// export const config = {
//   serverURL: 'http://144.76.103.235:8076/',
//   API: 'http://144.76.103.235:8076/admin/api',
//   ws: 'ws://144.76.103.235:8077',
//   // API: 'http://localhost:8071/admin/api',
// }

// prod
export const config = {
  serverURL: "https://production.corleone.kitg.com.ua/",
  API: "https://production.corleone.kitg.com.ua/admin/api",
  ws: "ws://144.76.103.235:8077",
};
