import React from 'react'

const Preloader: React.FC = () => {

  return (
    <div className="page-preloader">
      <img
        alt='Logo'
        src={require('../../assets/images/logo.jpg')}
      />
    </div>
  )
}

export default Preloader
