import React from 'react'
import * as Table from '../../../../components/Table'

import { IRole } from '../../../../types/items'
import { ITrackProps } from './types'


const Track: React.FC<ITrackProps> = ({
  data
}) => {

  return (
    <Table.Track
      type='home'
    >
      <Table.Cell
        text={data.fullname}
      />
      <Table.Cell
        text={data.login}
      />
      <Table.Cell
        text={(data.role as IRole).title}
      />
      <Table.Cell
        text={new Date((data.updated as string)).toLocaleDateString()}
      />
      <Table.Cell
        text={new Date((data.created as string)).toLocaleDateString()}
      />
    </Table.Track>
  )
}

export default Track