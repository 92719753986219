import React, {
  Fragment,
  useContext,
  useState,
  useEffect
} from 'react'

import * as Contexts from '../../../../contexts'
import * as Form from '../../../../components/Form'

import { useHttp } from '../../../../hooks'
import { config, Translater } from '../../../../config'
import { ISimilarBlockProps } from './types'

import AsyncSelect from 'react-select/async'
import Item from './Item'

import { IProduct, IOption } from '../../../../types/items'




const SimilarBlock: React.FC<ISimilarBlockProps> = ({
  items,
  setHandler,
  removeHandler
}) => {

  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { request } = useHttp()

  const [options, setOptions] = useState<any[]>([])
  const [selectedOptions, setSelectedOptions] = useState<IOption[]>([])

  const Events = {
    loadOptions: async (inputValue: string) => {
      try {
        const response: IProduct[] = await request(`${config.API}/products/?name=${inputValue}`, 'GET', null, {
          Authorization: (token as string)
        })
        const formatted: IOption[] = []
        if (response.length) {
          for (const item of response) {
            const option = {
              value: (item._id as string),
              label: (item.name as string)
            }
            formatted.push(option)
          }
        }
        setOptions(formatted)
        return formatted
      } catch (e) {
        console.log(e)
      }
    },
    optionHandler: (options: any[]) => {
      setSelectedOptions(options)
    },
  }

  const Styles = {
    control: () => ({
      display: 'flex',
      border: '1px solid #e6e6e6',
      borderRadius: 5,
      outline: 0
    }),
    input: () => ({
      fontSize: 16
    })
  }


  useEffect(() => {
    const arr: string[] = []
    if (selectedOptions) {
      for (let i = 0; i < selectedOptions.length; i++) {
        arr.push((selectedOptions[i].value as string))
      }
    }
    setHandler(arr)
  }, [selectedOptions])

  return (
    <Fragment>
      {access.products?.rule === 'change' ? (
        <Form.LabelField
          label={Translater.TableTitles.addToSimilar[language.slug]}
        >
          <AsyncSelect
            isMulti
            cacheOptions
            styles={Styles}
            defaultOptions={options}
            closeMenuOnSelect={false}
            placeholder={Translater.Placeholders.searchInput[language.slug]}
            loadingMessage={() => Translater.Placeholders.loadingMessage[language.slug]}
            noOptionsMessage={() => Translater.Placeholders.noOptionsMessage[language.slug]}
            onInputChange={(value: string) => { }}
            //@ts-ignore
            onChange={Events.optionHandler}
            loadOptions={Events.loadOptions}
          />
        </Form.LabelField>
      ) : null}

      {items && removeHandler ? (

        <Form.LabelField
          label={Translater.TableTitles.similar[language.slug]}
          text={!items.length ? 'None' : ''}
        >
          {items.map(item => (
            <Item
              key={item._id}
              data={item}
              removeHandler={removeHandler}
            />
          ))}
        </Form.LabelField>
      ) : null}
    </Fragment>
  )
}

export default SimilarBlock
