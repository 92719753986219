import React, { useContext } from 'react'

import * as Contexts from '../../../../contexts'
import * as Table from '../../../../components/Table'

import { useHistory } from 'react-router-dom'
import { ITrackProps } from './types'


const Track: React.FC<ITrackProps> = ({
  order,
  data
}) => {

  const { access } = useContext(Contexts.UserContext)

  const history = useHistory()

  const Events = {
    editHandler: () => {
      if (access.users?.rule !== 'false') {
        history.push(`/users/${data._id}`)
      }
    }
  }


  return (
    <Table.Track
      type='users'
    >
      <Table.Cell
        text={`${order}`}
      />

      <Table.Cell
        text={data.name}
      />

      <Table.Cell
        text={data.phone}
      />

      <Table.Cell
        text={new Date((data.updated as string)).toLocaleDateString()}
      />

      <Table.Cell
        text={new Date((data.created as string)).toLocaleDateString()}
      />

      <Table.Cell
        type='control'
        editButton={access.users?.rule !== 'false'}
        editHandler={Events.editHandler}
      />
    </Table.Track>
  )
}

export default Track