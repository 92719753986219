import React, {
  useState,
  useEffect,
  useContext
} from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Components from './components'

import { useHttp } from '../../hooks'
import { config, Translater } from '../../config'
import { IStaff } from '../../types/items'


const IndexPage: React.FC = () => {

  const { token } = useContext(Contexts.AuthContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { loading, request } = useHttp()

  const [primary, setPrimary] = useState<IStaff | null>(null)

  const Callbacks = {
    Fetch: async () => {
      try {
        const response: IStaff = await request(`${config.API}/login`, 'GET', null, {
          Authorization: (token as string)
        })

        if (response) setPrimary(response)
      } catch (e) {
        console.log(e)
      }
    }
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [])

  if (loading || !primary) return <Page.Preloader />

  return (
    <Page.Wrapper
      title={Translater.HomePage.title[language.slug]}
    >

      <Page.Header
        title={Translater.HomePage.title[language.slug]}
      />

      <Components.Table
        data={primary}
      />

    </Page.Wrapper>
  )
}

export default IndexPage