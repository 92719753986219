import React, { useContext, useState } from 'react'

import * as Contexts from '../../contexts'

import { Translater } from '../../config'
import { IItemProps } from './types'



const Item: React.FC<IItemProps> = ({ data }) => {

  const { language } = useContext(Contexts.LanguageContext)

  const [isVisible, toggleVisible] = useState<boolean>(true)

  const clickHandler = () => {
    toggleVisible(!isVisible)
  }

  if (!isVisible) return null

  return (
    <div
      className='notif-wrapper'
      onClick={clickHandler}
    >
      {data.type}
      {data.action}
    </div>
  )
}

export default Item