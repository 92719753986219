import React, {
  useState,
  useEffect,
  useContext,
} from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Components from './components'

import { Translater, config } from '../../config'
import { useHttp } from '../../hooks'
import { IRole } from '../../types/items'


const IndexPage: React.FC = () => {

  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { loading, request } = useHttp()

  const [primary, setPrimary] = useState<IRole[]>([])

  const Events = {
    reloadHandler: () => {
      Callbacks.Fetch()
    }
  }

  const Callbacks = {
    Fetch: async () => {
      try {
        const response: IRole[] = await request(`${config.API}/roles/?title=`,
          'GET', null, {
          Authorization: (token as string)
        })

        if (response) {
          setPrimary(response)
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [])


  if (loading || !primary) return <Page.Preloader />

  return (
    <Page.Wrapper
      title={Translater.RolesPage.title[language.slug]}
    >
      <Page.Header
        title={Translater.RolesPage.title[language.slug]}
        linkTitle={Translater.Buttons.create[language.slug]}
        enableButton={access.admin?.children?.roles?.rule === 'change'}
        linkTo='/roles/create'
      />

      <Components.Table
        items={primary}
        reloadHandler={Events.reloadHandler}
      />

    </Page.Wrapper>
  )
}

export default IndexPage