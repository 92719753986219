import React, {
    useState,
    useEffect,
    useContext,
    ChangeEvent,
} from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Buttons from '../../components/Buttons'
import * as Components from './components'

import { config, Translater } from '../../config'
import { useHttp } from '../../hooks/http.hook'
import { useHistory } from 'react-router-dom'


const CreatePage: React.FC = () => {

    const { token } = useContext(Contexts.AuthContext)
    const { language } = useContext(Contexts.LanguageContext)

    const history = useHistory()
    const { loading, request } = useHttp()

    const [isValid, toggleValid] = useState({
        price: false,
       // photo: false,
    })

    const [form, setForm] = useState<any>({
        price: '',
        photo: '',
        description: '',
    })

    const Events = {
        inputHandler: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
            //@ts-ignore
            if (e.target.type === 'file') setForm({ ...form, photo: e.target.files[0] })
          /*  else if (e.target.name.split('[')[1]) {
                setMultiLangTitles({
                    ...multiLangTitles, [e.target.name]: e.target.value
                })
            } */
            else setForm({ ...form, [e.target.name]: e.target.value })
        },
      /*  selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
            setForm({ ...form, [e.target.name]: e.target.value })
        }, */
        setCity: (city: string) => {
            setForm({ ...form, city})
        },
        /*  setRole: (role: string) => {
              setForm({ ...form, role })
          }, */
        createHandler: () => {
            let isRight = false

            for (let key in isValid) {
                //@ts-ignore
                const value = isValid[key]
                if (value) isRight = true
                else {
                    isRight = false
                    break
                }
            }

            if (isRight) Callbacks.Create()
        }
    }

    const Callbacks = {
        Create: async () => {
            try { console.log(form)
                const data = new FormData()

                data.append('price', form.price)
                data.append('description', form.description)
                if (form.photo) data.append('photo', form.photo)
                const response = await request(`${config.API}/booking`, 'POST', data, {
                    Authorization: (token as string),
                })

                if (response) {
                    history.goBack()
                }
            } catch (e) {
                console.log(e)
            }
        }
    }

    useEffect(() => {
        if (form.price.length > 0) toggleValid({ ...isValid, price: true })
        else toggleValid({ ...isValid, price: false })
    }, [form.price])

/*    useEffect(() => {
        if (form.photo) toggleValid({ ...isValid, photo: true })
        else toggleValid({ ...isValid, photo: false })
    }, [form.photo]) */


    return (
        <Page.Wrapper
            title={Translater.BookingCreatePage.title[language.slug]}
        >
            <Page.Header
                backButtonTitle={Translater.BookingCreatePage.title[language.slug]}
            />

            <Buttons.Container
                disabled={loading}
                createHandler={Events.createHandler}
            />

            <Components.ConfigBlock
                isCreate
                form={form}
                isValid={isValid}
              //  setCity={Events.setCity}
                inputHandler={Events.inputHandler}
              //  selectHandler={Events.selectHandler}
            />

        </Page.Wrapper>
    )
}

export default CreatePage
