import { ITableTitle } from '../../../../types/table'

export const STitles: ITableTitle[] = [
  { type: 'header', slug: 'increment' },
  { type: 'header', slug: 'fullname' },
  { type: 'header', slug: 'login' },
  { type: 'header', slug: 'role' },
  { type: 'header', slug: 'updated' },
  { type: 'header', slug: 'created' },
  { type: 'header', slug: '' },
]