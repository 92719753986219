import { ITableTitle } from '../../../../types/table'

export const STitles: ITableTitle[] = [
  { type: 'header', slug: 'increment' },
  { type: 'header', slug: 'photo' },
  { type: 'header', slug: 'name', sort: true },
  { type: 'header', slug: 'category', sort: true },
  { type: 'header', slug: 'nutritionalValue'},
  { type: 'header', slug: 'hidden', sort: true },
  { type: 'header', slug: 'price', sort: true },
  { type: 'header', slug: 'updated', sort: true },
  { type: 'header', slug: 'created', sort: true },
  { type: 'header', slug: '' },
]
