import React, { Fragment } from 'react'

import * as Buttons from '../Buttons'
import * as Form from '../Form'

import { IFilterProps } from './types'


const Filter: React.FC<IFilterProps> = ({
 children,
 form,
 button,
 agePicker,
 events = {}
}) => {

    return (
        <div className='filter-bar'>
            <div className='filter-bar-data'>
                {children}
            </div>

            <div className='filter-bar-inputs'>

                {events && agePicker ? (
                    <Fragment>
                        <Form.Input
                            type="date"
                            name='dateFrom'
                            value={form?.dateFrom}
                            label='Часовий проміжок для експорта в Excel:'
                            inputHandler={(events.inputHandler as any)}
                        />

                        <Form.Input
                            type="date"
                            name='dateTo'
                            value={form?.dateTo}
                            inputHandler={(events.inputHandler as any)}
                        />
                    </Fragment>
                ) : null}

            </div>
        </div>
    )
}



export default Filter
