import React, {
  Fragment,
  useContext,
} from 'react'

import * as Contexts from '../../../../contexts'
import * as Form from '../../../../components/Form'
import * as Buttons from '../../../../components/Buttons'

import { Translater } from '../../../../config'

import { IDetailBlockProps } from './types'


const DetailBlock: React.FC<IDetailBlockProps> = ({
  data
}) => {

  const { language } = useContext(Contexts.LanguageContext)


  return (
    <Fragment>
      <div className='block-container'>
        <Form.LabelField
          fontSize='small'
          label={Translater.TableTitles.orderNumber[language.slug]}
          text={`${data.increment}`}
        />

        <Form.LabelField
          fontSize='small'
          label={Translater.TableTitles.status[language.slug]}
          text={Translater.TableTitles.statusTypes[data.status][language.slug]}
        />

        <Form.LabelField
          fontSize='small'
          label={Translater.TableTitles.comment[language.slug]}
          text={data.comment ? data.comment : (
            Translater.TableTitles.withoutComment[language.slug]
          )}
        />

        <Form.LabelField
          fontSize='small'
          label={Translater.TableTitles.user[language.slug]}
        >
          <Buttons.Link
            hideModal
            title={data.user.name}
            linkTo={`/users/${data.user._id}`}
          />
          <p className='label-text'>
            {data.user.phone}
          </p>
        </Form.LabelField>

        <Form.LabelField
          fontSize='small'
          label={Translater.TableTitles.recipient[language.slug]}
        >
          <p className='label-text'>
            {data.delivery.name}
          </p>
          <p className='label-text'>
            {data.delivery.phone}
          </p>
          <p className='label-text'>
            {data.delivery.address.formatted_address}
          </p>
        </Form.LabelField>

        <Form.LabelField
          label={Translater.TableTitles.type[language.slug]}
          text={data.delivery.type === 'courier' ? (
            Translater.TableTitles.courier[language.slug]
          ) : Translater.TableTitles.pickup[language.slug]}
        />

        {(data.paidByBonuses) ? <Form.LabelField
            fontSize='small'
            label={Translater.TableTitles.paidByBonuses[language.slug]}
            text={`${data.paidByBonuses} грн`}
        /> : null}

        <Form.LabelField
          fontSize='small'
          label={Translater.TableTitles.deliveryPrice[language.slug]}
          text={`${data.deliveringPrice} грн`}
        />

        <Form.LabelField
          fontSize='small'
          label={Translater.TableTitles.archived[language.slug]}
          text={new Date((data.archived as string)).toLocaleDateString()}
        />

        <Form.DateBlock
          updated={(data.updated as string)}
          created={(data.created as string)}
        />

      </div>
    </Fragment>
  )
}

export default DetailBlock
