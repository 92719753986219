import React from 'react'

import { ITableWrapperProps } from './type'


const Track: React.FC<ITableWrapperProps> = ({
  type,
  children,
  clickHandler = () => { }
}) => {

  return (
    <div
      className={`table-track table--grid table--${type}`}
      onClick={clickHandler}
    >
      {children}
    </div>
  )
}

export default Track