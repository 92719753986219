import React,
{ useState, useContext, useEffect } from 'react'
import {
  IProduct
} from '../../types/items'

import { useHttp } from '../../hooks/http.hook'
import * as Contexts from '../../contexts'

import {
  config,
  Translater
} from '../../config'

import AsyncSelect from 'react-select/async'



const ListProducts: React.FC<{
  productsList: string,
  selectHandler: (e: any) => void
}> = ({ selectHandler, productsList }) => {
  const { token } = useContext(Contexts.AuthContext)
  const { loading, totalCount, request } = useHttp()

  const [primary, setPrimary] = useState<IProduct[]>([])

  const Callbacks = {
    Fetch: async () => {
      try {
        const response: IProduct[] = await request(`${config.API}/products?page=1&perPage=500`,
          'GET', null, {
          Authorization: (token as string)
        })

        if (response) {
          console.log('response LIST', response)
          setPrimary(response)
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [])


  return (
    <select name='productsList' value={productsList} onChange={selectHandler} >
      <option value="" disabled>Виберіть продукт</option>
      <option value="">Немає</option>
      {primary.map(({ _id, name }) => {
        return <option key={_id} value={_id}>{name}</option>
      })}
    </select>
  )
}

export default ListProducts
