import { TLanguages } from '../types/contexts/language'

export const Languages: TLanguages = {
  ua: {
    slug: 'ua',
    label: 'Українська'
  },
  ru: {
    slug: 'ru',
    label: 'Русский'
  }
}

export const Translater = {
  Erros: {
    oops: {
      ua: 'Упс...',
      ru: 'Упс...'
    },
    network: {
      ua: 'Відсутнє підключення до інтернету',
      ru: 'Отсутствует подключение к интернету'
    },
    ws: {
      ua: 'Відсутнє підключення до WebSocket.\nПерезавантажте сторiнку',
      ru: 'Отсутствует подключение к WebSocket\nПерезагрузите страницу'
    },
    forbidden: {
      ua: 'Нема прав доступу!',
      ru: 'Нету прав доступа!'
    }
  },
  WS: {
    Actions: {
      'STATUS_CHANGE': {
        ua: '',
        ru: ''
      }
    },
    Types: {
      'ORDER': {
        ua: '',
        ru: ''
      }
    }
  },
  Sidebar: {
    online: {
      ua: 'Онлайн',
      ru: 'Онлайн'
    },
    home: {
      ua: 'Головна',
      ru: 'Главная'
    },
    feedback: {
      ua: 'Вiдгуки',
      ru: 'Отзывы'
    },
    orders: {
      ua: 'Замовлення',
      ru: 'Заказы'
    },
    categories: {
      ua: 'Категорії',
      ru: 'Категории'
    },
    restaurants: {
      ua: 'Ресторани',
      ru: 'Рестораны'
    },
    booking: {
      ua: 'Столики',
      ru: 'Столики'
    },
    referals: {
      ua: 'Реферальні коди',
      ru: 'Реферальные коды'
    },
    products: {
      ua: 'Блюда',
      ru: 'Блюда'
    },
    sales: {
      ua: 'Акциї',
      ru: 'Акции'
    },
    users: {
      ua: 'Користувачi',
      ru: 'Пользователи'
    },
    staff: {
      ua: 'Персонал',
      ru: 'Персонал'
    },
    roles: {
      ua: 'Посади',
      ru: 'Должности'
    },
    history: {
      ua: 'Архів',
      ru: 'Архив'
    },
    notifications: {
      ua: 'Сповіщення',
      ru: 'Уведомления'
    },
    delivery: {
      ua: 'Доставка та оплата',
      ru: 'Доставка и оплата'
    },
    stocks: {
      ua: 'Акції',
      ru: 'Акции'
    },
    settings: {
      ua: 'Налаштування',
      ru: 'Настройки'
    },
  },
  Buttons: {
    login: {
      ua: 'Увійти',
      ru: 'Войти'
    },
    goBack: {
      ua: 'Повернутись назад',
      ru: 'Вернуться назад'
    },
    reload: {
      ua: 'Перезапустити',
      ru: 'Перезапустить'
    },
    logout: {
      ua: 'Вийти',
      ru: 'Выйти'
    },
    save: {
      ua: 'Зберегти',
      ru: 'Сохранить'
    },
    delete: {
      ua: 'Видалити',
      ru: 'Удалить'
    },
    create: {
      ua: 'Створити',
      ru: 'Создать'
    },
    addOptions: {
      ua: 'Добавити опції',
      ru: 'Добавить опции'
    },
    add: {
      ua: 'Додати',
      ru: 'Добавить'
    },
      send: {
        ua: 'Надіслати',
        ru: 'Отправить'
      }
  },
  Alert: {
    save: {
      ua: 'Зберегти?',
      ru: 'Сохранить?'
    },
    delete: {
      ua: 'Видалити?',
      ru: 'Удалить?'
    }
  },
  Select: {
    new: {
      ua: 'Новий',
      ru: 'Новый'
    },
    working: {
      ua: 'Готується',
      ru: 'Готовиться'
    },
    delivering: {
      ua: 'Доставляється',
      ru: 'Доставляеться'
    },
    ready: {
      ua: 'Замовлення готово',
      ru: 'Заказ готов'
    },
    rejected: {
      ua: 'Відмовлений',
      ru: 'Отменён'
    },
    done: {
      ua: 'Завершений',
      ru: 'Завершенный'
    },
    yes: {
      ua: 'Так',
      ru: 'Да'
    },
    no: {
      ua: 'Ні',
      ru: 'Нет'
    },
    view: {
      ua: 'Перегляд',
      ru: 'Просмотр'
    },
    change: {
      ua: 'Редагування',
      ru: 'Редактирование'
    },
    hide: {
      ua: 'Приховати',
      ru: 'Скрыть'
    },
    processing: {
      ua: 'Обробка',
      ru: 'Обработка'
    },
    'payment-error': {
      ua: 'Помилка при оплаті',
      ru: 'Ошибка при оплате'
    },
    'payment-paid': {
      ua: 'Сплачено',
      ru: 'Оплачено'
    }
  },
  Placeholders: {
    minTwo: {
      ua: 'Не менш 2 букв',
      ru: 'Не менее 2 букв'
    },
    minThree: {
      ua: 'Не менш 3 букв',
      ru: 'Не менее 3 букв'
    },
    minFour: {
      ua: 'Не менш 4 букв',
      ru: 'Не менее 4 букв'
    },
    minSix: {
      ua: 'Не менш 6 букв',
      ru: 'Не менее 6 букв'
    },
    noOptionsMessage: {
      ua: 'Нема збігів :(',
      ru: 'Нету совпадений :('
    },
    loadingMessage: {
      ua: 'Завантаження...',
      ru: 'Загрузка...'
    },
    searchInput: {
      ua: 'Пошук за випадковим збігом',
      ru: 'Поиск по совпадению'
    }
  },
  TableTitles: {
    number: {
      ua: '№',
      ru: '№',
    },
    info: {
      ua: 'Додаткова інформація',
      ru: 'Дополнительная информация',
    },
    countBonuses: {
      ua: 'Кількість бонусів',
      ru: 'Количество бонусов',
    },
    count: {
      ua: 'Кількість',
      ru: 'Количество',
    },
    operation: {
      ua: 'Операція',
      ru: 'Операция',
    },
    slug: {
      ua: 'Заголовок',
      ru: 'Заголовок'
    },
    bonusesPercents: {
      ua: 'Проценти бонусів',
      ru: 'Проценты бонусов'
    },
    timeEnd: {
      ua: 'Залишилось часу',
      ru: 'Осталось времени'
    },
    rate: {
      ua: 'Оцiнка',
      ru: 'Оценка'
    },
    product: {
      ua: 'Продукт',
      ru: 'Продукт'
    },
    stocks: {
      ua: 'Акції',
      ru: 'Акции'
    },
    stocks_main_view: {
      ua: 'Перегляд',
      ru: 'Просмотр'
    },
    stocks_main_change: {
      ua: 'Редагування',
      ru: 'Редактирование'
    },
    feedback: {
      ua: 'Вiдгуки',
      ru: 'Отзывы'
    },
    referals: {
      ua: 'Реферальні коди',
      ru: 'Реферальные коды'
    },
    booking: {
      ua: 'Столики',
      ru: 'Столики'
    },
    null: {
      ua: 'Немає',
      ru: 'Нету'
    },
    city: {
      ua: 'Місто',
      ru: 'Город'
    },
    percent: {
      ua: '%',
      ru: '%'
    },
    endStock: {
      ua: 'Акція закінчилась',
      ru: 'Акція закінчилась'
    },
    expiresDate: {
      ua: 'expiresDate',
      ru: 'expiresDate'
    },
    admin: {
      ua: 'Адміністратори',
      ru: 'Администраторы'
    },
    message: {
      ua: 'Повідомлення',
      ru: 'Сообщение'
    },
    processing: {
      ua: 'Обробка',
      ru: 'Обработка'
    },
    staff: {
      ua: 'Персонал',
      ru: 'Персонал'
    },
    roles: {
      ua: 'Посади',
      ru: 'Должности'
    },
    users: {
      ua: 'Користувачі',
      ru: 'Пользователи'
    },
    orders: {
      ua: 'Замовлення',
      ru: 'Заказы'
    },
    history: {
      ua: 'Архів замовлень',
      ru: 'Архив заказов'
    },
    deleting: {
      ua: 'Bидалення',
      ru: 'Удаление'
    },
    categories: {
      ua: 'Категорiї',
      ru: 'Категории'
    },
    similar: {
      ua: 'Cхоже',
      ru: 'Похожее'
    },
    password: {
      ua: 'Пароль',
      ru: 'Пароль'
    },
    archived: {
      ua: 'Доданий в архів',
      ru: 'Добавлен в архив'
    },
    favourites: {
      ua: 'Вибране',
      ru: 'Избранное'
    },
    addToSimilar: {
      ua: 'Додати до схожого',
      ru: 'Добавить к похожему'
    },
    averageStars: {
      ua: 'Середня оцінка',
      ru: 'Средняя оценка'
    },
    starsCount: {
      ua: 'Kількість відгуків',
      ru: 'Kоличество отзывов'
    },
    stock: {
      ua: 'В наявності',
      ru: 'В наличии'
    },
    category: {
      ua: 'Kатегорія',
      ru: 'Kатегория'
    },
    paidByBonuses: {
      ua: 'Оплачено бонусами',
      ru: 'Оплачено бонусами'
    },
    paidByBonusesTable: {
      ua: 'Опл бонусами',
      ru: 'Опл бонусами'
    },
    price: {
      ua: 'Ціна',
      ru: 'Цена'
    },
    products: {
      ua: 'Продукти',
      ru: 'Продукты'
    },
    restaurants: {
      ua: 'Ресторани',
      ru: 'Рестораны'
    },
    historyBonuses: {
      ua: 'Історія бонусів',
      ru: 'История бонусов',
    },
    notifications: {
      ua: 'Сповіщення',
      ru: 'Уведомления',
    },
    productsCount: {
      ua: 'Кількість продуктів',
      ru: 'Kоличество продуктов'
    },
    subCategories: {
      ua: 'Підкатегорії',
      ru: 'Подкатегории'
    },
    subCategoriesCount: {
      ua: 'Кількість підкатегорій',
      ru: 'Kоличество подкатегорий'
    },
    parentCategory: {
      ua: 'Батьківська категорія',
      ru: 'Родительская категория'
    },
    parentProduct: {
      ua: 'Батьківський продукт',
      ru: 'Родительский продукт'
    },
    increment: {
      ua: '№',
      ru: '№',
    },
    fullname: {
      ua: 'ПІБ',
      ru: 'ФИО'
    },
    nutritionalValue: {
      ua: 'Харчова цінність',
      ru: 'Пищевая ценность',
    },
    hidden: {
      ua: 'Прихована',
      ru: 'Cкрытая',
      true: {
        ua: 'Так',
        ru: 'Да'
      },
      false: {
        ua: 'Ні',
        ru: 'Нет'
      }
    },
    login: {
      ua: 'Логін',
      ru: 'Логин'
    },
    role: {
      ua: 'Посада',
      ru: 'Должность'
    },
    sum: {
      ua: 'Сума',
      ru: 'Суммa'
    },
    code: {
      ua: 'Реферальний код',
      ru: 'Реферальный код'
    },
    itemsTotalPrice: {
      ua: 'Сума',
      ru: 'Суммa'
    },
    user: {
      ua: 'Користувач',
      ru: 'Пользователь'
    },
    status: {
      ua: 'Статус',
      ru: 'Статус'
    },
    comment: {
      ua: 'Коментар',
      ru: 'Комментарий'
    },
    delivery: {
      ua: 'Доставка',
      ru: 'Доставка'
    },
    deliveryPrice: {
      ua: 'Ціна за доставку',
      ru: 'Цена за доставку'
    },
    recipient: {
      ua: 'Отримувач',
      ru: 'Получатель'
    },
    call: {
      ua: 'Передзвонити',
      ru: 'Перезвонить'
    },
    phone: {
      ua: 'Телефон',
      ru: 'Телефон'
    },
    photo: {
      ua: 'Фото',
      ru: 'Фото'
    },
    dateStart: {
      ua: 'Початок акції',
      ru: 'Начало акции'
    },
    dateEnd: {
      ua: 'Кінець акції',
      ru: 'Конец акции'
    },
    productsList: {
      ua: 'Список товарів',
      ru: 'Список товаров'
    },
    categoriesList: {
      ua: 'Список категорій',
      ru: 'Список категорий'
    },
    name: {
      ua: 'Назва',
      ru: 'Название'
    },
    orderNumber: {
      ua: 'Номер замовлення',
      ru: 'Hомер заказа'
    },
    pickup: {
      ua: 'Самовивіз',
      ru: 'Самовывоз'
    },
    subcategory: {
      ua: 'Підкатегорія',
      ru: 'Подкатегория'
    },
    options: {
      ua: 'Опції',
      ru: 'Опции'
    },
    options_addition: {
      ua: 'Додатково(Вага/шт)',
      ru: 'Дополнительно(Вес/шт)'
    },
    options_name: {
      ua: 'Назва опції',
      ru: 'Название опции'
    },
    options_price: {
      ua: 'Ціна опції',
      ru: 'Цена опции'
    },
    title: {
      ua: 'Назва',
      ru: 'Название'
    },
    calories: {
      ua: 'Калорійність',
      ru: 'Калорийность'
    },
    fats: {
      ua: 'Жири',
      ru: 'Жиры'
    },
    carbohydrates: {
      ua: 'Вуглеводи',
      ru: 'Углеводы'
    },
    proteins: {
      ua: 'Білки',
      ru: 'Белки'
    },
    weight: {
      ua: 'Вага',
      ru: 'Вес'
    },
    description: {
      ua: 'Опис',
      ru: 'Описание'
    },
    amount: {
      ua: 'Кількість',
      ru: 'Количество'
    },
    courier: {
      ua: "Кур'єр",
      ru: 'Курьер'
    },
    withoutComment: {
      ua: '[Без комментарів]',
      ru: '[Без комментариев]'
    },
    type: {
      ua: "Вид",
      ru: 'Вид'
    },
    address: {
      ua: 'Адрес',
      ru: 'Адрес'
    },
    statusTypes: {
      new: {
        ua: 'Новий',
        ru: 'Новый'
      },
      working: {
        ua: 'Готується',
        ru: 'Готовиться'
      },
      delivering: {
        ua: 'Доставляється',
        ru: 'Доставляеться'
      },
      ready: {
        ua: 'Замовлення готово',
        ru: 'Заказ готов'
      },
      rejected: {
        ua: 'Відмовленно',
        ru: 'Отменён'
      },
      done: {
        ua: 'Завершений',
        ru: 'Завершенный'
      },
      'payment-processing': {
        ua: 'В обробці',
        ru: 'В обработке'
      },
      'payment-error': {
        ua: 'Помилка при оплаті',
        ru: 'Ошибка при оплате'
      },
      'payment-paid': {
        ua: 'Сплачено',
        ru: 'Оплачено'
      },
    },
    created: {
      ua: 'Створений',
      ru: 'Создан'
    },
    updated: {
      ua: 'Оновлений',
      ru: 'Обновлён'
    }
  },
  AuthPage: {
    title: {
      ua: 'Авторизація',
      ru: 'Авторизация'
    },
    loginPlaceholder: {
      ua: 'Логін',
      ru: 'Логин'
    },
    passwordPlaceholder: {
      ua: 'Пароль',
      ru: 'Пароль'
    },
  },
  HomePage: {
    title: {
      ua: 'Головна',
      ru: 'Главная'
    }
  },
  OrdersPage: {
    title: {
      ua: 'Замовлення',
      ru: 'Заказы'
    }
  },
  OrdersDetailPage: {
    title: {
      ua: 'Замовлення',
      ru: 'Заказ'
    }
  },
  CategoriesPage: {
    title: {
      ua: 'Категорії',
      ru: 'Категории'
    },
  },
  DeliveryPage: {
    title: {
      ua: 'Доставка та оплата',
      ru: 'Доставка и оплата'
    }
  },
  CategoriesCreatePage: {
    title: {
      ua: 'Створити категорію',
      ru: 'Cоздать категорию'
    }
  },
  NotificationsPage: {
    title: {
      ua: 'Сторінка повідомлень',
      ru: 'Страница уведомлений'
    }
  },
  BookingPage: {
    title: {
      ua: 'Бронювання столиків',
      ru: 'Бронирование столиков'
    }
  },
  ReferalsPage: {
    title: {
      ua: 'Сторінка реферальних кодів',
      ru: 'Страница реферальных кодов'
    }
  },
  StocksCreatePage: {
    title: {
      ua: 'Створити акцію',
      ru: 'Cоздать акцию'
    }
  },
  StocksDetailPage: {
    title: {
      ua: 'Картка акції',
      ru: 'Kарточка акции'
    }
  },
  CategoriesDetailPage: {
    title: {
      ua: 'Картка категорії',
      ru: 'Kарточка категории'
    }
  },
  BookingDetailPage: {
    title: {
      ua: 'Редагувати столик',
      ru: 'Редактировать столик'
    }
  },
  ReferalsDetailPage: {
    title: {
      ua: 'Редагування',
      ru: 'Редактирование'
    }
  },
  RestaurantsPage: {
    title: {
      ua: 'Ресторани',
      ru: 'Рестораны'
    }
  },
  ProductsCreatePage: {
    title: {
      ua: 'Створити продукт',
      ru: 'Создать продукт'
    }
  },
  ProductsDetailPage: {
    title: {
      ua: 'Продукт',
      ru: 'Продукт'
    }
  },
  RestaurantsCreatePage: {
    title: {
      ua: 'Створити ресторан',
      ru: 'Создать ресторан'
    }
  },
  BookingCreatePage: {
    title: {
      ua: 'Створити бронь столика',
      ru: 'Создать бронь столика'
    }
  },
  RestaurantsDetailPage: {
    title: {
      ua: 'Ресторан',
      ru: 'Ресторан'
    }
  },
  ProductsPage: {
    title: {
      ua: 'Продукти',
      ru: 'Продукты'
    }
  },
  StocksPage: {
    title: {
      ua: 'Акції',
      ru: 'Акции'
    }
  },
  SalesPage: {
    title: {
      ua: 'Акцiї',
      ru: 'Акции'
    }
  },
  SalesCreatePage: {
    title: {
      ua: 'Створити акцiю',
      ru: 'Создать акцию'
    }
  },
  SalesDetailPage: {
    title: {
      ua: 'Акцiя',
      ru: 'Акция'
    }
  },
  UsersPage: {
    title: {
      ua: 'Користувачі',
      ru: 'Пользователи'
    }
  },
  UsersDetailPage: {
    title: {
      ua: 'Користувач',
      ru: 'Пользователь'
    }
  },
  StaffPage: {
    title: {
      ua: 'Персонал',
      ru: 'Персонал'
    }
  },
  StaffCreatePage: {
    title: {
      ua: 'Створити співробітника',
      ru: 'Создать сотрудника'
    }
  },
  StaffDetailPage: {
    title: {
      ua: 'Співробітник',
      ru: 'Сотрудник'
    }
  },
  RolesPage: {
    title: {
      ua: 'Посади',
      ru: 'Должности'
    }
  },
  RoleCreatePage: {
    title: {
      ua: 'Створити посаду',
      ru: 'Создать должность'
    }
  },
  RoleDetailPage: {
    title: {
      ua: 'Посада',
      ru: 'Должность'
    }
  },
  ArchivePage: {
    title: {
      ua: 'Архів замовлень',
      ru: 'Архив заказов'
    }
  },
  FeedbackPage: {
    title: {
      ua: 'Вiдгуки',
      ru: 'Отзывы'
    }
  },
  SettingsPage: {
    title: {
      ua: 'Налаштування',
      ru: 'Настройки'
    },
    langSwitchLabel: {
      ua: 'Змінити мову :',
      ru: 'Сменить язык :'
    },
  },
}
