import React, {
    useState,
    useEffect,
    useContext,
    ChangeEvent,
} from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Buttons from '../../components/Buttons'
import * as Components from './components'

import { config, Translater } from '../../config'
import { useHttp } from '../../hooks/http.hook'
import {useHistory, useParams} from 'react-router-dom'
import {ICategory, IRestaurant} from "../../types/items";


const DetailPage: React.FC = () => {

    const { id } = useParams() as any
    const { token } = useContext(Contexts.AuthContext)
    const { language } = useContext(Contexts.LanguageContext)

    const history = useHistory()
    const { loading, request } = useHttp()

    const [primary, setPrimary] = useState<any | null>(null)
    const [isValid, toggleValid] = useState({
        price: false,
        // photo: false,
    })

    const [form, setForm] = useState<any>({
        price: '',
        photo: '',
        description: '',
    })

    const Events = {
        inputHandler: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
            //@ts-ignore
            if (e.target.type === 'file') setForm({ ...form, photo: e.target.files[0] })
            /*  else if (e.target.name.split('[')[1]) {
                  setMultiLangTitles({
                      ...multiLangTitles, [e.target.name]: e.target.value
                  })
              } */
            else setForm({ ...form, [e.target.name]: e.target.value })
        },
        /*  selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
              setForm({ ...form, [e.target.name]: e.target.value })
          }, */
        setCity: (city: string) => {
            setForm({ ...form, city})
        },
        /*  setRole: (role: string) => {
              setForm({ ...form, role })
          }, */
        saveHandler: () => {
            if (isValid.price) Callbacks.Save()
        },
        deleteHandler: () => {
            const answer = window.confirm(Translater.Alert.delete[language.slug])
            if (answer) Callbacks.Delete()
        },
    }

    const Callbacks = {
        Fetch: async () => {
            try {
                const response: any = await request(`${config.API}/booking/${id}`,
                    'GET', null, {
                        Authorization: (token as string)
                    })
                console.log(response)

                if (response) {
                    setPrimary(response)
                }
            } catch (e) {
                console.log(e)
            }
        },
        Save: async () => {
            try {
                const data = new FormData()
                console.log(form)

                data.append('price', form.price)
                data.append('description', form.description)
                if (form.photo) data.append('photo', form.photo)
                const response = await request(`${config.API}/booking/${primary?._id}`, 'POST', data, {
                    Authorization: (token as string),
                })

                if (response) {
                    history.goBack()
                }
            } catch (e) {
                console.log(e)
            }
        },
        Delete: async () => {
            try {
                await request(`${config.API}/booking/${id}`, 'DELETE', null, {
                    Authorization: (token as string),
                })

                history.push('/booking')
            } catch (e) {
                console.log(e)
            }
        },
    }

    useEffect(() => {
        Callbacks.Fetch()
    }, [id])

    useEffect(() => {
        if (primary) setForm({
            ...form,
            price: primary.price,
            description: primary.description
        })
    }, [primary])

    useEffect(() => {
        if (form.price > 0) toggleValid({ ...isValid, price: true })
        else toggleValid({ ...isValid, price: false })
    }, [form.price])

    /*    useEffect(() => {
            if (form.photo) toggleValid({ ...isValid, photo: true })
            else toggleValid({ ...isValid, photo: false })
        }, [form.photo]) */


    return (
        <Page.Wrapper
            title={Translater.BookingDetailPage.title[language.slug]}
        >
            <Page.Header
                backButtonTitle={Translater.BookingDetailPage.title[language.slug]}
            />

            <Buttons.Container
                disabled={loading}
                saveHandler={Events.saveHandler}
                deleteHandler={Events.deleteHandler}
            />

            <Components.ConfigBlock
                isCreate
                form={form}
                isValid={isValid}
                //  setCity={Events.setCity}
                inputHandler={Events.inputHandler}
                //  selectHandler={Events.selectHandler}
            />

        </Page.Wrapper>
    )
}

export default DetailPage
