import React, {
  useState,
  useEffect,
  useContext
} from 'react'


import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'

import * as Components from './components'
import { useHttp } from '../../hooks/http.hook'

import { config, Translater } from '../../config'

import { IStock } from '../../types/items'

const Stocks: React.FC = () => {

  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { loading, totalCount, request } = useHttp()

  const [stocks, setStocks] = useState<IStock[]>([])


  const Events = {

    pressHandler: (e: KeyboardEvent) => {
      if (e.key === 'Enter') Callbacks.Fetch()
    },

    reloadHandler: () => {
      Callbacks.Fetch()
    }
  }


  const Callbacks = {
    Fetch: async () => {
      try {
        const response: IStock[] = await request(`${config.API}/stocks`,
          'GET', null, {
          Authorization: (token as string)
        })
        console.log('valh', response)
        if (response) {
          setStocks(response)
        }

      } catch (e) {
        console.log(e)
      }

    }
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [])

  if (loading || !stocks) return <Page.Preloader />
  return (
    <Page.Wrapper
      footer
      title={Translater.StocksPage.title[language.slug]}
    >
      <Page.Header
        title={Translater.StocksPage.title[language.slug]}
        linkTitle={Translater.Buttons.create[language.slug]}
        enableButton={access.stocks?.rule === 'change'}
        linkTo='/stocks/create'
      />
      {/* {stocks.map(e => {
        return (
          <ul>
            <li>Название акции:{e.name}</li>
            <li>Описание: {e.description}</li>
            <li>Процент: {e.percent}</li>
            <li>До конца акции осталось {e.timeEnd}</li>
            <li>Путь к фото{e.photo}</li>
          </ul>
        )
      })} */}

      <Components.Table
        items={stocks}
        reloadHandler={Events.reloadHandler}
      />

      <Page.Footer>

      </Page.Footer>
    </Page.Wrapper>
  )
}

export default Stocks