import React from 'react'

import * as Table from '../../../../components/Table'
import * as Components from '..'

import { ITableWrapperProps } from './types'

import { STitles } from './static'


const TableWrapper: React.FC<ITableWrapperProps> = ({
 items,
 sortHandler
 }) => {

    return (
        <Table.Table
            type='bonuses'
            titles={STitles}
            sortHandler={sortHandler}
        >
            {items.map((item, index) => (
                <Components.BonusesTrack
                    key={item._id}
                    order={index + 1}
                    data={item}
                />
            ))}
        </Table.Table>
    )
}

export default TableWrapper
