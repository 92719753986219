import React, { useContext } from 'react'

import * as Contexts from '../../contexts'
import * as Buttons from '../Buttons'

import { Translater } from '../../config'

import { ICellProps } from './type'
import { TSortBy } from '../../types/items'


const Cell: React.FC<ICellProps> = ({
  type = 'simple',
  text,
  slug,
  style,
  imageURL,
  children,
  linkText,
  linkTo,
  modalContent,
  sort = false,
  editButton,
  editHandler = () => { },
  deleteButton,
  deleteHandler = () => { },
  clickHandler = () => { },
  sortHandler,
}) => {

  const { language } = useContext(Contexts.LanguageContext)

  const Events = {
    clickHandler: () => {
      clickHandler()
      if (sortHandler && sort) sortHandler((slug as TSortBy))
    }
  }

  if (type === 'custom') {
    return (
      <div
        className={`table-cell table-cell--${type}`}
        style={style}
        onClick={Events.clickHandler}
      >
        {children}
      </div>
    )
  }

  if (type === 'image') {
    return (
      <div
        className={`table-cell table-cell--${type}`}
        onClick={Events.clickHandler}
      >
        <img
          className='table-cell-img'
          alt='Category'
          src={imageURL}
        />
      </div>
    )
  }

  if (type === 'control') {
    return (
      <div className={`table-cell table-cell--${type}`}>
        {editButton ? (
          <Buttons.ImageButton
            image={<img alt='Edit' src={require('../../assets/icons/edit.png')} />}
            buttonHandler={editHandler}
          />
        ) : null}

        {deleteButton ? (
          <Buttons.ImageButton
            image={<img alt='Edit' src={require('../../assets/icons/delete.png')} />}
            buttonHandler={deleteHandler}
          />
        ) : null}
      </div>
    )
  }

  if (type === 'list') {
    return (
        <div
            className={`table-cell table-cell--${type}`}
            onClick={Events.clickHandler}
        >
          {text ? (
              <div>{(text?.split('+')[0].split(':')[1] !== 'undefined') ? text?.split('+').map((result) => {
                return result + '\n';
              }) : '-'}</div>
          ) : null}

          {slug ? (
              <p>{Translater.TableTitles[slug][language.slug]}</p>
          ) : null}

          {linkText && linkTo ? (
              <Buttons.Link
                  title={linkText}
                  linkTo={linkTo}
              />
          ) : null}

          {modalContent ? (
              <div className="table-cell-modal">
                {modalContent}
              </div>
          ) : null}
        </div>
    )
  }

  return (
    <div
      className={`table-cell table-cell--${type}`}
      onClick={Events.clickHandler}
    >

      {text ? (
        <p>{text}</p>
      ) : null}

      {slug ? (
        <p>{Translater.TableTitles[slug][language.slug]}</p>
      ) : null}

      {linkText && linkTo ? (
        <Buttons.Link
          title={linkText}
          linkTo={linkTo}
        />
      ) : null}

      {modalContent ? (
        <div className="table-cell-modal">
          {modalContent}
        </div>
      ) : null}
    </div>
  )
}

export default Cell
