import React from 'react'

import { IDefaultButtonProps } from './types'


const DefaultButton: React.FC<IDefaultButtonProps> = ({
  title,
  disabled = false,
  backgroundColor = 'green',
  buttonHandler
}) => {

  return (
    <button
      className={`btn btn--default btn--default--${backgroundColor}`}
      disabled={disabled}
      onClick={buttonHandler}
    >
      {title}
    </button>
  )
}

export default DefaultButton