import React, { useContext } from 'react'

import * as Contexts from '../../contexts'
import * as Form from '../Form'

import { Translater } from '../../config'

import { ISortBarProps } from './types'

import { SOptions } from './static'


const SortBar: React.FC<ISortBarProps> = ({
  searchBar = false,
  name = '',
  value = '',
  perPage,
  filter,
  selectHandler,
  pressHandler = () => { },
  inputHandler = () => { }
}) => {

  const { language } = useContext(Contexts.LanguageContext)

  return (
    <div className='sort-bar-wrapper'>
      <div className='sort-bar-container'>
        {searchBar ? (
          <Form.Input
            name={name}
            value={value}
            placeholder={Translater.Placeholders.searchInput[language.slug]}
            inputHandler={inputHandler}
            pressHandler={pressHandler}
          />
        ) : null}
      </div>

      <div className='sort-bar-container'>

        <div className='flex margin--sides'>
          <p className='sort-bar-title'>
            {Translater.TableTitles[filter.sortBy][language.slug]}
          </p>

          {filter.sortOrder === 'asc' ? (
            <img
              alt='Direction'
              src={require('../../assets/icons/arrow-top.png')}
            />
          ) : (
              <img
                alt='Direction'
                src={require('../../assets/icons/arrow-bottom.png')}
              />
            )}
        </div>

        {selectHandler ? <Form.Select
          name='perPage'
          options={SOptions}
          large={false}
          value={perPage}
          selectHandler={selectHandler}
        /> : null}
      </div>
    </div>
  )
}

export default SortBar
