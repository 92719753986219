import React,
{
  useState,
  useEffect,
  useContext,
  ChangeEvent
} from 'react'


import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Buttons from '../../components/Buttons'
import * as Components from './components'

import { useHttp } from '../../hooks/http.hook'
import {
  config,
  Translater
} from '../../config'

import { IStock } from '../../types/items'
import {
  TMutliLangTitles,
  TMutliLangDescriptions,
  TPossibleMultiLangTitle,
  TPossibleMultiLangDescriptions
} from '../../types/multiLang'

import { useHistory } from 'react-router-dom'


const CreatePage: React.FC = () => {

  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)
  const { multiLang, modules } = useContext(Contexts.ConfigContext)

  const { loading, request } = useHttp()
  const history = useHistory()

  const [startDate, setStartDate] = useState(new Date())
  const [showProduct, setShowProduct] = useState(false)

  const [isValid, toggleValid] = useState({
    name: false,
    photo: false,
    percent: 0,
    description: false,
    productsList: true
  })

  const [form, setForm] = useState<IStock>({
    name: '',
    photo: '',
    percent: 0,
    description: '',
    status: false,
    dateStart: 0,
    dateEnd: 0,
    timeEnd: 0,
    productsList: '',
    categoriesList: ''
  })
  const [multiLangTitles, setMultiLangTitles] = useState<TMutliLangTitles>({
    "title[EN]": '',
    "title[RU]": '',
    "title[UA]": ''
  })

  const [multiLangDescriptions, setMultiLangDescriptions] = useState<TMutliLangDescriptions>({
    "description[EN]": '',
    "description[RU]": '',
    "description[UA]": ''
  })



  const Events = {
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      //@ts-ignore
      if (e.target.type === 'file') setForm({ ...form, photo: e.target.files[0] })
      else if (e.target.name.split('[')[1]) {
        setMultiLangTitles({
          ...multiLangTitles, [e.target.name]: e.target.value
        })
      }
      else setForm({ ...form, [e.target.name]: e.target.value })
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setForm({ ...form, [e.target.name]: e.target.value })
    },
    createHandler: () => {

      if (isValid.name && isValid.photo) Callbacks.Create()
    }

  }
  //@ts-ignore
  const HandlerShowProduct = () => {
    setShowProduct(true)
  }


  const Callbacks = {
    Create: async () => {
      try {
        const data = new FormData()
        console.log(data)

        if (multiLang) {
          for (let key in multiLangTitles) {
            data.append(key, multiLangTitles[(key as TPossibleMultiLangTitle)])
          }
        } else data.append('title', form.name)

        data.append('photo', form.photo)
        data.append('name', form.name)
        data.append('description', form.description)
        data.append('percent', form.percent as string)
        data.append('dateStart', form.dateStart as string)
        data.append('dateEnd', form.dateEnd as string)
        data.append('productsList', form.productsList as any)
        data.append('categoriesList', form.categoriesList as any)

        console.log(form)


        const response = await request(`${config.API}/stocks`, 'POST', data, {
          Authorization: (token as string),
        })

        if (response.created) {
          history.goBack()
          setForm({
            ...form,
            name: '',
            description: '',
            photo: '',
            percent: 0,
            status: false,
            dateStart: 0,
            dateEnd: 0,
            timeEnd: 0,
            productsList: '',
            categoriesList: '',
          })
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  useEffect(() => {
    if (multiLang) {
      let isRight = true

      for (let key in multiLangTitles) {
        if (multiLangTitles[(key as TPossibleMultiLangTitle)].length >= 2 && isRight) {
          toggleValid({ ...isValid, name: true, productsList: true })
          isRight = true
        } else {
          isRight = false
          toggleValid({ ...isValid, name: false, productsList: false })
        }
      }
    } else {
      if (form.name.length >= 2) toggleValid({ ...isValid, name: true, productsList: true })
      else toggleValid({ ...isValid, name: false, productsList: false })
    }
  }, [form.name, form.productsList, multiLangTitles])

  useEffect(() => {
    if (form.photo) toggleValid({ ...isValid, photo: true })
    else toggleValid({ ...isValid, photo: false })
  }, [form.photo])

  useEffect(
    () => HandlerShowProduct()
    , [])

  return (
    <Page.Wrapper
      title={Translater.StocksCreatePage.title[language.slug]}
    >
      <Page.Header
        backButtonTitle={Translater.StocksCreatePage.title[language.slug]}
      />

      <Buttons.Container
        disabled={loading}
        createHandler={Events.createHandler}

      />

      <Components.ConfigBlock
        //@ts-ignore
        form={form}
        //@ts-ignore
        isValid={isValid}
        multiLangValues={multiLangTitles}
        inputHandler={Events.inputHandler}
        selectHandler={Events.selectHandler}
      />



    </Page.Wrapper>
  )
}

export default CreatePage
