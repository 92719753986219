import { ITableTitle } from '../../../../types/table'

export const STitles: ITableTitle[] = [
 //   { type: 'header', slug: 'increment' },
    { type: 'header', slug: 'code'},
    { type: 'header', slug: 'sum'},
//    { type: 'header', slug: 'updated', sort: true },
//    { type: 'header', slug: 'created', sort: true },
    { type: 'header', slug: '' },
]
