import React, {
  useContext,
  useState,
  useEffect
} from 'react'

import * as Contexts from '../../contexts'

import { NavLink } from 'react-router-dom'
import { Translater } from '../../config'
import { INavLink } from '../Page/types'
import { Feedback } from '../../pages/Products/components'



const CustomNavLink: React.FC<INavLink> = ({
  slug,
  linkTo,
  clickHandler
}) => {

  const { modules } = useContext(Contexts.ConfigContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)

  const [localAccess, setLocalAccess] = useState<boolean>(false)
  const [title, setTitle] = useState<string>('')
  const [image, setImage] = useState<any>(require('../../assets/icons/home.png'))

  useEffect(() => {
    switch (slug) {
      case 'home':
        if (
          modules.admin
          && access.admin?.rule
          && access.admin?.rule !== 'false') {
          setLocalAccess(true)
          setImage(require('../../assets/icons/home.png'))
          setTitle(Translater.Sidebar.home[language.slug])
        }
        break
      case 'orders':
        if (
          modules.orders
          && access.orders?.rule
          && access.orders?.rule !== 'false') {
          setLocalAccess(true)
          setImage(require('../../assets/icons/order.png'))
          setTitle(Translater.Sidebar.orders[language.slug])
        }
        break
      case 'categories':
        if (
          modules.categories
          && access.categories?.rule
          && access.categories?.rule !== 'false') {
          setLocalAccess(true)
          setImage(require('../../assets/icons/categories.png'))
          setTitle(Translater.Sidebar.categories[language.slug])
        }
        break
      case 'restaurants':
        if (
            modules.restaurants
            && access.restaurants?.rule
            && access.restaurants?.rule !== 'false') {
          setLocalAccess(true)
          setImage(require('../../assets/icons/restaurant.png'))
          setTitle(Translater.Sidebar.restaurants[language.slug])
        }
        break
      case 'products':
        if (
          modules.products
          && access.products?.rule
          && access.products?.rule !== 'false') {
          setLocalAccess(true)
          setImage(require('../../assets/icons/products.png'))
          setTitle(Translater.Sidebar.products[language.slug])
        }
        break
     /* case 'feedback':
        if (
          (modules.products as any)?.feedback
          && access.products?.children?.feedback?.rule
          && access.products?.children?.feedback?.rule !== 'false') {
          setLocalAccess(true)
          setImage(require('../../assets/icons/feedback.png'))
          setTitle(Translater.Sidebar.feedback[language.slug])
        }
        break */
      // case 'sales':
      //   if (false) { //!fix rule from backend //TODO create
      //     setLocalAccess(true)
      //     setImage(require('../../assets/icons/sale.png'))
      //     setTitle(Translater.Sidebar.sales[language.slug])
      //   }
      //   break
      case 'stocks':
        if (
          modules.stocks
          && access.stocks?.rule
          && access.stocks?.rule !== 'false') {
          setLocalAccess(true)
          setImage(require('../../assets/icons/sale.png'))
          setTitle(Translater.Sidebar.stocks[language.slug])
        }
        break
      case 'users':
        if (
          modules.users
          && access.users?.rule
          && access.users?.rule !== 'false') {
          setLocalAccess(true)
          setImage(require('../../assets/icons/consumer.png'))
          setTitle(Translater.Sidebar.users[language.slug])
        }
        break
      case 'staff':
        if (
          (modules.admin as any)?.staff
          && access.admin?.children?.staff?.rule
          && access.admin?.children?.staff?.rule !== 'false') {
          setLocalAccess(true)
          setImage(require('../../assets/icons/staff.png'))
          setTitle(Translater.Sidebar.staff[language.slug])
        }
        break
      case 'roles':
        if (
          (modules.admin as any)?.roles
          && access.admin?.children?.roles?.rule
          && access.admin?.children?.roles?.rule !== 'false') {
          setLocalAccess(true)
          setImage(require('../../assets/icons/role.png'))
          setTitle(Translater.Sidebar.roles[language.slug])
        }
        break
      case 'history':
        if (
          (modules.orders as any)?.history
          && access.orders?.children?.history
          && access.orders?.children?.history.rule !== 'false') {
          setLocalAccess(true)
          setImage(require('../../assets/icons/archive.png'))
          setTitle(Translater.Sidebar.history[language.slug])
        }
        break
      case 'notifications':
        if (
            modules.notifications
            && access.notifications?.rule
            && access.notifications?.rule !== 'false') {
          setLocalAccess(true)
          setImage(require('../../assets/icons/notifications.png'))
          setTitle(Translater.Sidebar.notifications[language.slug])
        }
        break
      case 'booking':
        if (
            modules.booking
            && access.booking?.rule
            && access.booking?.rule !== 'false') {
          setLocalAccess(true)
          setImage(require('../../assets/icons/booking.png'))
          setTitle(Translater.Sidebar.booking[language.slug])
        }
        break
      case 'referals':
        if (
            modules.referals
            && access.referals?.rule
            && access.referals?.rule !== 'false') {
          setLocalAccess(true)
          setImage(require('../../assets/icons/referal.png'))
          setTitle(Translater.Sidebar.referals[language.slug])
        }
        break
      case 'delivery':
        if (
            modules.delivery
            && access.delivery?.rule
            && access.delivery?.rule !== 'false') {
          setLocalAccess(true)
          setImage(require('../../assets/icons/delivery.png'))
          setTitle(Translater.Sidebar.delivery[language.slug])
        }
        break
      case 'settings':
        setLocalAccess(true)
        setImage(require('../../assets/icons/settings.png'))
        setTitle(Translater.Sidebar.settings[language.slug])
        break
      default:
        setLocalAccess(false)
    }
  }, [language])

  if (!localAccess) return null

  return (
    <NavLink
      className='sidebar-link'
      to={linkTo}
      onClick={clickHandler}
    >
      <img
        alt={slug}
        src={image}
      />
      {title}
    </NavLink>
  )
}

export default CustomNavLink
