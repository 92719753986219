import React, {
    Fragment,
    useState,
    useEffect,
    useContext, ChangeEvent,
} from 'react'

import * as Contexts from '../../../contexts'
import * as Buttons from '../../../components/Buttons'
import * as Form from '../../../components/Form'

import { config, Translater } from '../../../config'
import { useHttp } from '../../../hooks'

import { SHiddenOptions } from '../../../components/Form/static'
import {ICategory, IOption, IProduct, IRestaurant} from '../../../types/items'
import { IConfigBlockProps } from './types'
import { DefaultButton } from "../../../components/Buttons";

import AsyncSelect from 'react-select/async'


const ConfigBlock: React.FC<IConfigBlockProps> = ({
  data,
  options,
  optionValue,
  form,
  isValid,
  showPhoto = false,
  multiLangTitles,
  multiLangDescriptions,
  setCategory,
  setRestaurant,
  inputHandler,
  inputOptionHandler,
  buttonOptionHandler,
  selectHandler,
  textareaHandler
}) => {

  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { multiLang } = useContext(Contexts.ConfigContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { request } = useHttp()

  const [showValue, setShowValue] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')
  const [inputRestaurantValue, setInputRestaurantValue] = useState<string>('')
  const [categoryOptions, setCategoryOptions] = useState<any[]>([])
  const [restaurantOptions, setRestaurantOptions] = useState<any[]>([])
  const [children, setChildren] = useState<any[]>([])
  const [categorySelectValue, setCategorySelectValue] = useState<any>({
    label: '',
    value: ''
  })
  const [restaurantSelectValue, setRestaurantSelectValue] = useState<any>({
      label: '',
      value: ''
  })
 /* const [optionValue, setOptionValue] = useState<any>({
      option: '',
      price_option: '0',
  }) */
  // const [optionArr, setOptionArr] = useState<any[]>([])

  const Events = {
    inputHandler: (value: string) => {
      setInputValue(value)
    },
      inputRestaurantHandler: (value: string) => {
          setInputRestaurantValue(value)
      },
      loadChildrenOptions: async () => {
          try {
              const response: IProduct[] = await request(`${config.API}/products`, 'GET', null, {
                  Authorization: (token as string)
              })

               setChildren(response)
          } catch (e) {
              console.log(e)
          }
      },
      loadRestaurantOptions: async () => {
          try {
              const response: IRestaurant[] = await request(`${config.API}/restaurants`, 'GET', null, {
                  Authorization: (token as string)
              })

              setRestaurantOptions(response)
          } catch (e) {
              console.log(e)
          }
      },
    /*loadRestaurantOptions: async (inputValue: string) => {
        try {
            const response: IRestaurant[] = await request(`${config.API}/restaurants/search/?title=${inputValue}`, 'GET', null, {
                 Authorization: (token as string)
            })
            const formatted: IOption[] = []
            if (response.length) {
               for (const item of response) {
                  const option = {
                       value: (item._id as string),
                       label: (item.title as string)
                  }
                  formatted.push(option)
               }
            }
            setRestaurantOptions(formatted)
            return formatted
        } catch (e) {
            console.log(e)
        }
    }, */
    optionRestaurantHandler: (option: any) => {
       setRestaurantSelectValue(option)
       setRestaurant(option.value)
    },
    /*loadOptions: async (inputValue: string) => {
      try {
        const response: ICategory[] = await request(`${config.API}/categories/search/?title=${inputValue}`, 'GET', null, {
          Authorization: (token as string)
        })
        const formatted: IOption[] = []
        if (response.length) {
          for (const item of response) {
            const option = {
              value: (item._id as string),
              label: (item.title as string)
            }
            formatted.push(option)
          }
        }
        setCategoryOptions(formatted)
        return formatted
      } catch (e) {
        console.log(e)
      }
    },*/
      loadCategoriesOptions: async () => {
        try {
            const response: ICategory[] = await request(`${config.API}/categories/all`, 'GET', null, {
                Authorization: (token as string)
            })

            setCategoryOptions(response)
        } catch (e) {
            console.log(e)
        }
    },
    optionHandler: (option: any) => {
      setCategorySelectValue(option)
      setCategory(option.value)
    },
    /* inputOptionHandler: (e: ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name
        const type = e.target.type
        const value = e.target.value
        console.log(name, value);
        setOptionValue({ ...optionValue, [name]: value })
    },
    buttonOptionHandler: () => {
        setOptionArr([...optionArr, optionValue])
        options = optionArr;
        console.log(optionArr)
    },*/
    buttonHandler: () => {
      (showValue) ? setShowValue(false) : setShowValue(true)
    }
  }

  const Styles = {
    control: () => ({
      display: 'flex',
      border: '1px solid #e6e6e6',
      borderRadius: 5,
      outline: 0
    }),
    input: () => ({
      fontSize: 16
    })
  }

    useEffect(() => {
        Events.loadRestaurantOptions()
        Events.loadCategoriesOptions()
        Events.loadChildrenOptions()
    }, [])

  useEffect(() => {
    setInputValue((data?.category as any)?._id)
  }, [(data?.category as ICategory)?._id])

  useEffect(() => {
     setInputRestaurantValue((data?.restaurant as any)?._id)
  }, [(data?.restaurant as IRestaurant)?._id])

  return (
    <Fragment>

      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.title[language.slug]}
        text={access.products?.rule !== 'change' ? form.name : ''}
      >
        {access.products?.rule === 'change' ? (
          multiLang ? (
            <Form.MultiLang
              minLength={2}
              multiLangTitles={multiLangTitles}
              placeholder={Translater.Placeholders.minTwo[language.slug]}
              inputHandler={inputHandler}
            />
          ) : (
              <Form.Input
                name='name'
                placeholder={Translater.Placeholders.minTwo[language.slug]}
                isValid={isValid.name}
                value={form.name}
                inputHandler={inputHandler}
              />
            )
        ) : null}
      </Form.LabelField>

      <Form.LabelField
          fontSize='small'
          label={Translater.TableTitles.calories[language.slug]}
          text={access.products?.rule !== 'change' ? form.calories : ''}
      >
        {access.products?.rule === 'change' ? (
            multiLang ? (
                <Form.MultiLang
                    minLength={2}
                    multiLangTitles={multiLangTitles}
                    placeholder={Translater.Placeholders.minTwo[language.slug]}
                    inputHandler={inputHandler}
                />
            ) : (
                <Form.Input
                    name='calories'
                    placeholder={Translater.Placeholders.minTwo[language.slug]}
                    isValid={isValid.calories}
                    value={form.calories}
                    inputHandler={inputHandler}
                />
            )
        ) : null}
      </Form.LabelField>

      {/* <input
          name="select"
          type="checkbox"
          value={form.subcategory}
         // checked={true}
          onChange={inputHandler}
      /> */}


        <Form.LabelField
            fontSize='small'
            label={Translater.TableTitles.weight[language.slug]}
            text={access.products?.rule !== 'change' ? form.weight : ''}
        >
            {access.products?.rule === 'change' ? (
                multiLang ? (
                    <Form.MultiLang
                        minLength={2}
                        multiLangTitles={multiLangTitles}
                        placeholder={Translater.Placeholders.minTwo[language.slug]}
                        inputHandler={inputHandler}
                    />
                ) : (
                    <Form.Input
                        name='weight'
                        placeholder={Translater.Placeholders.minTwo[language.slug]}
                        isValid={isValid.weight}
                        value={form.weight}
                        inputHandler={inputHandler}
                    />
                )
            ) : null}
        </Form.LabelField>

      <Form.LabelField
           fontSize='small'
           label={Translater.TableTitles.fats[language.slug]}
           text={access.products?.rule !== 'change' ? form.fats : ''}
      >
            {access.products?.rule === 'change' ? (
                multiLang ? (
                    <Form.MultiLang
                        minLength={2}
                        multiLangTitles={multiLangTitles}
                        placeholder={Translater.Placeholders.minTwo[language.slug]}
                        inputHandler={inputHandler}
                    />
                ) : (
                    <Form.Input
                        name='fats'
                        placeholder={Translater.Placeholders.minTwo[language.slug]}
                        isValid={isValid.fats}
                        value={form.fats}
                        inputHandler={inputHandler}
                    />
                )
            ) : null}
        </Form.LabelField>

        <Form.LabelField
            fontSize='small'
            label={Translater.TableTitles.carbohydrates[language.slug]}
            text={access.products?.rule !== 'change' ? form.carbohydrates : ''}
        >
            {access.products?.rule === 'change' ? (
                multiLang ? (
                    <Form.MultiLang
                        minLength={2}
                        multiLangTitles={multiLangTitles}
                        placeholder={Translater.Placeholders.minTwo[language.slug]}
                        inputHandler={inputHandler}
                    />
                ) : (
                    <Form.Input
                        name='carbohydrates'
                        placeholder={Translater.Placeholders.minTwo[language.slug]}
                        isValid={isValid.carbohydrates}
                        value={form.carbohydrates}
                        inputHandler={inputHandler}
                    />
                )
            ) : null}
        </Form.LabelField>

        <Form.LabelField
            fontSize='small'
            label={Translater.TableTitles.proteins[language.slug]}
            text={access.products?.rule !== 'change' ? form.proteins : ''}
        >
            {access.products?.rule === 'change' ? (
                multiLang ? (
                    <Form.MultiLang
                        minLength={2}
                        multiLangTitles={multiLangTitles}
                        placeholder={Translater.Placeholders.minTwo[language.slug]}
                        inputHandler={inputHandler}
                    />
                ) : (
                    <Form.Input
                        name='proteins'
                        placeholder={Translater.Placeholders.minTwo[language.slug]}
                        isValid={isValid.proteins}
                        value={form.proteins}
                        inputHandler={inputHandler}
                    />
                )
            ) : null}
        </Form.LabelField>

      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.description[language.slug]}
        text={access.products?.rule !== 'change' ? form.description : ''}
      >
        {access.products?.rule === 'change' ? (
          multiLang ? (
            <Form.MultiLang
              minLength={2}
              multiLangDescriptions={multiLangDescriptions}
              placeholder={Translater.Placeholders.minTwo[language.slug]}
              textareaHandler={textareaHandler}
            />
          ) : (
              <Form.Textarea
                name='description'
                placeholder={Translater.Placeholders.minTwo[language.slug]}
                isValid={isValid.description}
                value={form.description}
                textareaHandler={textareaHandler}
              />
            )
        ) : null}
      </Form.LabelField>

      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.price[language.slug]}
        text={access.products?.rule !== 'change' ? `${form.price} грн` : ''}
      >
        {access.products?.rule === 'change' ? (
          <Form.Input
            type='number'
            name='price'
            value={form.price}
            inputHandler={inputHandler}
          />
        ) : null}
      </Form.LabelField>

      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.hidden[language.slug]}
        text={access.products?.rule !== 'change' ? (
          form.hidden ? (
            Translater.TableTitles.hidden.true[language.slug]
          ) : Translater.TableTitles.hidden.false[language.slug]
        ) : ''}
      >
        {access.products?.rule === 'change' ? (
          <Form.Select
            name='hidden'
            options={SHiddenOptions}
            value={(form.hidden as string)}
            selectHandler={selectHandler}
          />
        ) : null}
      </Form.LabelField>

      {/* <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.stock[language.slug]}
        text={access.products?.rule !== 'change' ? (
          form.hidden ? (
            Translater.TableTitles.hidden.true[language.slug]
          ) : Translater.TableTitles.hidden.false[language.slug]
        ) : ''}
      >
        {access.products?.rule === 'change' ? (
          <Form.Select
            name='stock'
            options={SHiddenOptions}
            value={(form.stock as string)}
            selectHandler={selectHandler}
          />
        ) : null}
      </Form.LabelField> */}

        <Form.LabelField
            fontSize='small'
            label={Translater.TableTitles.restaurants[language.slug]}
            text={access.products?.rule !== 'change' && data ? (
                (data.restaurant as IRestaurant).title ? '' : '-'
            ) : ''}
        >
            <Fragment>

        {access.products?.rule !== 'change' && data ? (
            <Buttons.Link
                title={(data?.restaurant as IRestaurant).title}
                linkTo={`/restaurants/${(data?.restaurant as IRestaurant)._id}`}
            />
        ) : null}

        {
            access.categories?.rule === 'change' ? (
                <Form.Dropdown
                    disabledValue={'Вибір ресторану'}
                    items={restaurantOptions}
                    item={form.restaurant}
                    name='restaurant'
                    changeHandler={selectHandler} />
            ) : null
        }
        {/*access.products?.rule === 'change' ? (
                    <AsyncSelect
                        cacheOptions
                        loadingMessage={() => Translater.Placeholders.loadingMessage[language.slug]}
                        noOptionsMessage={() => Translater.Placeholders.noOptionsMessage[language.slug]}
                        inputValue={inputRestaurantValue}
                        styles={Styles}
                      //  className={`select ${!isValid.restaurant && 'invalid'}`}
                        value={restaurantSelectValue}
                        defaultOptions={restaurantOptions}
                        onInputChange={Events.inputRestaurantHandler}
                        onChange={Events.optionRestaurantHandler}
                        loadOptions={Events.loadRestaurantOptions}
                    />
                ) : null*/}
    </Fragment>
    </Form.LabelField>

        <Form.LabelField
            fontSize='small'
            label={Translater.TableTitles.category[language.slug]}
            text={access.products?.rule !== 'change' && data ? (
                (data.category as ICategory).title ? '' : '-'
            ) : ''}
        >
            <Fragment>

                {access.products?.rule !== 'change' && data ? (
                    <Buttons.Link
                        title={(data.category as ICategory).title}
                        linkTo={`/categories/${(data.category as ICategory)._id}`}
                    />
                ) : null}

                {
                    access.categories?.rule === 'change' ? (
                        <Form.Dropdown
                            disabledValue={'Вибір категорії'}
                            items={categoryOptions}
                            isValid={isValid.category}
                            item={form.category}
                            name='category'
                            changeHandler={selectHandler} />
                    ) : null
                }

                {/*access.products?.rule === 'change' ? (
            <AsyncSelect
              cacheOptions
              loadingMessage={() => Translater.Placeholders.loadingMessage[language.slug]}
              noOptionsMessage={() => Translater.Placeholders.noOptionsMessage[language.slug]}
              inputValue={inputValue}
              styles={Styles}
              className={`select ${!isValid.category && 'invalid'}`}
              value={categorySelectValue}
              defaultOptions={categoryOptions}
              onInputChange={Events.inputHandler}
              onChange={Events.optionHandler}
              loadOptions={Events.loadOptions}
            />
          ) : null*/}
            </Fragment>
        </Form.LabelField>

      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.parentProduct[language.slug]}
        text={access.products?.rule !== 'change' && data ? (
          (data as IProduct).name ? '' : '-'
        ) : ''}
      >
        <Fragment>

          {access.products?.rule !== 'change' && data ? (
            <Buttons.Link
              title={(data as IProduct).name}
              linkTo={`/products/${(data as IProduct)._id}`}
            />
          ) : null}

            {
                access.categories?.rule === 'change' ? (
                    <Form.Dropdown
                        disabledValue={'Вибір батьківського продукту'}
                        items={children}
                        item={form.parent}
                        name='parent'
                        changeHandler={selectHandler} />
                ) : null
            }

          {/*access.products?.rule === 'change' ? (
            <AsyncSelect
              cacheOptions
              loadingMessage={() => Translater.Placeholders.loadingMessage[language.slug]}
              noOptionsMessage={() => Translater.Placeholders.noOptionsMessage[language.slug]}
              inputValue={inputValue}
              styles={Styles}
              className={`select ${!isValid.category && 'invalid'}`}
              value={categorySelectValue}
              defaultOptions={categoryOptions}
              onInputChange={Events.inputHandler}
              onChange={Events.optionHandler}
              loadOptions={Events.loadOptions}
            />
          ) : null*/}
        </Fragment>
      </Form.LabelField>

      {access.products?.rule === 'change' ? (
        <Form.LabelField
          fontSize='small'
          label={Translater.TableTitles.photo[language.slug]}
        >
          <Form.Input
            type='file'
            name='photo'
            isValid={isValid.photo}
            inputHandler={inputHandler}
          />
        </Form.LabelField>
      ) : null}

      {showPhoto ? (
        <Form.LabelField
          fontSize='small'
          label={access.products?.rule !== 'change' ? (
            Translater.TableTitles.photo[language.slug]
          ) : ''}
        >
          <img
            alt='Product'
            src={`${config.serverURL}${(data as IProduct).photo}`}
          />
        </Form.LabelField>
      ) : null}

    <DefaultButton
        title={Translater.Buttons.addOptions[language.slug]}
        buttonHandler={Events.buttonHandler}
    />

        {(showValue) ? <Form.LabelField
            fontSize='small'
            label={Translater.TableTitles.options_name[language.slug]}
            text={access.products?.rule !== 'change' ? optionValue.option : ''}
        >
            {access.products?.rule === 'change' ? (
                multiLang ? (
                    <Form.MultiLang
                        minLength={2}
                        multiLangTitles={multiLangTitles}
                        placeholder={Translater.Placeholders.minTwo[language.slug]}
                        inputHandler={inputOptionHandler}
                    />
                ) : (
                    <Form.Input
                        name='option'
                        placeholder={Translater.Placeholders.minTwo[language.slug]}
                        // isValid={isValid.calories}
                        value={optionValue.option}
                        inputHandler={inputOptionHandler}
                    />
                )
            ) : null}
        </Form.LabelField> : null }

        {(showValue) ? <Form.LabelField
            fontSize='small'
            label={Translater.TableTitles.options_addition[language.slug]}
            text={access.products?.rule !== 'change' ? optionValue.additions : ''}
        >
            {access.products?.rule === 'change' ? (
                multiLang ? (
                    <Form.MultiLang
                        minLength={2}
                        multiLangTitles={multiLangTitles}
                        placeholder={Translater.Placeholders.minTwo[language.slug]}
                        inputHandler={inputOptionHandler}
                    />
                ) : (
                    <Form.Input
                        name='additions'
                        placeholder={Translater.Placeholders.minTwo[language.slug]}
                        // isValid={isValid.calories}
                        value={optionValue.additions}
                        inputHandler={inputOptionHandler}
                    />
                )
            ) : null}
        </Form.LabelField> : null }

        {(showValue) ? <Form.LabelField
            fontSize='small'
            label={Translater.TableTitles.options_price[language.slug]}
            text={access.products?.rule !== 'change' ? optionValue.price_option : ''}
        >
            {access.products?.rule === 'change' ? (
                multiLang ? (
                    <Form.MultiLang
                        minLength={2}
                        multiLangTitles={multiLangTitles}
                        placeholder={Translater.Placeholders.minTwo[language.slug]}
                        inputHandler={inputOptionHandler}
                    />
                ) : (
                    <Form.Input
                        name='price_option'
                        type='number'
                        // placeholder={Translater.Placeholders.minTwo[language.slug]}
                        // isValid={isValid.calories}
                        value={optionValue.price_option}
                        inputHandler={inputOptionHandler}
                    />
                )
            ) : null}
        </Form.LabelField> : null }

        {/*(showValue) ? (
            <Form.LabelField
                fontSize='small'
                label={Translater.TableTitles.photo[language.slug]}
            >
                <Form.Input
                    type='file'
                    name='photo_option'
                    // isValid={isValid.photo}
                    inputHandler={inputOptionHandler}
                />
            </Form.LabelField>
        ) : null*/}

        {(showValue) ?
            <DefaultButton
                title={Translater.Buttons.add[language.slug]}
                buttonHandler={buttonOptionHandler}
            />  : null
        }

    </Fragment>
  )
}

export default ConfigBlock
