import React, { useContext } from 'react'

import * as Contexts from '../../../../contexts'
import * as Buttons from '../../../../components/Buttons'

import { IItemProps } from './types'


const Item: React.FC<IItemProps> = ({
  data,
  removeHandler
}) => {

  const { access } = useContext(Contexts.UserContext)

  return (
    <div
      className='similar-item border'
    >
      <Buttons.Link
        title={data.name! || data.option!}
        linkTo={`/products/${data._id}`}
      />

      {access.products?.rule === 'change' ? (
        <button
          className='btn btn--similar'
          onClick={_ => removeHandler(data._id!)}
        >
          <img
            alt='Cross'
            src={require('../../../../assets/icons/cross.png')}
          />
        </button>
      ) : null}
    </div>
  )
}

export default Item
