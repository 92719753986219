import React, { useContext } from 'react'

import * as Contexts from '../../contexts'

import { Translater } from '../../config'
import { ISelectProps } from './types'


const Select: React.FC<ISelectProps> = ({
  name,
  large = true,
  value,
  options,
  selectHandler
}) => {

  const { language } = useContext(Contexts.LanguageContext)

  return (
    <div className={large ? 'select-wrapper--default' : ''}>

      <select
        className='select'
        name={name}
        value={value}
        onChange={selectHandler}
      >
        {options.map(option => (
          <option
            key={option.value}
            value={option.value}
          >
            {option.label ? option.label : null}
            {option.slug ? Translater.Select[option.slug][language.slug] : null}
          </option>
        )
        )}
      </select>
    </div>
  )
}

export default Select