import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
} from 'react'

import * as Components from './components'
import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Form from '../../components/Form'
import * as Buttons from '../../components/Buttons'
import * as ComponentsOrders from '../../pages/Orders/components'
import * as Feedback from '../../components/Feedback'

import { useHttp } from '../../hooks/http.hook'
import { config, Translater } from '../../config'
import { useParams, useHistory } from 'react-router-dom'
import {IClient, TClientForm, IFeedback, IOrder} from '../../types/items'


const DetailPage: React.FC = () => {

  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { modules } = useContext(Contexts.ConfigContext)
  const { language } = useContext(Contexts.LanguageContext)

  const history = useHistory()
  const { id } = useParams() as any
  const { loading, request } = useHttp()

  const [primary, setPrimary] = useState<IClient | null>(null)
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [bonuses, setBonuses] = useState<any[]>([]);
  const [feedback, setFeedback] = useState<IFeedback[]>([])
  const [isValid, toggleValid] = useState({
    name: false
  })

  const [form, setForm] = useState<TClientForm>({
    name: '',
  })

  const Events = {
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name
      const value = e.target.value
      setForm({ ...form, [name]: value })
    },
    saveHandler: () => {
      let isRight: boolean = false

      for (let key in isValid) {
        //@ts-ignore
        const value = isValid[key]
        if (value) isRight = true
        else {
          isRight = false
          break
        }
      }

      if (isRight) Callbacks.Save()
    },
    sortHandler: () => {

    },
    reloadHandler: () => {
      Callbacks.FetchOrders()
    },
  }

  const Callbacks = {
    Fetch: async () => {
      try {
        const response: IClient = await request(`${config.API}/users/${id}`,
          'GET', null, {
          Authorization: (token as string)
        })

        if (response) {
          setPrimary(response)
        }
      } catch (e) {
        console.log(e)
      }
    },
    FetchOrders: async () => {
      try {
        const response: IOrder[] = await request(`${config.API}/orders/${id}/byUser`,
            'GET', null, {
              Authorization: (token as string)
            })
        const responseArchive: IOrder[] = await request(`${config.API}/orders/history/${id}/byUser`,
            'GET', null, {
              Authorization: (token as string)
            })
        console.log(response, responseArchive)

        setOrders([...response, ...responseArchive]);
      } catch (e) {
        console.log(e)
      }
    },
    FetchBonuses: async () => {
      try {
        const response: any[] = await request(`${config.API}/bonuses/${id}/byUser`,
            'GET', null, {
              Authorization: (token as string)
            })
        console.log(response)

        setBonuses(response);
      } catch (e) {
        console.log(e)
      }
    },
    Save: async () => {
      try {

        const response = await request(`${config.API}/users/${primary?._id}`, 'POST', { ...form }, {
          Authorization: (token as string),
        })

        if (response) history.push('/users')
      } catch (e) {
        console.log(e)
      }
    }
  }

  useEffect(() => {
    Callbacks.Fetch()
    Callbacks.FetchOrders()
    Callbacks.FetchBonuses()
  }, [id])

  useEffect(() => {
    if (primary) setForm({ ...form, name: primary.name })
  }, [primary])

  useEffect(() => {
    if (form.name?.length >= 2 && form.name) toggleValid({ ...isValid, name: true })
    else toggleValid({ ...isValid, name: false })
  }, [form.name])


  if (loading || !primary) return <Page.Preloader />

  return (
    <Page.Wrapper
      footer
      title={primary.name}
    >
      <Page.Header
        backButtonTitle={Translater.UsersDetailPage.title[language.slug]}
      />

      <Buttons.Container
        disabled={loading}
        saveHandler={access.users?.rule === 'change' ? Events.saveHandler : false}
      />

      <Components.ConfigBlock
        form={form}
        isValid={isValid}
        inputHandler={Events.inputHandler}
      />

      <Form.LabelField
          fontSize='small'
          label={Translater.TableTitles.countBonuses[language.slug]}
          text={primary?.bonuses ? ''+primary?.bonuses : '0'}
      >

      </Form.LabelField>

      { (modules.users as any).favourites ? (
        <Components.DetailBlock
          data={primary}
        />
      ) : null}

      <Form.DateBlock
        updated={(primary.updated as string)}
        created={(primary.created as string)}
      />

      {bonuses[0] ? 'Історія нарахувань/зписувань бонусів:': ''}
      {bonuses[0] ? <Components.BonusesTable
          items={bonuses}
      //    reloadHandler={Events.reloadHandler}
          sortHandler={Events.sortHandler}/> : <p>Користувач не користувався бонусами!</p>}

      {orders[0] ? 'Історія замовлень:' : ''}
      {(orders[0]) ? <ComponentsOrders.Table
          items={orders}
          reloadHandler={Events.reloadHandler}
          sortHandler={Events.sortHandler}/> : <p>У користувача немає замовлень!</p>}
      {/* (modules.products as any).feedback ? (
        <Feedback.Block
          userID={id}
          link={`/users/${id}`}
        />
      ) : null */}
    </Page.Wrapper>
  )
}

export default DetailPage
