import React from 'react'

const Dropdown: React.FC<any> = ({
  items,
  item,
  name,
  isValid = true,
  disabledValue,
  changeHandler = () => { }
}) => {

        return(
                <div className={`select-wrapper--default `}>
                    <select className={`select ${!isValid && 'invalid'}`} name={name} value={(item) ? item : ''} onChange={changeHandler}>
                        <option value="" disabled>{disabledValue}</option>
                        {items.map((itemMap: any) => <option key={itemMap._id} value={itemMap._id}>{(itemMap.title) ? itemMap.title : itemMap.name}</option>)}
                    </select>
                    <label></label>
                </div>
        )
};

export default Dropdown
