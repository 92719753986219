import React, {
  useState,
  useEffect,
  useContext,
} from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Components from './components'

import { useHttp } from '../../hooks'

import {
  config,
  Translater
} from '../../config'

import {
  ICategory,
} from '../../types/items'


const IndexPage: React.FC = () => {

  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { loading, request } = useHttp()

  const [primary, setPrimary] = useState<ICategory[]>([])

  const Events = {
    reloadHandler: () => {
      Callbacks.Fetch()
    }
  }

  const Callbacks = {
    Fetch: async () => {
      try {
        const response: ICategory[] = await request(`${config.API}/categories`,
          'GET', null, {
          Authorization: (token as string)
        })

        if (response) {
          setPrimary(response)
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [])


  if (loading || !primary) return <Page.Preloader />

  return (
    <Page.Wrapper
      title={Translater.CategoriesPage.title[language.slug]}
    >
      <Page.Header
        title={Translater.CategoriesPage.title[language.slug]}
        enableButton={access.categories?.rule === 'change'}
        linkTitle={Translater.Buttons.create[language.slug]}
        linkTo='/categories/create'
      />

      <Components.Table
        items={primary}
        reloadHandler={Events.reloadHandler}
      />
    </Page.Wrapper>
  )
}

export default IndexPage