import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
} from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Buttons from '../../components/Buttons'
import * as Components from './components'

import { config, Translater } from '../../config'
import { useHttp } from '../../hooks/http.hook'
import { ICategory } from '../../types/items'
import {
  TMutliLangDescriptions,
  TMutliLangTitles,
  TPossibleMultiLangDescriptions,
  TPossibleMultiLangTitle
} from '../../types/multiLang'
import { useHistory } from 'react-router-dom'



const CreatePage: React.FC = () => {

  const { token } = useContext(Contexts.AuthContext)
  const { multiLang } = useContext(Contexts.ConfigContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { loading, request } = useHttp()
  const history = useHistory()

  const [isValid, toggleValid] = useState({
    title: false,
    photo: false
  })
  const [form, setForm] = useState<ICategory>({
    title: '',
    description: '',
    hidden: 'true',
    parent_id: '',
    restaurant: '',
    photo: '',
  })
  const [multiLangTitles, setMultiLangTitles] = useState<TMutliLangTitles>({
    "title[EN]": '',
    "title[RU]": '',
    "title[UA]": ''
  })

  const [multiLangDescriptions, setMultiLangDescriptions] = useState<TMutliLangDescriptions>({
    "description[EN]": '',
    "description[RU]": '',
    "description[UA]": ''
  })

  const Events = {
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      //@ts-ignore
      if (e.target.type === 'file') setForm({ ...form, photo: e.target.files[0] })
      else if (e.target.name.split('[')[1]) {
        setMultiLangTitles({
          ...multiLangTitles, [e.target.name]: e.target.value
        })
      }
      else setForm({ ...form, [e.target.name]: e.target.value })
    },
    textareaHandler: (e: ChangeEvent<HTMLTextAreaElement>) => {
      if (e.target.name.split('[')[1]) {
        setMultiLangDescriptions({
          ...multiLangDescriptions, [e.target.name]: e.target.value
        })
      } else setForm({ ...form, [e.target.name]: e.target.value })
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => { console.log(e.target.name, e.target.value)
      setForm({ ...form, [e.target.name]: e.target.value })
    },
    setParentID: (parent_id: string) => {
      setForm({ ...form, parent_id })
    },
    setRestaurant: (restaurant: string) => {
      setForm({ ...form, restaurant })
    },
    createHandler: () => {
      if (isValid.title && isValid.photo) Callbacks.Create()
    }
  }

  const Callbacks = {
    Create: async () => {
      try {
        const data = new FormData()
        console.log(form)

        if (multiLang) {
          for (let key in multiLangTitles) {
            data.append(key, multiLangTitles[(key as TPossibleMultiLangTitle)])
          }
        } else data.append('title', form.title)

        if (multiLang) {
          for (let key in multiLangDescriptions) {
            data.append(key, multiLangDescriptions[(key as TPossibleMultiLangDescriptions)])
          }
        } else data.append('description', form.description)

        data.append('hidden', (form.hidden as string))
        if (form.restaurant) data.append('restaurant', (form.restaurant as string))
        data.append('photo', form.photo)

        if (form.parent_id) {
          data.append('parent_id', form.parent_id)
        }

        const response = await request(`${config.API}/categories`, 'POST', data, {
          Authorization: (token as string),
        })

        if (response.created) {
          history.goBack()
          setForm({ ...form, title: '', parent_id: '', hidden: 'true', photo: '' })
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  useEffect(() => {
    if (multiLang) {
      let isRight = true

      for (let key in multiLangTitles) {
        if (multiLangTitles[(key as TPossibleMultiLangTitle)].length >= 2 && isRight) {
          toggleValid({ ...isValid, title: true })
          isRight = true
        } else {
          isRight = false
          toggleValid({ ...isValid, title: false })
        }
      }
    } else {
      if (form.title.length >= 2) toggleValid({ ...isValid, title: true })
      else toggleValid({ ...isValid, title: false })
    }
  }, [form.title, multiLangTitles])

  useEffect(() => {
    if (form.photo) toggleValid({ ...isValid, photo: true })
    else toggleValid({ ...isValid, photo: false })
  }, [form.photo])


  return (
      <Page.Wrapper
          title={Translater.CategoriesCreatePage.title[language.slug]}
      >
        <Page.Header
            backButtonTitle={Translater.CategoriesCreatePage.title[language.slug]}
        />

        <Buttons.Container
            disabled={loading}
            createHandler={Events.createHandler}
        />

        <Components.ConfigBlock
            form={form}
            isValid={isValid}
            multiLangValues={multiLangTitles}
            multiLangDescriptions={multiLangDescriptions}
            setRestaurant={Events.setRestaurant}
            setParentID={Events.setParentID}
            inputHandler={Events.inputHandler}
            selectHandler={Events.selectHandler}
            textareaHandler={Events.textareaHandler}
        />

      </Page.Wrapper>
  )
}

export default CreatePage
