import { ITableTitle } from './../../../../types/table'

export const SHomeTitles: ITableTitle[] = [
//  { type: 'header', slug: 'increment' },
  { type: 'header', slug: 'photo' },
  { type: 'header', slug: 'price' },
//  { type: 'header', slug: 'hidden' },
//  { type: 'header', slug: 'created' },
//  { type: 'header', slug: 'updated' },
  { type: 'header', slug: '' },
]
