import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
} from 'react'

import * as Contexts from '../../../../contexts'
import * as Table from '../../../../components/Table'

import { useHistory } from 'react-router-dom'
import { config, Translater } from '../../../../config'
import { useHttp } from '../../../../hooks/http.hook'
import { ICategory } from '../../../../types/items'
import { ITrackProps } from './types'


const Track: React.FC<ITrackProps> = ({
  data,
  nesting,
  reloadHandler
}) => {

  const history = useHistory()

  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { loading, request } = useHttp()

  const [showChildren, toggleChildren] = useState<boolean>(false)
  const [children, setChildren] = useState<ICategory[]>([])

  const Events = {
    childrenHandler: () => {
      if (!loading) toggleChildren(!showChildren)
    },
    deleteHandler: () => {
      const answer = window.confirm(Translater.Alert.delete[language.slug])
      if (answer) Callbacks.Delete()
    },
    editHandler: () => {
      history.push(`/categories/${data._id}`)
    }
  }

  const Callbacks = {
    FetchChildren: async () => {
      try {
        const response: ICategory = await request(`${config.API}/categories/${data._id}`, 'GET', null, {
          Authorization: (token as string)
        })

        if (response.children) setChildren(response.children)
      } catch (e) {
        console.log(e)
      }
    },
    Delete: async () => {
      try {
        await request(`${config.API}/categories/${data._id}`, 'DELETE', null, {
          Authorization: (token as string),
        })

        reloadHandler()
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    Callbacks.FetchChildren()
  }, [])

  return (
    <Fragment>
      <Table.Track
        type='categories'
        clickHandler={Events.childrenHandler}
      >

        <Table.Cell
          type='custom'
          style={{
            paddingLeft: (nesting + 1) * 20
          }}
        >
          {children.length ? (
            <img
              alt='Nesting'
              className={`nesting-arrow ${showChildren && 'active'}`}
              src={require('../../../../assets/icons/arrow-bottom.png')}
            />
          ) : (
              <img
                alt='Dow'
                className='nesting-dot'
                src={require('../../../../assets/icons/dot.png')}
              />
            )}
        </Table.Cell>

        <Table.Cell
          type='image'
          imageURL={`${config.serverURL}${data.photo}`}
        />

        <Table.Cell
          text={data.title}
        />

        <Table.Cell
          text={data.hidden ? (
            Translater.TableTitles.hidden.true[language.slug]
          ) : Translater.TableTitles.hidden.false[language.slug]}
        />

        <Table.Cell
          text={new Date((data.updated as string)).toLocaleDateString()}
        />

        <Table.Cell
          text={new Date((data.created as string)).toLocaleDateString()}
        />

        <Table.Cell
          type='control'
          editButton
          deleteButton={access.categories?.rule === 'change' && true}
          editHandler={Events.editHandler}
          deleteHandler={Events.deleteHandler}
        />
      </Table.Track>

      {showChildren && children ? (
        children.map(child => (
          <Track
            key={child._id}
            data={child}
            nesting={nesting + 1}
            reloadHandler={reloadHandler}
          />
        ))
      ) : null}

    </Fragment>
  )
}

export default Track