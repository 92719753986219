import React, {
    useState,
    useEffect,
    useContext, ChangeEvent, useRef, Fragment,
} from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import { Editor } from '@tinymce/tinymce-react';
import { Popover, ArrowContainer } from "react-tiny-popover";

// import * as Components from './components'

import { useHttp } from '../../hooks'

import {
    config,
    Translater
} from '../../config'

import {
    ICategory,
} from '../../types/items'
import * as Form from "../../components/Form";


const IndexPage: React.FC = () => {

    const { token } = useContext(Contexts.AuthContext)
    const { access } = useContext(Contexts.UserContext)
    const { language } = useContext(Contexts.LanguageContext)

    const { loading, request } = useHttp()

    const [deliveryTypes, setDeliveryTypes] = useState<any>([
        {
            _id: 'self',
            title: 'Самовивіз'
        },
        {
            _id: 'delivery',
            title: 'Доставка'
        }]);
    const [deliveryType, setDeliveryType] = useState<any>()
    const [primary, setPrimary] = useState<any>({})
    const [editorContent, setEditorContent] = useState<any>();
    const [form, setForm] = useState<any>({
        percents: 0,
        info: '',
        type: 'self'
    });
    const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
    const clickMeButtonRef = useRef<HTMLButtonElement | undefined>();

    const Events = {
        inputHandler: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
            const name = e.target.name
            const value = e.target.value

            console.log(value)
            setForm({...form, [name]: value})
        },
        selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
            setForm({ ...form, [e.target.name]: e.target.value })
        },
        reloadHandler: () => {
            Callbacks.Fetch()
        },
        handleEditorChange: (content: any, editor: any) => {
            setEditorContent(content)
            console.log('Content was updated:', content);
        },
        textareaHandler: (e: ChangeEvent<HTMLTextAreaElement>) => {
            setForm({ ...form, [e.target.name]: e.target.value })
        },
        buttonHandler: () => {
            Callbacks.SaveData();
         //   console.log(form.percents, editorContent)
        }
    }

    const Callbacks = {
        Fetch: async () => {
            try {
                const response: any = await request(`${config.API}/delivery`,
                    'GET', null, {
                        Authorization: (token as string)
                    })
                const responsePercents: any = await request(`${config.API}/users/findPercents`, 'GET', null, {
                    Authorization: (token as string)
                });
               // console.log(responsePercents)

                if (response[0]) {
                    setEditorContent(response[0].content)
                    setDeliveryType(response[0].type)
                    setPrimary(response[0])
                    console.log(response[0])
                    setForm({...form, percents: responsePercents.percents, type: response[0].typeDel, info: response[0].info})
                }
            } catch (e) {
                console.log(e)
            }
        },
        SaveData: async () => {
            try { console.log(editorContent, form.type);
                if (!primary.content) {
                    await request(`${config.API}/delivery`, 'POST', {content: '' + editorContent, typeDel: form.type, info: form.info},
                        {
                            Authorization: (token as string)
                        })
                } else {
                    await request(`${config.API}/delivery/${primary._id}`, 'POST', {content: '' + editorContent, typeDel: form.type, info: form.info},
                        {
                            Authorization: (token as string)
                        })
                }
                await request(`${config.API}/users/setBonuses`, 'POST', { percents: form.percents }, {
                    Authorization: (token as string)
                });
                await window.location.reload(true)
            } catch (e) {
                console.log(e)
            }
        }
    }

    useEffect(() => {
        Callbacks.Fetch()
    }, [])


    if (loading || !primary) return <Page.Preloader />

    return (
        <Page.Wrapper
            title={Translater.DeliveryPage.title[language.slug]}
        >
            <Page.Header
                title={Translater.DeliveryPage.title[language.slug]}
               // enableButton={access.categories?.rule === 'change'}
                btnTitle={Translater.Buttons.save[language.slug]}
                buttonHandler={Events.buttonHandler}
               // linkTo='/categories/create'
            />

            <Editor
                initialValue={primary?.content}
                init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount image'
                    ],
                    toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | image code',

                    /* without images_upload_url set, Upload tab won't show up*/
                    image_title: false,
                    /* enable automatic uploads of images represented by blob or data URIs*/
                    automatic_uploads: false,
                    images_upload_url: 'postAcceptor.php',

                    /* we override default upload handler to simulate successful upload*/
                   /* images_upload_handler: function (blobInfo, success, failure) {
                        setTimeout(async function () {
                         //   success('http://moxiecode.cachefly.net/tinymce/v9/images/logo.png');
                            console.log(blobInfo.blob(), blobInfo.blobUri)
                            /* no matter what you upload, we will turn it into TinyMCE logo :)*/
                        //    const data = new FormData();

                        //    data.append('photo_delivery', blobInfo.blob());

                        /*    const response = await request(`${config.API}/delivery/uploadFiles`, 'POST', data,
                                {
                                    Authorization: (token as string)
                                })
                        //    console.log(response)
                        //   if (response) success('http://localhost:8071/' + response);

                        }, 2000);
                    },*/

                  //  images_upload_credentials: true
                }}
                onEditorChange={Events.handleEditorChange}
            />

            <Form.LabelField
                fontSize='small'
                label={Translater.TableTitles.bonusesPercents[language.slug]}
               // text={form.percents}
            >
                {(
                        <Form.Input
                            name='percents'
                            placeholder={Translater.Placeholders.minTwo[language.slug]}
                          //  isValid={isValid.title}
                            type={'number'}
                            value={form.percents}
                            inputHandler={Events.inputHandler}
                        />
                )}
            </Form.LabelField>

            <Form.Dropdown
                disabledValue={'Вибір способу доставки'}
                items={deliveryTypes}
                item={form.type}
                name='type'
                changeHandler={Events.selectHandler} />

            <Form.LabelField
                fontSize='small'
                label={Translater.TableTitles.info[language.slug]}
                text={access.products?.rule !== 'change' ? form.info : ''}
            >
                {((
                        <Form.Textarea
                            name='info'
                            placeholder={Translater.Placeholders.minTwo[language.slug]}
                            //  isValid={isValid.description}
                            value={form.info}
                            textareaHandler={Events.textareaHandler}
                        />
                    )
                )}
            </Form.LabelField>

            {/* <Popover
                isOpen={isPopoverOpen}
                positions={['top', 'right', 'left', 'bottom']}
                padding={10}
                onClickOutside={() => setIsPopoverOpen(false)}
               // ref={clickMeButtonRef} // if you'd like a ref to your popover's child, you can grab one here
                content={({position, childRect, popoverRect}) => (
                    <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                        position={position}
                        childRect={childRect}
                        popoverRect={popoverRect}
                        arrowColor={'black'}
                        arrowSize={10}
                        arrowStyle={{opacity: 0.7}}
                        className='popover-arrow-container'
                        arrowClassName='popover-arrow'
                    >
                        <div
                            style={{backgroundColor: 'white', opacity: 0.7, border: '1px double black'}}
                           // onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                        >

                            доставка 20 км - 50 грн
                            <br />
                            доставка по городу - 10 грн
                        </div>
                    </ArrowContainer>
                )}
            >
                <img className={'btn-popover'} onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                alt='Logo'
                src={require('../../assets/icons/info.png')}
                />
            </Popover> */}
        </Page.Wrapper>
    )
}

export default IndexPage
