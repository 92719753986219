import React, {
  Fragment,
  useState,
  useContext,
  useEffect,
} from 'react'

import * as Contexts from '../../../contexts'
import * as Buttons from '../../../components/Buttons'
import * as Form from '../../../components/Form'

import { config, Translater } from '../../../config'
import { useHttp } from '../../../hooks/http.hook'

import { SHiddenOptions } from '../../../components/Form/static'
import {ICategory, IOption, IRestaurant} from '../../../types/items'
import { IConfigBlockProps } from './types'

import AsyncSelect from 'react-select/async'


const ConfigBlock: React.FC<IConfigBlockProps> = ({
 data,
 form,
 isValid,
 multiLangValues,
 multiLangDescriptions,
 setRestaurant,
 setParentID,
 inputHandler,
 selectHandler,
 textareaHandler,
}) => {

  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { multiLang } = useContext(Contexts.ConfigContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { request } = useHttp()

  const [inputValue, setInputValue] = useState<string>('')
  const [inputRestaurantValue, setInputRestaurantValue] = useState<string>('')
  const [categoryOptions, setCategoryOptions] = useState<any[]>([])
  const [restaurantOptions, setRestaurantOptions] = useState<any[]>([])
  const [categorySelectValue, setCategorySelectValue] = useState<any>({
    label: '',
    value: ''
  })
  const [restaurantSelectValue, setRestaurantSelectValue] = useState<any>({
    label: '',
    value: ''
  })

  const Events = {
    inputHandler: (value: string) => {
      setInputValue(value)
    },
    inputRestaurantHandler: (value: string) => {
      setInputRestaurantValue(value)
    },
    loadRestaurantOptions: async () => {
      try {
        const response: IRestaurant[] = await request(`${config.API}/restaurants`, 'GET', null, {
          Authorization: (token as string)
        })

        setRestaurantOptions(response)
      } catch (e) {
        console.log(e)
      }
    },
    loadCategoriesOptions: async () => {
      try {
        const response: ICategory[] = await request(`${config.API}/categories`, 'GET', null, {
          Authorization: (token as string)
        })

        setCategoryOptions(response)
      } catch (e) {
        console.log(e)
      }
    },
   /* loadOptions: async (inputValue: string) => {
      try {
        const response: ICategory[] = await request(`${config.API}/categories/search/?title=${inputValue}`, 'GET', null, {
          Authorization: (token as string)
        })
        const formatted: IOption[] = []
        if (response.length) {
          for (const item of response) {
            const option = {
              value: (item._id as string),
              label: (item.title as string)
            }
            formatted.push(option)
          }
        }
        setCategoryOptions(formatted)
        return formatted
      } catch (e) {
        console.log(e)
      }
    },*/
    optionHandler: (option: any) => {
      setCategorySelectValue(option)
      setParentID(option.value)
    },
   /* loadRestaurantOptions: async (inputValue: string) => {
      try {
        const response: IRestaurant[] = await request(`${config.API}/restaurants/search/?title=${inputValue}`, 'GET', null, {
          Authorization: (token as string)
        })
        const formatted: IOption[] = []
        if (response.length) {
          for (const item of response) {
            const option = {
              value: (item._id as string),
              label: (item.title as string)
            }
            formatted.push(option)
          }
        }
        setRestaurantOptions(formatted)
        return formatted
      } catch (e) {
        console.log(e)
      }
    },*/
    optionRestaurantHandler: (option: any) => {
      setRestaurantSelectValue(option)
      setRestaurant(option.value)
    },
  }

  const Styles = {
    control: () => ({
      display: 'flex',
      border: '1px solid #e6e6e6',
      borderRadius: 5,
      outline: 0
    }),
    input: () => ({
      fontSize: 16
    })
  }

  useEffect(() => {
    Events.loadRestaurantOptions()
    Events.loadCategoriesOptions()
  }, [])

  useEffect(() => {
    if (data?.parentCategory) {
      setInputValue((data?.parentCategory as any)._id)
    }
  }, [data])

  useEffect(() => {
    setInputRestaurantValue((data?.restaurant as any)?._id)
  }, [(data?.restaurant as IRestaurant)?._id])


  return (
      <Fragment>

        <Form.LabelField
            fontSize='small'
            label={Translater.TableTitles.title[language.slug]}
            text={access.categories?.rule !== 'change' ? form.title : ''}
        >
          {access.categories?.rule === 'change' ? (
              multiLang ? (
                  <Form.MultiLang
                      placeholder={Translater.Placeholders.minTwo[language.slug]}
                      minLength={2}
                      multiLangTitles={multiLangValues}
                      inputHandler={inputHandler}
                  />
              ) : (
                  <Form.Input
                      name='title'
                      placeholder={Translater.Placeholders.minTwo[language.slug]}
                      isValid={isValid.title}
                      value={form.title}
                      inputHandler={inputHandler}
                  />
              )
          ) : null}
        </Form.LabelField>

        <Form.LabelField
            fontSize='small'
            label={Translater.TableTitles.restaurants[language.slug]}
            text={access.categories?.rule !== 'change' && data ? (
                (data.restaurant as IRestaurant).title ? '' : '-'
            ) : ''}
        >
          <Fragment>

            {access.categories?.rule !== 'change' && data ? (
                <Buttons.Link
                    title={(data?.restaurant as IRestaurant).title}
                    linkTo={`/restaurants/${(data?.restaurant as IRestaurant)._id}`}
                />
            ) : null}

            {
              access.categories?.rule === 'change' ? (
                  <Form.Dropdown
                      disabledValue={'Вибір ресторану'}
                      items={restaurantOptions}
                      item={form.restaurant}
                      name='restaurant'
                      changeHandler={selectHandler} />
              ) : null
            }
            {/*access.categories?.rule === 'change' ? (
                <AsyncSelect
                    cacheOptions
                    loadingMessage={() => Translater.Placeholders.loadingMessage[language.slug]}
                    noOptionsMessage={() => Translater.Placeholders.noOptionsMessage[language.slug]}
                    inputValue={inputRestaurantValue}
                    styles={Styles}
                    //  className={`select ${!isValid.restaurant && 'invalid'}`}
                    value={restaurantSelectValue}
                    defaultOptions={restaurantOptions}
                    onInputChange={Events.inputRestaurantHandler}
                    onChange={Events.optionRestaurantHandler}
                    loadOptions={Events.loadRestaurantOptions}
                />
            ) : null*/}
          </Fragment>
        </Form.LabelField>

        <Form.LabelField
            fontSize='small'
            label={Translater.TableTitles.hidden[language.slug]}
            text={access.categories?.rule !== 'change' ? (
                form.hidden ? (
                    Translater.TableTitles.hidden.true[language.slug]
                ) : Translater.TableTitles.hidden.false[language.slug]
            ) : ''}
        >
          {access.categories?.rule === 'change' ? (
              <Form.Select
                  name='hidden'
                  options={SHiddenOptions}
                  value={(form.hidden as string)}
                  selectHandler={selectHandler}
              />
          ) : null}
        </Form.LabelField>

        <Form.LabelField
            fontSize='small'
            label={Translater.TableTitles.parentCategory[language.slug]}
            text={access.categories?.rule !== 'change' && data ? (
                data.parentCategory?.title ? '' : '-'
            ) : ''}
        >
          {access.categories?.rule !== 'change' && data && data.parentCategory?.title ? (
              <Buttons.Link
                  title={data.parentCategory?.title}
                  linkTo={`/categories/${data.parentCategory._id}`}
              />
          ) : null}

          {
            access.categories?.rule === 'change' ? (
                <Form.Dropdown
                    disabledValue={'Вибір батьківської категорії'}
                    items={categoryOptions}
                    item={form.parent_id}
                    name='parent_id'
                    changeHandler={selectHandler} />
            ) : null
          }

          {/*access.categories?.rule === 'change' ? (
              <AsyncSelect
                  cacheOptions

                  loadingMessage={() => Translater.Placeholders.loadingMessage[language.slug]}
                  noOptionsMessage={() => Translater.Placeholders.noOptionsMessage[language.slug]}
                  styles={Styles}
                  inputValue={inputValue}
                  value={categorySelectValue}
                  defaultOptions={categoryOptions}
                  onInputChange={Events.inputHandler}
                  onChange={Events.optionHandler}
                  loadOptions={Events.loadOptions}
              />
          ) : null*/}
        </Form.LabelField>

        {access.categories?.rule === 'change' ? (
            <Form.LabelField
                fontSize='small'
                label={Translater.TableTitles.photo[language.slug]}
            >
              <Form.Input
                  type='file'
                  name='photo'
                  isValid={isValid.photo}
                  inputHandler={inputHandler}
              />
            </Form.LabelField>
        ) : null}

          <Form.LabelField
              fontSize='small'
              label={Translater.TableTitles.description[language.slug]}
              text={access.products?.rule !== 'change' ? form.description : ''}
          >
              {access.categories?.rule === 'change' ? (
                  multiLang ? (
                      <Form.MultiLang
                          minLength={2}
                          multiLangDescriptions={multiLangDescriptions}
                          placeholder={Translater.Placeholders.minTwo[language.slug]}
                          textareaHandler={textareaHandler}
                      />
                  ) : (
                      <Form.Textarea
                          name='description'
                          placeholder={Translater.Placeholders.minTwo[language.slug]}
                        //  isValid={isValid.description}
                          value={form.description}
                          textareaHandler={textareaHandler}
                      />
                  )
              ) : null}
          </Form.LabelField>
      </Fragment>
  )
}

export default ConfigBlock
