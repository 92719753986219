import React, { useContext, useState, useEffect } from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Components from './components'

import { IOrder } from '../../types/items'
import { useHttp } from '../../hooks/http.hook'
import { useParams } from 'react-router-dom'
import { config, Translater } from '../../config'


const DetailPage: React.FC = () => {

  const { token } = useContext(Contexts.AuthContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { id } = useParams()
  const { loading, request } = useHttp()

  const [primary, setPrimary] = useState<IOrder | null>(null)

  const Callbacks = {
    Fetch: async () => {
      try {
        const response: IOrder = await request(`${config.API}/orders/history/${id}`,
          'GET', null, {
          Authorization: (token as string)
        })

        if (response) {
          setPrimary(response)
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [])

  if (loading || !primary) return <Page.Preloader />

  return (
    <Page.Wrapper
      title={`${Translater.OrdersDetailPage.title[language.slug]} №${primary.increment}`}
    >
      <Page.Header
        backButtonTitle={Translater.OrdersDetailPage.title[language.slug]}
      />

      <Components.DetailBlock
        data={primary}
      />

      <Components.ProductsList
        items={primary.items}
      />

    </Page.Wrapper>
  )
}

export default DetailPage