import React, { useState, useEffect, useContext, ChangeEvent } from "react";

import * as Page from "../../components/Page";
import * as Contexts from "../../contexts";
import * as Components from "./components";
import * as CSV from "../../components/ExcelUpload";

import { useHttp } from "../../hooks";

import { config, Translater } from "../../config";

import { IOrder, IPagination, IFilter, TSortBy } from "../../types/items";

const IndexPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext);
  const { language } = useContext(Contexts.LanguageContext);

  const { loading, totalCount, request } = useHttp();

  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    perPage: 10,
    totalCount: 0,
  });
  const [filter, setFilter] = useState<IFilter>({
    sortBy: "updated",
    sortOrder: "desc",
    dateFrom: "",
    dateTo: "",
  });

  const [primary, setPrimary] = useState<IOrder[]>([]);
  const [xslsData, setXSLSData] = useState<any>();

  const Events = {
    inputHandler: (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilter({ ...filter, [e.target.name]: e.target.value });
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setPagination({ ...pagination, page: 1, perPage: +e.target.value });
    },
    sortHandler: (name: TSortBy) => {
      let sortOrder: "asc" | "desc";
      if (filter.sortOrder === "asc") sortOrder = "desc";
      else sortOrder = "asc";
      setFilter({ ...filter, sortBy: name, sortOrder });

      setPagination({ ...pagination, page: 1 });
    },
    paginateHandler: (index: number) => {
      setPagination({ ...pagination, page: index });
    },
    reloadHandler: () => {
      Callbacks.Fetch();
    },
  };

  const Callbacks = {
    Fetch: async () => {
      try {
        const response: IOrder[] = await request(
          `${config.API}/orders/history/?page=${pagination.page}&perPage=${pagination.perPage}&sortOrder=${filter.sortOrder}&sortBy=${filter.sortBy}&createdAt[]=${filter.dateFrom}&createdAt[]=${filter.dateTo}`,
          "GET",
          null,
          {
            Authorization: token as string,
          }
        );
        const responseSXLS: IOrder[] = await request(
          `${config.API}/orders/history/?createdAt[]=${filter.dateFrom}&createdAt[]=${filter.dateTo}`,
          "GET",
          null,
          {
            Authorization: token as string,
          }
        );

        const Arr: any = [];
        let sum: number = 0;
        let cash: number = 0;
        let online: number = 0;
        let products: string;

        responseSXLS.forEach((resp: any) => {
          products = "";
          resp.items.forEach((rsp: any, key: number) => {
            //  products += ' ' + rsp.name + (rsp.stock) ? '(Акція)' : '';
            products += `${rsp.name}(${rsp.count}шт)${
              rsp.stock ? "(Акція)" : ""
            }${key < resp.length - 1 ? "," : ""}`;
          });
          Arr.push({
            number: resp.increment,
            client: resp.user?.name,
            date: new Date(resp.created).toLocaleDateString(),
            items: products,
            paidByBonuses: resp?.paidByBonuses + "грн",
            address: resp?.delivery?.address?.formatted_address,
            price:
              resp?.price +
              "грн" +
              "(" +
              (resp?.paymentType === "cash" ? "Наличные" : "Онлайн") +
              ")",
          });
          if (resp?.paymentType === "cash") {
            cash += resp?.price;
          } else {
            online += resp?.price;
          }
          sum += resp.price;
        });
        Arr.push({
          sumOfCashPaidOrders: cash,
          sumOfOnlinePaidOrders: online,
          sumOfAllOrders: sum,
          countOrders: Arr.length,
        });

        if (response) {
          setXSLSData(Arr);
          setPrimary(response);
        }
      } catch (e) {
        console.log(e);
      }
    },
  };

  useEffect(() => {
    setPagination({ ...pagination, totalCount });
  }, [totalCount]);

  useEffect(() => {
    Callbacks.Fetch();
  }, [
    filter.sortBy,
    filter.sortOrder,
    pagination.page,
    pagination.perPage,
    filter.dateFrom,
    filter.dateTo,
  ]);

  if (loading || !primary) return <Page.Preloader />;

  return (
    <Page.Wrapper footer title={Translater.ArchivePage.title[language.slug]}>
      <Page.Header title={Translater.ArchivePage.title[language.slug]} />

      <Page.Filter agePicker form={filter} events={Events} />

      <Page.SortBar
        perPage={pagination.perPage}
        filter={filter}
        selectHandler={Events.selectHandler}
      />

      <CSV.ExcelUpload csvData={xslsData} fileName={"archiveOrders"} />

      <Components.Table
        items={primary}
        sortHandler={Events.sortHandler}
        reloadHandler={Events.reloadHandler}
      />

      <Page.Footer>
        <Page.Pagination
          linkTo="/archive"
          pagination={pagination}
          paginateHandler={Events.paginateHandler}
        />
      </Page.Footer>
    </Page.Wrapper>
  );
};

export default IndexPage;
