import { IOption } from './../../../types/items'

export const SOptions: IOption[] = [
  { value: 'new', slug: 'new' },
  { value: 'working', slug: 'working' },
  { value: 'ready', slug: 'ready' },
  { value: 'delivering', slug: 'delivering' },
  { value: 'rejected', slug: 'rejected' },
  { value: 'done', slug: 'done' },
  { value: 'payment-paid', slug: 'payment-paid' },
  { value: 'payment-error', slug: 'payment-error' },
]
