import React, {
    Fragment,
    useState,
    useEffect,
    useContext, ChangeEvent,
} from 'react'

import * as Contexts from '../../../contexts'
import * as Buttons from '../../../components/Buttons'
import * as Form from '../../../components/Form'

import { config, Translater } from '../../../config'
import { useHttp } from '../../../hooks'

import {IStaff, IRole, ICategory, ICity, IRestaurant} from '../../../types/items'
import { IConfigBlockProps } from './types'

import AsyncSelect from 'react-select/async'
import * as Page from "../../../components/Page";

const ConfigBlock: React.FC<IConfigBlockProps> = ({
 data,
 form,
 isValid,
 isCreate = false,
 inputHandler,
 selectHandler,
 }) => {

    const { token } = useContext(Contexts.AuthContext)
    const { access } = useContext(Contexts.UserContext)
    const { language } = useContext(Contexts.LanguageContext)

    const { request } = useHttp()

    const [inputValue, setInputValue] = useState<string>('')


    const Events = {
        inputHandler: (value: string) => {
            setInputValue(value)
        },
        textareaHandler: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const name = e.target.name
        const value = e.target.value

       // setForm({...form, [name]: value})
    },
    }

    const Styles = {
        control: () => ({
            display: 'flex',
            border: '1px solid #e6e6e6',
            borderRadius: 5,
            outline: 0
        }),
        input: () => ({
            fontSize: 16
        })
    }

   /* useEffect(() => {
        if (data) setInputValue((data.role as IRole)?.title)
    }, [(data?.role as IRole)?.title])
    useEffect(() => {
        setInputValue((data?.city as ICity)?.name)
    }, [(data?.city as ICity)?.name])*/


    return (
        <Fragment>
            <Form.LabelField
                fontSize='small'
                label={Translater.TableTitles.title[language.slug]}
                text={access.restaurants?.rule !== 'change' ? form.title : ''}
            >
                {access.restaurants?.rule === 'change' ? (
                    <Form.Input
                        name='price'
                        type={'number'}
                        value={form.price}
                        isValid={isValid.price}
                      //  placeholder={Translater.Placeholders.minThree[language.slug]}
                        inputHandler={inputHandler}
                    />
                ) : null}
            </Form.LabelField>

            <Form.LabelField
                fontSize='small'
                label={Translater.TableTitles.photo[language.slug]}
            >
                <Form.Input
                    type='file'
                    name='photo'
                   // isValid={isValid.photo}
                    inputHandler={inputHandler}
                />
            </Form.LabelField>

            <Form.LabelField
                fontSize='small'
                label={Translater.TableTitles.description[language.slug]}
              // text={form.description}
            >
                {(
                    <Form.Textarea
                        name='description'
                        placeholder={Translater.Placeholders.minTwo[language.slug]}
                        // isValid={isValid.description}
                        value={form.description}
                        textareaHandler={inputHandler}
                    />
                )}
            </Form.LabelField>
        </Fragment>
    )
}

export default ConfigBlock
