import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
} from 'react'

import * as Contexts from '../../contexts'
import * as Buttons from '../../components/Buttons'
import * as Page from '../../components/Page'
import * as Components from './components'

import { useHttp } from '../../hooks/http.hook'
import {
  config,
  Translater
} from '../../config'

import { IProduct, IOptions } from '../../types/items'
import {
  TMutliLangTitles,
  TMutliLangDescriptions,
  TPossibleMultiLangTitle,
  TPossibleMultiLangDescriptions
} from '../../types/multiLang'
import { useHistory } from 'react-router-dom'



const CreatePage: React.FC = () => {

  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)
  const { multiLang, modules } = useContext(Contexts.ConfigContext)

  const { loading, request } = useHttp()
  const history = useHistory()

  const [isValid, toggleValid] = useState({
    name: false,
    photo: false,
    category: false,
    description: false,
   /* calories: false,
    fats: false,
    carbohydrates: false,
    proteins: false,
    weight: false, */
  })

  const [form, setForm] = useState<IProduct>({
    name: '',
    price: '0',
    photo: '',
    parent: '',
    // stock: 'true',
    hidden: 'true',
    category: '',
    restaurant: '',
    description: '',
    options: [],
    calories: '',
    fats: '',
    carbohydrates: '',
    proteins: '',
    weight: '',
  })

  const [optionValue, setOptionValue] = useState<any>({
    option: '',
    photo_option: '',
    price_option: '0',
    additions: '',
  })

  const [options, setOptions] = useState<IOptions[]>([])

  const [similar, setSimilar] = useState<string[]>([])

  const [multiLangTitles, setMultiLangTitles] = useState<TMutliLangTitles>({
    "title[EN]": '',
    "title[RU]": '',
    "title[UA]": ''
  })

  const [multiLangDescriptions, setMultiLangDescriptions] = useState<TMutliLangDescriptions>({
    "description[EN]": '',
    "description[RU]": '',
    "description[UA]": ''
  })

  const Events = {
    inputOptionHandler: (e: ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name
      const type = e.target.type
      const value = e.target.value

//@ts-ignore
      if (type === 'file') setOptionValue({ ...optionValue, photo_option: e.target.files[0] })
      else {
        setOptionValue({...optionValue, [name]: value})
      }
    },
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name
      const type = e.target.type
      const value = e.target.value

      //@ts-ignore
      if (type === 'file') setForm({ ...form, photo: e.target.files[0] })
      else if (type === 'number') {
        if (+value >= 0) setForm({ ...form, [name]: value })
      }
      else if (name.split('[')[1]) {
        setMultiLangTitles({
          ...multiLangTitles, [name]: value
        })
      }
      else setForm({ ...form, [name]: value })
    },
    textareaHandler: (e: ChangeEvent<HTMLTextAreaElement>) => {
      if (e.target.name.split('[')[1]) {
        setMultiLangDescriptions({
          ...multiLangDescriptions, [e.target.name]: e.target.value
        })
      } else setForm({ ...form, [e.target.name]: e.target.value })
    },
    buttonOptionHandler: () => {
      setOptions([...options, optionValue])
      setOptionValue({
        option: '',
        photo_option: '',
        price_option: '0',
        additions: '',
      })
    },
    setHandler: (arr: string[]) => {
      setSimilar(arr)
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setForm({ ...form, [e.target.name]: e.target.value })
    },
    setCategory: (category: string) => {
      setForm({ ...form, category })
    },
    setRestaurant: (restaurant: string) => {
      setForm({ ...form, restaurant })
    },
    saveHandler: () => {
      let isRight: boolean = false

      for (let key in isValid) {
        //@ts-ignore
        const value = isValid[key]
        if (value) isRight = true
        else {
          isRight = false
          break
        }
      }

      if (isRight) Callbacks.Save()
    }
  }

  const Callbacks = {
    Save: async () => {
      try {
        const data = new FormData()
       // let err = false;
       // let responceFileUploads: any = [];
        if (options.length > 0) {
          const dataOptions = new FormData()

         /* for (let i = 0; i < options.length; i++) {
            if (options[i].photo_option) {
              console.log(options[i].photo_option)
              err = true;
              dataOptions.append('photo_options', options[i].photo_option)
            } else {
             // err = false;
              alert('Опция не добавлена, загрузите картинку!');
            }
          } console.log(err) */

        //  if (err) {
            /*const responceFileUploads = await request(`${config.API}/products/uploadFiles`, 'POST', dataOptions, {
              Authorization: (token as string),
            }); */

            for (let i = 0; i < options.length; i++) {
             // options[i].photo_option = responceFileUploads[i];
              if (options[i].price_option) data.append('options[]', (JSON.stringify(options[i]) as any));
            }
          //  console.log(responceFileUploads)
          }

         // console.log(responceFileUploads)
      //  }

        if (multiLang) {
          for (let key in multiLangTitles) {
            data.append(key, multiLangTitles[(key as TPossibleMultiLangTitle)])
          }
        } else data.append('name', form.name)

        if (multiLang) {
          for (let key in multiLangDescriptions) {
            data.append(key, multiLangDescriptions[(key as TPossibleMultiLangDescriptions)])
          }
        } else data.append('description', form.description)

        if (form.photo) data.append('photo', form.photo)

        console.log(form)

        data.append('price', (form.price as string))
        // data.append('stock', (form.stock as string)) //!need to fix from backend
        data.append('hidden', (form.hidden as string))
        data.append('category', (form.category as string))
        if (form.restaurant) data.append('restaurant', (form.restaurant as string))
        if (form.parent) data.append('parent', (form.parent as string))
        data.append('calories', (form.calories as string))
        data.append('fats', (form.fats as string))
        // data.append('options[]', (options as any))

        data.append('carbohydrates', (form.carbohydrates as string))
        data.append('proteins', (form.proteins as string))
        data.append('weight', (form.weight as string))

      //  console.log(options[0])

        const response: IProduct = await request(`${config.API}/products`, 'POST', data, {
          Authorization: (token as string),
        })

        if ((modules.products as any).similar) {
         const response_similar: boolean = await Callbacks.SetSimilar(response._id!)

          response_similar && history.push('/products')
        } else history.push('/products')
      } catch (e) {
        console.log(e)
      }
    },
    SetSimilar: async (productID: string): Promise<boolean> => {
      const response: string[] = await request(`${config.API}/products/similar/${productID}`,
        'POST',
        {
          products: similar
        },
        {
          Authorization: (token as string)
        }
      )
      return !!response
    }
  }

  useEffect(() => {
    const prevIsValid = { ...isValid }

    if (multiLang) {
      let isRightTitles = true

      for (let key in multiLangTitles) {
        if (multiLangTitles[(key as TPossibleMultiLangTitle)].length >= 2 && isRightTitles) {
          isRightTitles = true
          prevIsValid.name = true
        } else {
          isRightTitles = false
          prevIsValid.name = false
        }
      }

      let isRightDesc = true

      for (let key in multiLangDescriptions) {
        if (multiLangDescriptions[(key as TPossibleMultiLangDescriptions)].length >= 2 && isRightDesc) {
          isRightDesc = true
          prevIsValid.description = true
        } else {
          isRightDesc = false
          prevIsValid.description = false
        }
      }

      toggleValid(prevIsValid)
    } else {
      if (form.name.length >= 2) prevIsValid.name = true
      else prevIsValid.name = false

      if (form.description.length >= 2) prevIsValid.description = true
      else prevIsValid.description = false

      toggleValid(prevIsValid)
    }
  }, [form.name, form.description, multiLangTitles, multiLangDescriptions])

  useEffect(() => {
    if (form.photo) toggleValid({ ...isValid, photo: true })
    else toggleValid({ ...isValid, photo: false })
  }, [form.photo])

  useEffect(() => {
    if (form.category) toggleValid({ ...isValid, category: true })
    else toggleValid({ ...isValid, category: false })
  }, [form.category])

 /* useEffect(() => {
    if (form.calories) toggleValid({ ...isValid, calories: true })
    else toggleValid({ ...isValid, calories: false })
  }, [form.calories])

  useEffect(() => {
    if (form.fats) toggleValid({ ...isValid, fats: true })
    else toggleValid({ ...isValid, fats: false })
  }, [form.fats])

  useEffect(() => {
    if (form.carbohydrates) toggleValid({ ...isValid, carbohydrates: true })
    else toggleValid({ ...isValid, carbohydrates: false })
  }, [form.carbohydrates])

  useEffect(() => {
    if (form.proteins) toggleValid({ ...isValid, proteins: true })
    else toggleValid({ ...isValid, proteins: false })
  }, [form.proteins])

  useEffect(() => {
    if (form.weight) toggleValid({ ...isValid, weight: true })
    else toggleValid({ ...isValid, weight: false })
  }, [form.weight]) */


  return (
    <Page.Wrapper
      title={Translater.ProductsCreatePage.title[language.slug]}
    >
      <Page.Header
        backButtonTitle={Translater.ProductsCreatePage.title[language.slug]}
      />

      <Buttons.Container
        disabled={loading}
        createHandler={access.products?.rule === 'change' ? Events.saveHandler : false}
      />

      <Components.ConfigBlock
        form={form}
        optionValue={optionValue}
        options={options}
        isValid={isValid}
        multiLangTitles={multiLangTitles}
        multiLangDescriptions={multiLangDescriptions}
        setCategory={Events.setCategory}
        setRestaurant={Events.setRestaurant}
        inputHandler={Events.inputHandler}
        inputOptionHandler={Events.inputOptionHandler}
        buttonOptionHandler={Events.buttonOptionHandler}
        selectHandler={Events.selectHandler}
        textareaHandler={Events.textareaHandler}
      />

      {
        access.products?.children?.similar?.rule
          && access.products?.children?.similar?.rule === 'change'
          && (modules.products as any).similar
          ? (
            <Components.SimilarBlock
              setHandler={Events.setHandler}
            />
          ) : null}

    </Page.Wrapper>
  )
}

export default CreatePage
