import React,
{
  useState,
  useEffect,
  useContext,
  ChangeEvent
} from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Components from './components'
import * as Buttons from '../../components/Buttons'

import { useHttp } from '../../hooks/http.hook'
import { IStock } from '../../types/items'
import { config, Translater } from '../../config'
import { useParams, useHistory } from 'react-router-dom'
import { TMutliLangTitles, TPossibleMultiLangTitle } from '../../types/multiLang'

const DetailPage: React.FC = () => {

  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { multiLang } = useContext(Contexts.ConfigContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { id } = useParams() as any
  const history = useHistory()
  const { loading, request } = useHttp()

  const [stock, setStock] = useState<IStock | null>(null)
  const [isValid, toggleValid] = useState({
    name: false,
    photo: true,
    productsList: true,
    dateStart: false,
    dateEnd: false,
  })

  const [form, setForm] = useState<IStock>({
    name: '',
    photo: '',
    percent: 0,
    description: '',
    status: false,
    dateStart: 0,
    dateEnd: 0,
    timeEnd: 0,
    productsList: '',
    categoriesList: '',
  })

  const [multiLangValues, setMultiLangValues] = useState<TMutliLangTitles>({
    "title[EN]": '',
    "title[RU]": '',
    "title[UA]": ''
  })

  const Events = {
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      console.log('e.target.value', e.target.value)
      //@ts-ignore
      if (e.target.type === 'file') setForm({ ...form, photo: e.target.files[0] })
      else if (e.target.name.split('[')[1]) {
        setMultiLangValues({
          ...multiLangValues, [e.target.name]: e.target.value
        })
      }
      else setForm({ ...form, [e.target.name]: e.target.value })
    },

    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {

      setForm({ ...form, [e.target.name]: e.target.value })
    },
    saveHandler: () => {
      if (isValid.name && isValid.photo) Callbacks.Save()
    },
    deleteHandler: () => {
      const answer = window.confirm(Translater.Alert.delete[language.slug])
      if (answer) Callbacks.Delete()
    },
  }

  const Callbacks = {

    Fetch: async () => {
      try {
        const response: IStock = await request(`${config.API}/stocks/${id}`,
          'GET', null, {
          Authorization: (token as string)
        })

        if (response) {
          setStock(response)
        }
      } catch (e) {
        console.log(e)
      }
    },
    Save: async () => {
      try {
        const data = new FormData()

        if (multiLang) {
          for (let key in multiLangValues) {
            data.append(key, multiLangValues[(key as TPossibleMultiLangTitle)])
          }
        } else data.append('name', form.name)

        data.append('photo', form.photo)
        // data.append('name', form.name)
        data.append('description', form.description)
        data.append('percent', form.percent as string)
        data.append('dateStart', form.dateStart as string)
        data.append('dateEnd', form.dateEnd as string)
        data.append('productsList', form.productsList as any)
        data.append('categoriesList', form.categoriesList as any)

        console.log(form)

        const response = await request(`${config.API}/stocks/update/${id}`,
          //@ts-ignore
          'PUT', data, {
          Authorization: (token as string),
        })

        if (response) history.push('/stocks')
      } catch (e) {
        console.log(e)
      }
    },

    Delete: async () => {
      try {
        await request(`${config.API}/stocks/${id}`, 'DELETE', null, {
          Authorization: (token as string),
        })

        history.push('/stocks')
      } catch (e) {
        console.log(e)
      }
    }
  }


  useEffect(() => {
    Callbacks.Fetch()
  }, [id])


  useEffect(() => {
    if (stock) setForm({
      ...form,
      name: stock.name,
      photo: stock.photo,
      percent: stock.percent,
      description: stock.description,

      dateStart: `${new Date(stock.dateStart).getFullYear().toString()}-${new Date(stock.dateStart).getMonth().toString().length === 2 ? new Date(stock.dateStart).getMonth() + 1 : '0' + (+new Date(stock.dateStart).getMonth() + 1)}-${new Date(stock.dateStart).getDate().toString().length === 2 ? new Date(stock.dateStart).getDate() : '0' + new Date(stock.dateStart).getDate()}`,

      dateEnd: `${new Date(stock.dateEnd).getFullYear().toString()}-${new Date(stock.dateEnd).getMonth().toString().length === 2 ? new Date(stock.dateEnd).getMonth() + 1 : '0' + (+new Date(stock.dateEnd).getMonth() + 1)}-${new Date(stock.dateEnd).getDate().toString().length === 2 ? new Date(stock.dateEnd).getDate() : '0' + new Date(stock.dateEnd).getDate()}`,
      productsList: stock.productsList,
      categoriesList: stock.categoriesList,

    })
  }, [stock])

  useEffect(() => {
    if (multiLang) {
      let isRight = true

      for (let key in multiLangValues) {
        if (multiLangValues[(key as TPossibleMultiLangTitle)].length >= 2 && isRight) {
          toggleValid({ ...isValid, name: true })
          isRight = true
        } else {
          isRight = false
          toggleValid({ ...isValid, name: false })
        }
      }
    } else {
      if (form.name.length >= 2) toggleValid({ ...isValid, name: true })
      else toggleValid({ ...isValid, name: false })
    }
  }, [form.name, multiLangValues])

  if (loading || !stock) return <Page.Preloader />

  return (
    <Page.Wrapper
      title={stock.name}
    >

      <Page.Header
        backButtonTitle={Translater.StocksDetailPage.title[language.slug]}
      />

      <Buttons.Container
        disabled={loading}
        saveHandler={access.stocks?.rule === 'change' ? Events.saveHandler : false}
        deleteHandler={access.stocks?.rule === 'change' ? Events.deleteHandler : false}
      />

      <Components.ConfigBlock
        data={stock}
        form={form}
        //@ts-ignore
        isValid={isValid}
        multiLangValues={multiLangValues}
        inputHandler={Events.inputHandler}
        selectHandler={Events.selectHandler}
      />
      <Components.DetailBlock
        data={stock}
      />

    </Page.Wrapper>
  )
}

export default DetailPage
