import React, {
    useState,
    useEffect,
    useContext, ChangeEvent,
} from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
// import * as Components from './components'

import { useHttp } from '../../hooks'

import {
    config,
    Translater
} from '../../config'

import {
    ICategory,
} from '../../types/items'
import * as Form from "../../components/Form";


const IndexPage: React.FC = () => {

    const { token } = useContext(Contexts.AuthContext)
    const { access } = useContext(Contexts.UserContext)
    const { language } = useContext(Contexts.LanguageContext)

    const { loading, request } = useHttp()

    const [primary, setPrimary] = useState<any>([])
   // const [editorContent, setEditorContent] = useState<any>();
    const [form, setForm] = useState<any>({
        title: '',
        body: ''
    });

    const Events = {
        inputHandler: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
            const name = e.target.name
            const value = e.target.value

            setForm({...form, [name]: value})
        },
        reloadHandler: () => {
            Callbacks.Fetch()
        },
   /*     handleEditorChange: (content: any, editor: any) => {
            setEditorContent(content)
            console.log('Content was updated:', content);
        }, */
        buttonHandler: () => {
            Callbacks.SaveData();
            setPrimary([...primary, form])
            //  console.log(form)
        }
    }

    const Callbacks = {
        Fetch: async () => {
            try {
                const response: any = await request(`${config.API}/notifications`,
                    'GET', null, {
                        Authorization: (token as string)
                    })

                if (response) {
                    setPrimary(response)
                }
            } catch (e) {
                console.log(e)
            }
        },
        SaveData: async () => {
            try {
                await request(`${config.API}/users/sendNotifications`, 'POST', form, {
                    Authorization: (token as string)
                });

              //  await window.location.reload(true)
            } catch (e) {
                console.log(e)
            }
        }
    }

    useEffect(() => {
        Callbacks.Fetch()
    }, [])


    if (loading || !primary) return <Page.Preloader />

    return (
        <Page.Wrapper
            title={Translater.NotificationsPage.title[language.slug]}
        >
            <Page.Header
                title={Translater.NotificationsPage.title[language.slug]}
                // enableButton={access.categories?.rule === 'change'}
                btnTitle={Translater.Buttons.send[language.slug]}
                buttonHandler={Events.buttonHandler}
                // linkTo='/categories/create'
            />

            <Form.LabelField
                fontSize='small'
                label={Translater.TableTitles.slug[language.slug]}
                text={form.percents}
            >
                {(
                    <Form.Input
                        name='title'
                        placeholder={Translater.Placeholders.minTwo[language.slug]}
                        //  isValid={isValid.title}
                        value={form.title}
                        inputHandler={Events.inputHandler}
                    />
                )}
            </Form.LabelField>

            <Form.LabelField
                fontSize='small'
                label={Translater.TableTitles.message[language.slug]}
                text={form.message}
            >
                    {(
                        <Form.Textarea
                            name='body'
                            placeholder={Translater.Placeholders.minTwo[language.slug]}
                           // isValid={isValid.description}
                            value={form.body}
                            textareaHandler={Events.inputHandler}
                        />
                    )}
            </Form.LabelField>

            {
                primary.map((item: any) => {
                    return <div key={item._id} className='page-notification-list'> <div className='page-notification-title'>{item.title}</div> <div className='page-notification-body'>{item.body}</div> </div>
                })
            }
        </Page.Wrapper>
    )
}

export default IndexPage
