import React, { createContext, useEffect, useState } from 'react'

import {
  EVars,
  IUserData,
  IAuthContext,
  IAuthContextActions,
} from '../types/contexts/auth'


const AuthContext = createContext<IAuthContext>({
  token: null,
  userID: null,
  isReady: false,
  isAuthenticated: false,
  actions: {
    GET_USER: async () => { },
    LOGIN: async () => { },
    LOGOUT: async () => { }
  }
})

export default AuthContext


export const AuthContextProvider: React.FC = ({ children }) => {

  const [token, setToken] = useState<string | null>(null)
  const [userID, setUserID] = useState<string | null>(null)
  const [isReady, setReady] = useState<boolean>(false)
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false)

  const actions: IAuthContextActions = {
    LOGIN: (token: string, userID: string) => {

      setToken(token)
      setUserID(userID)
      setAuthenticated(true)

      localStorage.setItem(EVars.USER_DATA, JSON.stringify({ token, userID }))

      setReady(true)
    },
    LOGOUT: () => {
      setAuthenticated(false)
      localStorage.removeItem(EVars.USER_DATA)
    },
    GET_USER: () => {
      setReady(false)

      const response: string | null = localStorage.getItem(EVars.USER_DATA)

      if (response) {
        const data: IUserData = JSON.parse(response)

        if (data.token && data.userID) actions.LOGIN(data.token, data.userID)
      } else {
        console.log('There is not user')
        setReady(true)
      }
    }
  }

  useEffect(() => {
    actions.GET_USER()
  }, [])

  return (
    <AuthContext.Provider value={{
      token, userID, isReady, isAuthenticated, actions
    }}>
      {children}
    </AuthContext.Provider>
  )
}