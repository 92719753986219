import React, {Fragment, useContext, useState} from 'react'

import * as Contexts from '../../../../contexts'
import * as Table from '../../../../components/Table'

import { useHistory } from 'react-router-dom'
import { useHttp } from '../../../../hooks/http.hook'
import { config, Translater } from '../../../../config'
import { ITrackProps } from './types'
import { ICategory } from '../../../../types/items'
import * as Components from "../index";


const Track: React.FC<ITrackProps> = ({
  data,
  order,
  reloadHandler
}) => {

  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)

  const [showChildren, toggleChildren] = useState<boolean>(false)
  const [children, setChildren] = useState<ICategory[]>([])
  const { loading, request } = useHttp()
  const history = useHistory()

  const Events = {
    childrenHandler: () => {
      if (!loading) toggleChildren(!showChildren)
    },
    deleteHandler: () => {
      if (access.orders?.children?.deleting?.rule === 'yes') {
        const answer = window.confirm(Translater.Alert.delete[language.slug])
        if (answer) Callbacks.Delete()
      }
    },
    editHandler: () => {
      if (access.orders?.rule !== 'false') {
        history.push(`/products/${data._id}`)
      }
    }
  }

  const Callbacks = {
    Delete: async () => {
      try {
        await request(`${config.API}/products/${data._id}`, 'DELETE', null, {
          Authorization: (token as string),
        })

        reloadHandler()
      } catch (e) {
        console.log(e)
      }
    },
  }

  return (
<Fragment>
    <Table.Track
      type='products'
      clickHandler={Events.childrenHandler}
    >
      {/* <Table.Cell
        text={`${order}`}
      /> */}
      <Table.Cell
          type='custom'
          style={{
          //  paddingLeft: (index + 1) * 20
          }}
      >
        {data.children.length > 0 ? (
            <img
                alt='Nesting'
                className={`nesting-arrow ${showChildren && 'active'}`}
                src={require('../../../../assets/icons/arrow-bottom.png')}
            />
        ) : (
            <img
                alt='Dow'
                className='nesting-dot'
                src={require('../../../../assets/icons/dot.png')}
            />
        )}
      </Table.Cell>

      <Table.Cell
        type='image'
        imageURL={`${config.serverURL}${data.photo}`}
      />

      <Table.Cell
        text={data.name}
      />

      <Table.Cell
        linkText={(data.category as ICategory).title}
        linkTo={`/categories/${(data.category as ICategory)._id}`}
      />

      <Table.Cell
          type={'list'}
          text={'Кал:' + data.calories + '+Жир:' + data.fats + '+Вуглев:' + data.carbohydrates + '+Білків:' + data.proteins}
      />

      <Table.Cell
        text={data.hidden ? (
          Translater.TableTitles.hidden.true[language.slug]
        ) : Translater.TableTitles.hidden.false[language.slug]}
      />

      <Table.Cell
        text={`${data.price} грн`}
      />

      <Table.Cell
        text={new Date((data.updated as string)).toLocaleDateString()}
      />

      <Table.Cell
        text={new Date((data.created as string)).toLocaleDateString()}
      />

      <Table.Cell
        type='control'
        editButton={access.products?.rule !== 'false'}
        deleteButton={access.products?.rule === 'change'}
        editHandler={Events.editHandler}
        deleteHandler={Events.deleteHandler}
      />
    </Table.Track>
  {
    data.children[0] && showChildren ? data.children.map((item: any, index: number) => (
        <Components.Track
            key={item._id}
            order={index + 1}
            data={item}
            reloadHandler={reloadHandler}
        />
    )) : null
  }
</Fragment>
  )
}

export default Track
