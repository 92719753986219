import React, {
    Fragment,
    useState,
    useEffect,
    useContext,
} from 'react'

import * as Contexts from '../../../contexts'
import * as Buttons from '../../../components/Buttons'
import * as Form from '../../../components/Form'

import { config, Translater } from '../../../config'
import { useHttp } from '../../../hooks'

import {IStaff, IRole, ICategory, ICity, IRestaurant} from '../../../types/items'
import { IConfigBlockProps } from './types'

import AsyncSelect from 'react-select/async'

const ConfigBlock: React.FC<IConfigBlockProps> = ({
 data,
 form,
 isValid,
 // isCreate = false,
 inputHandler,
 selectHandler,
 }) => {

    const { token } = useContext(Contexts.AuthContext)
    const { access } = useContext(Contexts.UserContext)
    const { language } = useContext(Contexts.LanguageContext)

    const { request } = useHttp()

    const [inputValue, setInputValue] = useState<string>('')

    const Events = {
        inputHandler: (value: string) => {
            setInputValue(value)
        },
    }


    return (
        <Fragment>
            <Form.LabelField
                fontSize='small'
                label={Translater.TableTitles.sum[language.slug]}
                text={form.title}
            >
                    <Form.Input
                        name='sum'
                        type={'number'}
                        value={form.sum}
                        isValid={isValid.sum}
                      //  placeholder={Translater.Placeholders.minThree[language.slug]}
                        inputHandler={inputHandler}
                    />
            </Form.LabelField>

        </Fragment>
    )
}

export default ConfigBlock
