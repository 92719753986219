import React,
{ useState, useContext, useEffect } from 'react'
import {
  ICategory,
  IProduct
} from '../../types/items'

import { useHttp } from '../../hooks/http.hook'
import * as Contexts from '../../contexts'

import {
  config,
  Translater
} from '../../config'

import AsyncSelect from 'react-select/async'



const ListCategories: React.FC<{
  categoriesList: string,
  selectHandler: (e: any) => void
}> = ({ selectHandler, categoriesList }) => {
  const { token } = useContext(Contexts.AuthContext)
  const { loading, totalCount, request } = useHttp()

  const [primary, setPrimary] = useState<ICategory[]>([])

  const Callbacks = {
    Fetch: async () => {
      try {
        const response: ICategory[] = await request(`${config.API}/categories`,
          'GET', null, {
          Authorization: (token as string)
        })

        if (response) {
          console.log('response LIST', response)
          setPrimary(response)
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [])


  return (
    <select name='categoriesList' value={categoriesList} onChange={selectHandler} >
      <option value="" disabled>Виберіть категорію</option>
      {primary.map(({ _id, title }) => {
        return <option key={_id} value={_id}>{title}</option>
      })}
    </select>
  )
}

export default ListCategories
