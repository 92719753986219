import React, {
    useState,
    useEffect,
    useContext, ChangeEvent,
} from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
// import * as Components from './components'

import { useHttp } from '../../hooks'

import {
    config,
    Translater
} from '../../config'

import {
    ICategory,
} from '../../types/items'
import * as Form from "../../components/Form";
import * as Components from "./components";


const IndexPage: React.FC = () => {

    const { token } = useContext(Contexts.AuthContext)
    const { access } = useContext(Contexts.UserContext)
    const { language } = useContext(Contexts.LanguageContext)

    const { loading, request } = useHttp()

    const [primary, setPrimary] = useState<any>([])

    const Events = {
        reloadHandler: () => {
            Callbacks.Fetch()
        },
        /*     handleEditorChange: (content: any, editor: any) => {
                 setEditorContent(content)
                 console.log('Content was updated:', content);
             },
        buttonHandler: () => {
            Callbacks.SaveData();
            setPrimary([...primary, form])
            //  console.log(form)
        }*/
    }

    const Callbacks = {
        Fetch: async () => {
            try {
                const response: any = await request(`${config.API}/booking`,
                    'GET', null, {
                        Authorization: (token as string)
                    })

                if (response) {
                    setPrimary(response)
                }
            } catch (e) {
                console.log(e)
            }
        },
    }

    useEffect(() => {
        Callbacks.Fetch()
    }, [])


    if (loading || !primary) return <Page.Preloader />

    return (
        <Page.Wrapper
            title={Translater.BookingPage.title[language.slug]}
        >
            <Page.Header
                title={Translater.BookingPage.title[language.slug]}
                enableButton={primary.length <= 0}
                linkTitle={Translater.Buttons.create[language.slug]}
                linkTo='/booking/create'
            />

            <Components.Table
                items={primary}
                reloadHandler={Events.reloadHandler}
            />

        </Page.Wrapper>
    )
}

export default IndexPage
