import React, {
    useState,
    useEffect,
    useContext,
    ChangeEvent,
} from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Form from '../../components/Form'
import * as Components from './components'
import * as Buttons from '../../components/Buttons'

import { useHttp } from '../../hooks/http.hook'
import { IRestaurant, ICity } from '../../types/items'
import { config, Translater } from '../../config'
import { useParams, useHistory } from 'react-router-dom'
import {TPossibleMultiLangDescriptions, TPossibleMultiLangTitle} from "../../types/multiLang";


const DetailPage: React.FC = () => {

    const { token } = useContext(Contexts.AuthContext)
    const { access } = useContext(Contexts.UserContext)
    const { language } = useContext(Contexts.LanguageContext)

    const history = useHistory()
    const { id } = useParams() as any
    const { loading, request } = useHttp()

    const [primary, setPrimary] = useState<IRestaurant | null>(null)
    const [isValid, toggleValid] = useState({
        title: false,
        city: false,
    })

    const [form, setForm] = useState<IRestaurant>({
        title: '',
        city: '',
    })

    const Events = {
        inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
            const name = e.target.name
            const value = e.target.value
            setForm({ ...form, [name]: value })
        },
        selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
            setForm({ ...form, [e.target.name]: e.target.value })
        },
        setCity: (city: string) => {
            setForm({ ...form, city})
        },
       /* setRole: (role: string) => {
            setForm({ ...form, role })
        }, */
        saveHandler: () => {
            let isRight: boolean = false

            for (let key in isValid) {
                //@ts-ignore
                const value = isValid[key]
                if (value) isRight = true
                else {
                    isRight = false
                    break
                }
            }

            if (isRight) Callbacks.Save()
        },
        deleteHandler: () => {
            const answer = window.confirm(Translater.Alert.delete[language.slug])
            if (answer) Callbacks.Delete()
        },
    }

    const Callbacks = {
        Fetch: async () => {
            try {
                const response: IRestaurant = await request(`${config.API}/restaurants/${id}`,
                    'GET', null, {
                        Authorization: (token as string)
                    })

                if (response) {
                    setPrimary(response)
                }
            } catch (e) {
                console.log(e)
            }
        },
        Save: async () => {
            try {
                const response = await request(`${config.API}/restaurants/${primary?._id}`, 'POST', { ...form }, {
                    Authorization: (token as string),
                })

                if (response) history.push('/restaurants')
            } catch (e) {
                console.log(e)
            }
        },
        Delete: async () => {
            try {
                await request(`${config.API}/restaurants/${id}`, 'DELETE', null, {
                    Authorization: (token as string),
                })

                history.push('/restaurants')
            } catch (e) {
                console.log(e)
            }
        },
    }

    useEffect(() => {
        Callbacks.Fetch()
    }, [id])

    useEffect(() => {
        if (primary) setForm({
            ...form,
            title: primary.title,
            city: (primary.city as ICity)?._id!,
        })
    }, [primary])

    useEffect(() => {
        const prevIsValid = { ...isValid }

            if (form.title.length >= 2) prevIsValid.title = true
            else prevIsValid.title = false

            if (form.city) prevIsValid.city = true
            else prevIsValid.city = false

            toggleValid(prevIsValid)
    }, [form.title/*, form.city*/])


    if (loading || !primary) return <Page.Preloader />

    return (
        <Page.Wrapper
            title={primary?.title}
        >
        <Page.Header
            backButtonTitle={Translater.RestaurantsDetailPage.title[language.slug]}
    />

    <Buttons.Container
    disabled={loading}
    saveHandler={access.restaurants?.rule === 'change' ? Events.saveHandler : false}
    deleteHandler={access.restaurants?.rule === 'change' ? Events.deleteHandler : false}
    />

    <Components.ConfigBlock
    form={form}
    data={primary}
    isValid={isValid}
    setCity={Events.setCity}
    inputHandler={Events.inputHandler}
    selectHandler={Events.selectHandler}
    />

    <Form.DateBlock
    updated={(primary.updated as string)}
    created={(primary.created as string)}
    />

    </Page.Wrapper>
)
}

export default DetailPage
