import React, { useState, useEffect } from "react";

import { IPaginationProps } from "./types";
import { Link } from "react-router-dom";

const Pagination: React.FC<IPaginationProps> = ({
  linkTo,
  pagination,
  paginateHandler,
}) => {
  const [links, setLinks] = useState<any[]>([]);

  useEffect(() => {
    const count = Math.ceil(pagination.totalCount / pagination.perPage);
    let initialLinks = [];

    for (let i = 1; i <= count; i++) {
      initialLinks.push(i);
    }

    setLinks(initialLinks);
  }, [pagination.perPage, pagination.totalCount]);

  return (
    <div className="pagination-wrapper" style={{ width: "100vw" }}>
      {links.map((link) => (
        <Link
          key={link}
          className={`pagination-link ${link === pagination.page && "active"}`}
          to={linkTo}
          onClick={() => paginateHandler(link)}
        >
          {link}
        </Link>
      ))}
    </div>
  );
};

export default Pagination;
