import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  Fragment,
} from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Form from '../../components/Form'
import * as Components from './components'
import * as Buttons from '../../components/Buttons'
import * as Feedback from '../../components/Feedback'

import { useHttp } from '../../hooks/http.hook'
import {IProduct, ICategory, IOptions, IRestaurant} from '../../types/items'
import { config, Translater } from '../../config'
import { useParams, useHistory } from 'react-router-dom'
import {
  TMutliLangTitles,
  TMutliLangDescriptions,
  TPossibleMultiLangTitle,
  TPossibleMultiLangDescriptions
} from '../../types/multiLang'
import Item from "./components/Similar/Item";


const DetailPage: React.FC = () => {

  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { multiLang, modules } = useContext(Contexts.ConfigContext)
  const { language } = useContext(Contexts.LanguageContext)

  const history = useHistory()
  const { id } = useParams() as any
  const { loading, request } = useHttp()

  const [isJustLoaded, toggleIsJustLoaded] = useState<boolean>(true)
  const [primary, setPrimary] = useState<IProduct | null>(null)
  const [isValid, toggleValid] = useState({
    name: false,
    photo: true,
    category: true,
    description: false,
   /* calories: true,
    fats: true,
    carbohydrates: true,
    proteins: true,
    weight: true */
  })

  const [form, setForm] = useState<IProduct>({
    name: '',
    price: '',
    photo: '',
    parent: '',
    // stock: '',
    hidden: '',
    category: '',
    restaurant: '',
    description: '',
    options: [],
    calories: '',
    fats: '',
    carbohydrates: '',
    proteins: '',
    weight: '',
  })
  const [optionValue, setOptionValue] = useState<any>({
    option: '',
    photo_option: '',
    price_option: '0',
    additions: '',
  })

  const [options, setOptions] = useState<IOptions[]>([])

  const [optionsDelete, setOptionsDelete] = useState<any[]>([])

  const [similar, setSimilar] = useState<string[]>([])

  const [multiLangTitles, setMultiLangTitles] = useState<TMutliLangTitles>({
    "title[EN]": '',
    "title[RU]": '',
    "title[UA]": ''
  })

  const [multiLangDescriptions, setMultiLangDescriptions] = useState<TMutliLangDescriptions>({
    "description[EN]": '',
    "description[RU]": '',
    "description[UA]": ''
  })

  const Events = {
    inputOptionHandler: (e: ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name
      const type = e.target.type
      const value = e.target.value

      //@ts-ignore
      if (type === 'file') setOptionValue({ ...optionValue, photo_option: e.target.files[0] })
      else {
        setOptionValue({...optionValue, [name]: value})
      }
    },
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name
      const type = e.target.type
      const value = e.target.value
      //@ts-ignore
      if (type === 'file') setForm({ ...form, photo: e.target.files[0] })
      else if (type === 'number') {
        if (+value >= 0) setForm({ ...form, [name]: value })
      }
      else if (name.split('[')[1]) {
        setMultiLangTitles({
          ...multiLangTitles, [name]: value
        })
      }
      else setForm({ ...form, [name]: value })
    },
    textareaHandler: (e: ChangeEvent<HTMLTextAreaElement>) => {
      if (e.target.name.split('[')[1]) {
        setMultiLangDescriptions({
          ...multiLangDescriptions, [e.target.name]: e.target.value
        })
      } else setForm({ ...form, [e.target.name]: e.target.value })
    },
    buttonOptionHandler: () => {
      setOptions([...options, optionValue])
      setOptionValue({
        option: '',
        photo_option: '',
        price_option: '0',
        additions: '',
      })
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setForm({ ...form, [e.target.name]: e.target.value })
    },
    setHandler: (arr: string[]) => {
      setSimilar(arr)
    },
    removeHandler: (id: string) => {
      let similar: IProduct[] = [...(primary?.similar as IProduct[])]
      let options: IOptions[] = [...(primary?.options as IOptions[])]

      similar = similar.filter(item => item._id !== id)
      options = options.filter(item => item._id !== id)

      setOptionsDelete([...optionsDelete, id]);

      //@ts-ignore
      setPrimary({ ...primary, similar, options })
    },
    setCategory: (category: string) => {
      setForm({ ...form, category })
    },
    setRestaurant: (restaurant: string) => {
      setForm({ ...form, restaurant })
    },
    saveHandler: () => {
      let isRight: boolean = false

      for (let key in isValid) {
        //@ts-ignore
        const value = isValid[key]
        if (value) isRight = true
        else {
          isRight = false
          break
        }
      }

      if (isRight) Callbacks.Save()
    },
    deleteHandler: () => {
      const answer = window.confirm(Translater.Alert.delete[language.slug])
      if (answer) Callbacks.Delete()
    },
  }

  const Callbacks = {
    Fetch: async () => {
      try {
        const response: IProduct = await request(`${config.API}/products/${id}/?similar=true`,
          'GET', null, {
          Authorization: (token as string)
        })

        if (response) {
          setPrimary(response)
        }
      } catch (e) {
        console.log(e)
      }
    },
    SetSimilar: async (): Promise<boolean> => {
      const primarySimilar: string[] = []

      for (let i = 0; i < primary!.similar!.length; i++) {
        primarySimilar.push((primary!.similar as IProduct[])[i]._id!)
      }

      const response: string[] = await request(`${config.API}/products/similar/${id}`,
        'POST',
        {
          products: [...primarySimilar, ...similar]
        },
        {
          Authorization: (token as string)
        }
      )
      return !!response
    },
    Save: async () => {
      try {
        const data = new FormData()
       // let err = false;
        // let responceFileUploads: any = [];
        if (options.length > 0) {
          const dataOptions = new FormData()

         /* for (let i = 0; i < options.length; i++) {
            if (options[i].photo_option) {
              err = true;
              dataOptions.append('photo_options', options[i].photo_option)
            } else {
             // err = false;
              alert('Опция не добавлена, загрузите картинку!');
            }
          } */

        //  if (err) {
           /* const responceFileUploads = await request(`${config.API}/products/uploadFiles`, 'POST', dataOptions, {
              Authorization: (token as string),
            }); */

            for (let i = 0; i < options.length; i++) {
             // options[i].photo_option = responceFileUploads[i];
              if (options[i].price_option) data.append('options[]', (JSON.stringify(options[i]) as any));
            }
          }

      //  }

        if (optionsDelete.length > 0) {
          console.log('Here')
          await request(`${config.API}/products/${primary?._id}/deleteOptions`, 'POST', { optionsDelete }, {
            Authorization: (token as string),
          });
        }

        if (multiLang) {
          for (let key in multiLangTitles) {
            data.append(key, multiLangTitles[(key as TPossibleMultiLangTitle)])
          }
        } else data.append('name', form.name)

        if (multiLang) {
          for (let key in multiLangDescriptions) {
            data.append(key, multiLangDescriptions[(key as TPossibleMultiLangDescriptions)])
          }
        } else data.append('description', form.description)

        form.photo && data.append('photo', form.photo)

        data.append('price', (form.price as string))
        // data.append('stock', (form.stock as string)) //!need to fix from backend
        data.append('hidden', (form.hidden as string))
        data.append('category', (form.category as string))
        if (form.restaurant) data.append('restaurant', (form.restaurant as string))
        if (form.parent) data.append('parent', (form.parent as string))
        data.append('calories', (form.calories as string))
        data.append('fats', (form.fats as string))
        data.append('carbohydrates', (form.carbohydrates as string))
        data.append('proteins', (form.proteins as string))
        data.append('weight', (form.weight as string))

        await request(`${config.API}/products/${primary?._id}`, 'POST', data, {
          Authorization: (token as string),
        })

        if ((modules.products as any).similar) {
          const response_similar: boolean = await Callbacks.SetSimilar()

          response_similar && history.push('/products')
        } else history.push('/products')
      } catch (e) {
        console.log(e)
      }
    },
    Delete: async () => {
      try {
        await request(`${config.API}/products/${id}`, 'DELETE', null, {
          Authorization: (token as string),
        })

        history.push('/products')
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [id])

  useEffect(() => {
    if (primary && isJustLoaded) {
      toggleIsJustLoaded(false)
      setForm({
        ...form,
        name: primary.name,
        price: primary.price,
        calories: primary.calories,
        fats: primary.fats,
        carbohydrates: primary.carbohydrates,
        proteins: primary.proteins,
        weight: primary.weight,
        // stock: primary.stock,
        parent: primary.parent,
        options: primary.options,
        hidden: primary.hidden,
        description: primary.description,
        category: (primary.category as ICategory)._id!,
        restaurant: (primary.restaurant as IRestaurant)?._id!
      })
    }
  }, [primary])

  useEffect(() => {
    const prevIsValid = { ...isValid }

    if (multiLang) {
      let isRightTitles = true

      for (let key in multiLangTitles) {
        if (multiLangTitles[(key as TPossibleMultiLangTitle)].length >= 2 && isRightTitles) {
          isRightTitles = true
          prevIsValid.name = true
        } else {
          isRightTitles = false
          prevIsValid.name = false
        }
      }

      let isRightDesc = true

      for (let key in multiLangDescriptions) {
        if (multiLangDescriptions[(key as TPossibleMultiLangDescriptions)].length >= 2 && isRightDesc) {
          isRightDesc = true
          prevIsValid.description = true
        } else {
          isRightDesc = false
          prevIsValid.description = false
        }
      }

      toggleValid(prevIsValid)
    } else {
      if (form.name.length >= 2) prevIsValid.name = true
      else prevIsValid.name = false

      if (form.description.length >= 2) prevIsValid.description = true
      else prevIsValid.description = false

      toggleValid(prevIsValid)
    }
  }, [form.name, form.description, multiLangTitles, multiLangDescriptions])


  if (loading || !primary) return <Page.Preloader />

  return (
    <Page.Wrapper
      footer
      title={primary.name}
    >
      <Page.Header
        backButtonTitle={Translater.ProductsDetailPage.title[language.slug]}
      />

      <Buttons.Container
        disabled={loading}
        saveHandler={access.products?.rule === 'change' ? Events.saveHandler : false}
        deleteHandler={access.products?.rule === 'change' ? Events.deleteHandler : false}
      />

      <Components.ConfigBlock
        showPhoto
        form={form}
        optionValue={optionValue}
        options={options}
        data={primary}
        isValid={isValid}
        multiLangTitles={multiLangTitles}
        multiLangDescriptions={multiLangDescriptions}
        setCategory={Events.setCategory}
        setRestaurant={Events.setRestaurant}
        inputHandler={Events.inputHandler}
        inputOptionHandler={Events.inputOptionHandler}
        buttonOptionHandler={Events.buttonOptionHandler}
        selectHandler={Events.selectHandler}
        textareaHandler={Events.textareaHandler}
      />
      {
        access.products?.children?.similar?.rule
          && access.products?.children?.similar?.rule !== 'false'
          && (modules.products as any).similar
          ? (
            <Components.SimilarBlock
              items={(primary.similar as IProduct[])}
              setHandler={Events.setHandler}
              removeHandler={Events.removeHandler}
            />
          ) : null}

      <Form.DateBlock
        updated={(primary.updated as string)}
        created={(primary.created as string)}
      />

      {(modules.products as any).feedback ? (
        <Fragment>
          {/* <Components.Feedback
            data={primary}
          />

          <Feedback.Block
            link={`/products/${id}`}
            productID={id}
          /> */}

          <Fragment>
            {/* <Form.LabelField
                fontSize='small'
                label={Translater.TableTitles.options[language.slug]}
            >
              <div className='flex'>
                { (primary?.options[0]) ? primary?.options.map((value, key) => <div className='label-text' key={value._id}>{(key < 1) ? value.option : ', ' + value.option}</div>) : <div className='label-text'>Немає</div> }
              </div>
            </Form.LabelField> */}
            <Form.LabelField
                label={Translater.TableTitles.options[language.slug]}
                text={!primary?.options.length ? 'None' : ''}
            >
              {primary?.options.map(item => (
                  <Item
                      key={item._id}
                      data={item}
                      removeHandler={Events.removeHandler}
                  />
              ))}
            </Form.LabelField>

          </Fragment>
          <Buttons.DefaultButton
              title={Translater.Buttons.save[language.slug]}
              backgroundColor='yellow'
              disabled={false}
              buttonHandler={(Events.saveHandler as () => void)}
          />
        </Fragment>
      ) : null}
    </Page.Wrapper>
  )
}

export default DetailPage
