import React, { useContext } from 'react'

import * as Contexts from '../../contexts'

import { Link } from 'react-router-dom'

import { ILinkProps } from './types'


const LinkCustom: React.FC<ILinkProps> = ({
  title,
  linkTo,
  refresh = false,
  hideModal = false
}) => {

  const { actions: modalActions } = useContext(Contexts.ModalContext)

  const onClick = () => {
    if (hideModal) modalActions.HIDE()
    if (refresh) window.location.reload(true)
  }

  return (
    <Link
      className='btn--link'
      to={linkTo}
      onClick={onClick}
    >
      {title}
    </Link>
  )
}

export default LinkCustom