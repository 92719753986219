import React, {
  Fragment,
  useContext,
} from 'react'

import * as Contexts from '../../../../contexts'
import * as Form from '../../../../components/Form'

import { Translater } from '../../../../config'

import { IConfigBlockProps } from './types'


const ConfigBlock: React.FC<IConfigBlockProps> = ({
  form,
  isValid,
  inputHandler
}) => {

  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)

  return (
    <Fragment>
      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.title[language.slug]}
        text={access.users?.rule !== 'change' ? form.name : ''}
      >
        {access.users?.rule === 'change' ? (
          <Form.Input
            name='name'
            placeholder={Translater.Placeholders.minTwo[language.slug]}
            isValid={isValid.name}
            value={form.name}
            inputHandler={inputHandler}
          />
        ) : null}
      </Form.LabelField>
    </Fragment>
  )
}

export default ConfigBlock