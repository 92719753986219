import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  ChangeEvent
} from 'react'

import * as Contexts from '../../../../contexts'
import * as Form from '../../../../components/Form'
import * as Buttons from '../../../../components/Buttons'

import { config, Translater } from '../../../../config'

import { useHttp } from '../../../../hooks/http.hook'

import { SOptions } from '../static'
import { useHistory } from 'react-router-dom'
import { IDetailBlockProps } from './types'


const DetailBlock: React.FC<IDetailBlockProps> = ({
  data,
  reloadHandler
}) => {

  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)
  const { isActive } = useContext(Contexts.ModalContext)

  const { loading, request } = useHttp()
  const history = useHistory()

  const [status, setStatus] = useState<string>('')

  const Events = {
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setStatus(e.target.value)
    },
    saveHandler: () => {
      if (access.orders?.children?.processing?.rule === 'processing') {
        const answer = window.confirm(Translater.Alert.save[language.slug])
        if (answer) Callbacks.Save()
      }
    },
    deleteHandler: () => {
      if (access.orders?.children?.deleting?.rule === 'yes') {
        const answer = window.confirm(Translater.Alert.delete[language.slug])
        if (answer) Callbacks.Delete()
      }
    }
  }

  const Callbacks = {
    Save: async () => {
      try {
        await request(`${config.API}/orders/${data._id}/status`, 'POST', { status }, {
          Authorization: (token as string),
        })

        await window.location.reload(false);
        // await reloadHandler()
      } catch (e) {
        console.log(e)
      }
    },
    Delete: async () => {
      try {
        await request(`${config.API}/orders/${data._id}`, 'DELETE', null, {
          Authorization: (token as string),
        })

        if (!isActive) history.goBack()
        else reloadHandler()
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    setStatus(data.status)
  }, [])


  return (
    <Fragment>
      <Buttons.Container
        disabled={loading}
        saveHandler={access.orders?.children?.processing?.rule === 'processing' ? (
          Events.saveHandler
        ) : false}
        deleteHandler={access.orders?.children?.deleting?.rule === 'yes' ? (
          Events.deleteHandler
        ) : false}
      />

      <div className='block-container'>
        <Form.LabelField
          fontSize='small'
          label={Translater.TableTitles.orderNumber[language.slug]}
          text={`${data.increment}`}
        />

        <Form.LabelField
          fontSize='small'
          label={Translater.TableTitles.status[language.slug]}
          text={access.orders?.children?.processing?.rule !== 'processing'
            || data.status === 'payment-processing' ? (
              Translater.TableTitles.statusTypes[data.status][language.slug]
            ) : ''}
        >
          {(access.orders?.children?.processing?.rule === 'processing'
            && data.status !== 'payment-processing') ? (
              <Form.Select
                name='status'
                options={SOptions}
                value={status}
                selectHandler={Events.selectHandler}
              />
            ) : null}
        </Form.LabelField>

        <Form.LabelField
          fontSize='small'
          label={Translater.TableTitles.comment[language.slug]}
          text={data.comment ? data.comment : (
            Translater.TableTitles.withoutComment[language.slug]
          )}
        />

        {(data.user?.updated) ? <Form.LabelField
          fontSize='small'
          label={Translater.TableTitles.user[language.slug]}
        >
          <Buttons.Link
            hideModal
            title={data?.user?.name}
            linkTo={`/users/${data?.user?._id}`}
          />
          <p className='label-text'>
            {data?.user?.phone}
          </p>
        </Form.LabelField> : null}

        <Form.LabelField
          fontSize='small'
          label={Translater.TableTitles.recipient[language.slug]}
        >
          <p className='label-text'>
            {data.delivery.name}
          </p>
          <p className='label-text'>
            {data.delivery.phone}
          </p>
          <p className='label-text'>
            {data.delivery.address.formatted_address}
          </p>
        </Form.LabelField>

        <Form.LabelField
            fontSize='small'
            label={Translater.TableTitles.call[language.slug]}
        >
          <p className='label-text'>
            {(data?.call) ? 'Так' : 'Ні'}
          </p>
        </Form.LabelField>

        <Form.LabelField
          label={Translater.TableTitles.type[language.slug]}
          text={data.delivery.type === 'courier' ? (
            Translater.TableTitles.courier[language.slug]
          ) : Translater.TableTitles.pickup[language.slug]}
        />

        {(data.paidByBonuses) ? <Form.LabelField
            fontSize='small'
            label={Translater.TableTitles.paidByBonuses[language.slug]}
            text={`${data.paidByBonuses} грн`}
        /> : null}

        {(data.price) ? <Form.LabelField
            fontSize='small'
            label={Translater.TableTitles.price[language.slug]}
            text={`${data.price} грн(${data.paymentType === 'cash' ? 'Наличные' : 'Онлайн'})`}
        /> : null}

        {(data.created) ? <Form.LabelField
          fontSize='small'
          label={Translater.TableTitles.updated[language.slug]}
          text={new Date((data.updated as string)).toLocaleDateString()}
        /> : null}

        {(data.updated) ? <Form.LabelField
          fontSize='small'
          label={Translater.TableTitles.created[language.slug]}
          text={new Date((data.created as string)).toLocaleDateString()}
        /> : null}

      </div>
    </Fragment>
  )
}

export default DetailBlock
