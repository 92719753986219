import React, {
    Fragment,
    useState,
    useEffect,
    useContext,
} from 'react'

import * as Contexts from '../../../contexts'
import * as Buttons from '../../../components/Buttons'
import * as Form from '../../../components/Form'

import { config, Translater } from '../../../config'
import { useHttp } from '../../../hooks'

import {IStaff, IRole, ICategory, ICity, IRestaurant} from '../../../types/items'
import { IConfigBlockProps } from './types'

import AsyncSelect from 'react-select/async'

const ConfigBlock: React.FC<IConfigBlockProps> = ({
 data,
 form,
 isValid,
 isCreate = false,
 setCity,
 inputHandler,
 selectHandler,
 }) => {

    const { token } = useContext(Contexts.AuthContext)
    const { access } = useContext(Contexts.UserContext)
    const { language } = useContext(Contexts.LanguageContext)

    const { request } = useHttp()

    const [inputValue, setInputValue] = useState<string>('')
    const [options, setOptions] = useState<any[]>([])
    const [citySelectValue, setCitySelectValue] = useState<any>({
        label: '',
        value: ''
    })

    const Events = {
        inputHandler: (value: string) => {
            setInputValue(value)
        },
        loadOptions: async (inputValue: string) => {
            try {
                const response: ICity[] = await request(`${config.API}/cities/?name=${inputValue}`, 'GET', null, {
                    Authorization: (token as string)
                })
                const formatted: ICity[] = []
                if (response.length) {
                    for (const item of response) {
                        const option = {
                            value: (item._id as string),
                            label: (item.name as string)
                        }
                        formatted.push((option as any))
                    }
                }
                setOptions(formatted)
                return formatted
            } catch (e) {
                console.log(e)
            }
        },
        optionHandler: (option: any) => {
            setCitySelectValue(option)
            setCity(option.value)
        },
    }

    const Styles = {
        control: () => ({
            display: 'flex',
            border: '1px solid #e6e6e6',
            borderRadius: 5,
            outline: 0
        }),
        input: () => ({
            fontSize: 16
        })
    }

   /* useEffect(() => {
        if (data) setInputValue((data.role as IRole)?.title)
    }, [(data?.role as IRole)?.title]) */
    useEffect(() => {
        setInputValue((data?.city as ICity)?.name)
    }, [(data?.city as ICity)?.name])


    return (
        <Fragment>
            <Form.LabelField
                fontSize='small'
                label={Translater.TableTitles.title[language.slug]}
                text={access.restaurants?.rule !== 'change' ? form.title : ''}
            >
                {access.restaurants?.rule === 'change' ? (
                    <Form.Input
                        name='title'
                        value={form.title}
                        isValid={isValid.title}
                        placeholder={Translater.Placeholders.minThree[language.slug]}
                        inputHandler={inputHandler}
                    />
                ) : null}
            </Form.LabelField>

            { /* <Form.LabelField
                fontSize='small'
                label={Translater.TableTitles.city[language.slug]}
                text={access.restaurants?.rule !== 'change' ? form.city : ''}
            >
                {access.restaurants?.rule === 'change' ? (
                    <Form.Input
                        name='city'
                        value={form.city}
                        isValid={isValid.city}
                        placeholder={Translater.Placeholders.minThree[language.slug]}
                        inputHandler={inputHandler}
                    />
                ) : null}
            </Form.LabelField> */}
            <Form.LabelField
                fontSize='small'
                label={Translater.TableTitles.city[language.slug]}
                text={access.restaurants?.rule !== 'change' && data ? (
                    (data.city as ICity).name ? '' : '-'
                ) : ''}
            >
                <Fragment>

                    {access.restaurants?.rule !== 'change' && data ? (
                        <Buttons.Link
                            title={(data?.city as ICity).name}
                            linkTo={`/cities/${(data?.city as ICity)._id}`}
                        />
                    ) : null}

                    {access.restaurants?.rule === 'change' ? (
                        <AsyncSelect
                            cacheOptions
                            loadingMessage={() => Translater.Placeholders.loadingMessage[language.slug]}
                            noOptionsMessage={() => Translater.Placeholders.noOptionsMessage[language.slug]}
                            inputValue={inputValue}
                            styles={Styles}
                            className={`select ${!isValid.city && 'invalid'}`}
                            value={citySelectValue}
                            defaultOptions={options}
                            onInputChange={Events.inputHandler}
                            onChange={Events.optionHandler}
                            loadOptions={Events.loadOptions}
                        />
                    ) : null}
                </Fragment>
            </Form.LabelField>

        </Fragment>
    )
}

export default ConfigBlock
