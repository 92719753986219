import React, { useContext } from 'react'

import * as Contexts from '../../../../contexts'
import * as Table from '../../../../components/Table'

import { useHistory } from 'react-router-dom'
import { ITrackProps } from './types'


const Track: React.FC<ITrackProps> = ({
 order,
 data
 }) => {

    const { access } = useContext(Contexts.UserContext)

    const history = useHistory()

    const Events = {
        editHandler: () => {
            if (access.users?.rule !== 'false') {
                history.push(`/users/${data._id}`)
            }
        }
    }

console.log(data)
    return (
        <Table.Track
            type='bonuses'
        >
            <Table.Cell
                text={`${order}`}
            />

            <Table.Cell
                text={'' + data.count}
            />

            <Table.Cell
                text={(data.operation === 'Debit') ? 'Зписання' : 'Нарахування'}
            />

            <Table.Cell
                text={new Date((data.createdAt as string)).toLocaleDateString() + ' ' + new Date((data.createdAt as string)).toLocaleTimeString()}
            />

            { /* <Table.Cell
                type='control'
                editButton={access.users?.rule !== 'false'}
                editHandler={Events.editHandler}
            /> */}
        </Table.Track>
    )
}

export default Track
