import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
} from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Buttons from '../../components/Buttons'
import * as Components from './components'

import { config, Translater } from '../../config'
import { useHttp } from '../../hooks/http.hook'
import { useHistory } from 'react-router-dom'
import { IRole } from '../../types/items'
import {
  TMutliLangTitles,
  TPossibleMultiLangTitle
} from '../../types/multiLang'



const CreatePage: React.FC = () => {

  const { token } = useContext(Contexts.AuthContext)
  const { multiLang } = useContext(Contexts.ConfigContext)
  const { language } = useContext(Contexts.LanguageContext)

  const history = useHistory()
  const { loading, request } = useHttp()

  const [isValid, toggleValid] = useState({
    title: false,
  })
  const [form, setForm] = useState<IRole>({
    title: '',
    rules: {
      admin: {
        rule: 'false',
        children: {
          roles: {
            rule: 'false'
          },
          staff: {
            rule: 'false'
          }
        }
      },
      categories: {
        rule: 'false'
      },
      restaurants: {
        rule: 'false'
      },
      orders: {
        rule: 'false',
        children: {
          history: {
            rule: 'false'
          },
          deleting: {
            rule: 'false'
          },
          processing: {
            rule: 'false'
          }
        }
      },
      products: {
        rule: 'false',
        children: {
          similar: {
            rule: 'false'
          },
          feedback: {
            rule: 'false'
          }
        }
      },
      users: {
        rule: 'false'
      },
      referals: {
        rule: 'false'
      },
      booking: {
        rule: 'false'
      }
    }
  })
  const [multiLangTitles, setMultiLangTitles] = useState<TMutliLangTitles>({
    "title[EN]": '',
    "title[RU]": '',
    "title[UA]": ''
  })

  const Events = {
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.name.split('[')[1]) {
        setMultiLangTitles({
          ...multiLangTitles, [e.target.name]: e.target.value
        })
      }
      else setForm({ ...form, [e.target.name]: e.target.value })
    },
    createHandler: () => {
      if (isValid.title) Callbacks.Create()
    }
  }

  const Callbacks = {
    Create: async () => {
      try {
        const response = await request(`${config.API}/roles`, 'POST', { ...form }, {
          Authorization: (token as string),
        })

        if (response.created) {
          history.goBack()
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  useEffect(() => {
    if (multiLang) {
      let isRight = true

      for (let key in multiLangTitles) {
        if (multiLangTitles[(key as TPossibleMultiLangTitle)].length >= 2 && isRight) {
          toggleValid({ ...isValid, title: true })
          isRight = true
        } else {
          isRight = false
          toggleValid({ ...isValid, title: false })
        }
      }
    } else {
      if (form.title.length >= 2) toggleValid({ ...isValid, title: true })
      else toggleValid({ ...isValid, title: false })
    }
  }, [form.title, multiLangTitles])


  return (
    <Page.Wrapper
      title={Translater.RoleCreatePage.title[language.slug]}
    >
      <Page.Header
        backButtonTitle={Translater.RoleCreatePage.title[language.slug]}
      />

      <Buttons.Container
        disabled={loading}
        createHandler={Events.createHandler}
      />

      <Components.ConfigBlock
        form={form}
        isValid={isValid}
        multiLangTitles={multiLangTitles}
        setForm={setForm}
        inputHandler={Events.inputHandler}
      />

    </Page.Wrapper>
  )
}

export default CreatePage
