import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
} from 'react'

import * as Contexts from '../../../../contexts'
import * as Components from '.'

import { useHttp } from '../../../../hooks'
import { config } from '../../../../config'
import { IRules } from '../../../../types/items'
import { IRootProps, TPossibleRuleSlugs } from './types'


const Root: React.FC<IRootProps> = ({
  form,
  setForm
}) => {

  const { token } = useContext(Contexts.AuthContext)

  const { request } = useHttp()

  const [configuration, setConfig] = useState<IRules>({})

  const Callbacks = {
    Fetch: async () => {
      try {
        const response: any = await request(`${config.API}/roles/configuration`,
          'GET', null, {
          Authorization: (token as string)
        })
        console.log(response.children)

        if (response.children) {
          setConfig(response.children)
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [])


  return (
    <Fragment>
      {Object.keys(configuration).map(item => (
        <Components.Field
          key={item}
          nesting={0}
          form={form}
          parent={null}
          //@ts-ignore
          item={configuration[item]}
          slug={(item as TPossibleRuleSlugs)}
          setForm={setForm}
        />
      ))}
    </Fragment>
  )
}

export default Root
