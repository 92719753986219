import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
} from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Buttons from '../../components/Buttons'
import * as Components from './components'

import { config, Translater } from '../../config'
import { useHttp } from '../../hooks/http.hook'
import { IStaff } from '../../types/items'
import { useHistory } from 'react-router-dom'



const CreatePage: React.FC = () => {

  const { token } = useContext(Contexts.AuthContext)
  const { language } = useContext(Contexts.LanguageContext)

  const history = useHistory()
  const { loading, request } = useHttp()

  const [isValid, toggleValid] = useState({
    role: false,
    login: false,
    fullname: false,
    password: false
  })

  const [form, setForm] = useState<IStaff>({
    role: '',
    login: '',
    fullname: '',
    password: '',
  })

  const Events = {
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      setForm({ ...form, [e.target.name]: e.target.value })
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setForm({ ...form, [e.target.name]: e.target.value })
    },
    setRole: (role: string) => {
      setForm({ ...form, role })
    },
    createHandler: () => {
      let isRight = false

      for (let key in isValid) {
        //@ts-ignore
        const value = isValid[key]
        if (value) isRight = true
        else {
          isRight = false
          break
        }
      }

      if (isRight) Callbacks.Create()
    }
  }

  const Callbacks = {
    Create: async () => {
      try {
        const response = await request(`${config.API}/admins`, 'POST', { ...form }, {
          Authorization: (token as string),
        })

        if (response.status === 'ok') {
          history.goBack()
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  useEffect(() => {
    if (form.fullname.length >= 3) toggleValid({ ...isValid, fullname: true })
    else toggleValid({ ...isValid, fullname: false })
  }, [form.fullname])

  useEffect(() => {
    if (form.login.length >= 4) toggleValid({ ...isValid, login: true })
    else toggleValid({ ...isValid, login: false })
  }, [form.login])

  useEffect(() => {
    if (form.role) toggleValid({ ...isValid, role: true })
    else toggleValid({ ...isValid, role: false })
  }, [form.role])

  useEffect(() => {
    if ((form.password as string).length >= 6) toggleValid({ ...isValid, password: true })
    else toggleValid({ ...isValid, password: false })
  }, [form.password])


  return (
    <Page.Wrapper
      title={Translater.StaffCreatePage.title[language.slug]}
    >
      <Page.Header
        backButtonTitle={Translater.StaffCreatePage.title[language.slug]}
      />

      <Buttons.Container
        disabled={loading}
        createHandler={Events.createHandler}
      />

      <Components.ConfigBlock
        isCreate
        form={form}
        isValid={isValid}
        setRole={Events.setRole}
        inputHandler={Events.inputHandler}
        selectHandler={Events.selectHandler}
      />

    </Page.Wrapper>
  )
}

export default CreatePage