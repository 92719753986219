import React, {
  Fragment,
  useState,
  useContext,
  useEffect,
} from 'react'


import * as Contexts from '../../../contexts'
import * as Buttons from '../../../components/Buttons'
import * as Form from '../../../components/Form'

import { config, Translater } from '../../../config'
import { useHttp } from '../../../hooks/http.hook'


import { SHiddenOptions } from '../../../components/Form/static'
import { IStock, IOption } from '../../../types/items'
import { IConfigBlockProps } from './types'
import ListProducts from '../ListProducts'
import ListCategories from "../ListCategories";


import AsyncSelect from 'react-select/async'
import { NavLink } from 'react-router-dom'


const ConfigBlock: React.FC<IConfigBlockProps> = ({
  data,
  form,
  isValid,
  multiLangValues,
  setParentID,
  inputHandler,
  selectHandler,
  //@ts-ignore
  HandlerShowProduct

}) => {

  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { multiLang } = useContext(Contexts.ConfigContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { request } = useHttp()

  const [inputValue, setInputValue] = useState<string>('')
  const [categoryOptions, setCategoryOptions] = useState<any[]>([])
  const [categorySelectValue, setCategorySelectValue] = useState<any>({
    label: '',
    value: ''
  })



  const Events = {
    inputHandler: (value: string) => {
      setInputValue(value)
    },
    loadOptions: async (inputValue: string) => {
      try {
        const response: IStock[] = await request(`${config.API}/stocks`, 'GET', null, {
          Authorization: (token as string)
        })
        const formatted: IOption[] = []
        if (response.length) {
          for (const item of response) {
            const option = {
              value: (item._id as string),
              label: (item.name as string)
            }
            formatted.push(option)
          }
        }
        setCategoryOptions(formatted)
        return formatted
      } catch (e) {
        console.log(e)
      }
    },
    optionHandler: (option: any) => {
      setCategorySelectValue(option)
      setParentID(option.value)
    },
  }

  const Styles = {
    control: () => ({
      display: 'flex',
      border: '1px solid #e6e6e6',
      borderRadius: 5,
      outline: 0
    }),
    input: () => ({
      fontSize: 16
    })
  }


  return (
    <Fragment>
      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.title[language.slug]}
        text={access.stocks?.rule !== 'change' ? form.name : ''}
      >
        {access.stocks?.rule === 'change' ? (
          multiLang ? (
            <Form.MultiLang
              placeholder={Translater.Placeholders.minTwo[language.slug]}
              minLength={2}
              multiLangTitles={multiLangValues}
              inputHandler={inputHandler}
            />
          ) : (
              <Form.Input
                name='name'
                placeholder={Translater.Placeholders.minTwo[language.slug]}
                // isValid={isValid.title}
                value={form.name}
                inputHandler={inputHandler}
              />
            )
        ) : null}
      </Form.LabelField>



      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.description[language.slug]}
        text={access.stocks?.rule !== 'change' ? form.description : ''}
      >
        {access.stocks?.rule === 'change' ? (
          multiLang ? (
            <Form.MultiLang
              placeholder={Translater.Placeholders.minTwo[language.slug]}
              minLength={2}
              multiLangTitles={multiLangValues}
              inputHandler={inputHandler}
            />
          ) : (
              <Form.Input
                name='description'
                placeholder={Translater.Placeholders.minTwo[language.slug]}
                // isValid={isValid.title}
                value={form.description}
                inputHandler={inputHandler}
              />
            )
        ) : null}
      </Form.LabelField>

      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.percent[language.slug]}
        text={access.products?.rule !== 'change' ? `${form.percent} грн` : ''}
      >
        {access.products?.rule === 'change' ? (
          <Form.Input
            type='number'
            name='percent'
            value={form.percent}
            inputHandler={inputHandler}
          />
        ) : null}
      </Form.LabelField>



      {access.stocks?.rule === 'change' ? (
        <Form.LabelField
          fontSize='small'
          label={Translater.TableTitles.photo[language.slug]}
        >
          <Form.Input
            type='file'
            name='photo'
            isValid={isValid.photo}
            inputHandler={inputHandler}
          />
        </Form.LabelField>
      ) : null}

      {/* {access.stocks?.rule === 'change' ? (
        <Form.LabelField
          fontSize='small'
          label={Translater.TableTitles.dateStart[language.slug]}
        >
          <Form.Input
            type='number'
            name='dateStart'
            inputHandler={inputHandler}
          />
        </Form.LabelField>
      ) : null} */}

      {access.stocks?.rule === 'change' ? (
        <Form.LabelField
          fontSize='small'
          label={Translater.TableTitles.dateStart[language.slug]}
        >
          <Form.InputDate
            value={form.dateStart}
            type='number'
            name='dateStart'
            inputHandler={inputHandler}
          />
        </Form.LabelField>
      ) : null}


      {access.stocks?.rule === 'change' ? (
        <Form.LabelField
          fontSize='small'
          label={Translater.TableTitles.dateEnd[language.slug]}
        >
          <Form.InputDate
            value={form.dateEnd}
            type='number'
            name='dateEnd'
            inputHandler={inputHandler}
          />
        </Form.LabelField>
      ) : null}


      {access.stocks?.rule === 'change' ? (
        <Form.LabelField
          fontSize='small'
          label={Translater.TableTitles.productsList[language.slug]}
        >
          <ListProducts
            selectHandler={selectHandler}
            productsList={form.productsList}
          />

        </Form.LabelField>
      ) : null}

      {access.stocks?.rule === 'change' ? (
          <Form.LabelField
              fontSize='small'
              label={Translater.TableTitles.categoriesList[language.slug]}
          >
            <ListCategories
                selectHandler={selectHandler}
                categoriesList={form.categoriesList}
            />

          </Form.LabelField>
      ) : null}

    </Fragment>
  )
}

export default ConfigBlock
