import React, {
  Fragment,
  useContext,
} from 'react'

import * as Contexts from '../../../contexts'
import * as Form from '../../../components/Form'
import * as Rules from './Rules'

import { Translater } from '../../../config'

import { IConfigBlockProps } from './types'


const ConfigBlock: React.FC<IConfigBlockProps> = ({
  form,
  isValid,
  multiLangTitles,
  setForm,
  inputHandler
}) => {

  const { access } = useContext(Contexts.UserContext)
  const { multiLang } = useContext(Contexts.ConfigContext)
  const { language } = useContext(Contexts.LanguageContext)


  return (
    <Fragment>
      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.title[language.slug]}
        text={access.admin?.children?.roles?.rule !== 'change' ? form.title : ''}
      >
        {access.admin?.children?.roles?.rule === 'change' ? (
          multiLang ? (
            <Form.MultiLang
              placeholder={Translater.Placeholders.minTwo[language.slug]}
              minLength={2}
              multiLangTitles={multiLangTitles}
              inputHandler={inputHandler}
            />
          ) : (
              <Form.Input
                name='title'
                placeholder={Translater.Placeholders.minTwo[language.slug]}
                isValid={isValid.title}
                value={form.title}
                inputHandler={inputHandler}
              />
            )
        ) : null}
      </Form.LabelField>

      {access.admin?.children?.roles?.rule === 'change' ? (
        <Rules.Root
          form={form}
          setForm={setForm}
        />
      ) : null}
    </Fragment>
  )
}

export default ConfigBlock