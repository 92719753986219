import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
} from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Form from '../../components/Form'
import * as Components from './components'
import * as Buttons from '../../components/Buttons'

import { useHttp } from '../../hooks/http.hook'
import { config, Translater } from '../../config'
import { useParams, useHistory } from 'react-router-dom'
import { IRole } from '../../types/items'
import {
  TMutliLangTitles,
  TPossibleMultiLangTitle
} from '../../types/multiLang'


const DetailPage: React.FC = () => {

  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { multiLang } = useContext(Contexts.ConfigContext)
  const { language } = useContext(Contexts.LanguageContext)

  const history = useHistory()
  const { id } = useParams()
  const { loading, request } = useHttp()

  const [primary, setPrimary] = useState<IRole | null>(null)
  const [isValid, toggleValid] = useState({
    title: false
  })

  const [form, setForm] = useState<IRole>({
    title: '',
    rules: {}
  })

  const [multiLangTitles, setMultiLangTitles] = useState<TMutliLangTitles>({
    "title[EN]": '',
    "title[RU]": '',
    "title[UA]": ''
  })

  const Events = {
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.name.split('[')[1]) {
        setMultiLangTitles({
          ...multiLangTitles, [e.target.name]: e.target.value
        })
      }
      else setForm({ ...form, [e.target.name]: e.target.value })
    },
    saveHandler: () => {
      if (isValid.title) Callbacks.Save()
    },
    deleteHandler: () => {
      const answer = window.confirm(Translater.Alert.delete[language.slug])
      if (answer) Callbacks.Delete()
    }
  }

  const Callbacks = {
    Fetch: async () => {
      const response: IRole = await request(`${config.API}/roles/${id}`, 'GET', null, {
        Authorization: (token as string)
      })

      setPrimary(response)
    },
    Save: async () => {
      try {
        const response = await request(`${config.API}/roles/${id}`, 'POST', { ...form }, {
          Authorization: (token as string),
        })

        if (response) {
          window.location.reload()
         // history.goBack()
        }
      } catch (e) {
        console.log(e)
      }
    },
    Delete: async () => {
      try {
        await request(`${config.API}/roles/${id}`, 'DELETE', null, {
          Authorization: (token as string),
        })

        history.push('/roles')
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    if (multiLang) {
      let isRight = true

      for (let key in multiLangTitles) {
        if (multiLangTitles[(key as TPossibleMultiLangTitle)].length >= 2 && isRight) {
          toggleValid({ ...isValid, title: true })
          isRight = true
        } else {
          isRight = false
          toggleValid({ ...isValid, title: false })
        }
      }
    } else {
      if (form.title.length >= 2) toggleValid({ ...isValid, title: true })
      else toggleValid({ ...isValid, title: false })
    }
  }, [form.title, multiLangTitles])

  useEffect(() => {
    Callbacks.Fetch()
  }, [id])

  useEffect(() => {
    if (primary) setForm({
      ...form,
      title: primary.title,
      rules: primary.rules
    })
  }, [primary])


  if (loading || !primary) return <Page.Preloader />

  return (
    <Page.Wrapper
      title={primary.title}
    >
      <Page.Header
        backButtonTitle={Translater.RoleDetailPage.title[language.slug]}
      />

      <Buttons.Container
        disabled={loading}
        saveHandler={access.admin?.children?.roles?.rule === 'change' ? Events.saveHandler : false}
        deleteHandler={access.admin?.children?.roles?.rule === 'change' ? Events.deleteHandler : false}
      />

      <Components.ConfigBlock
        form={form}
        isValid={isValid}
        multiLangTitles={multiLangTitles}
        setForm={setForm}
        inputHandler={Events.inputHandler}
      />

      <Form.DateBlock
        updated={(primary.updated as string)}
        created={(primary.created as string)}
      />

    </Page.Wrapper>
  )
}

export default DetailPage
