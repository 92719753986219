import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
} from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Components from './components'
import * as Buttons from '../../components/Buttons'

import { useHttp } from '../../hooks/http.hook'
import {ICategory, IRestaurant} from '../../types/items'
import { config, Translater } from '../../config'
import { useParams, useHistory } from 'react-router-dom'
import {
  TMutliLangDescriptions,
  TMutliLangTitles,
  TPossibleMultiLangDescriptions,
  TPossibleMultiLangTitle
} from '../../types/multiLang'



const DetailPage: React.FC = () => {

  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { multiLang } = useContext(Contexts.ConfigContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { id } = useParams() as any
  const history = useHistory()
  const { loading, request } = useHttp()

  const [primary, setPrimary] = useState<ICategory | null>(null)
  const [isValid, toggleValid] = useState({
    title: false,
    photo: true
  })
  const [form, setForm] = useState<ICategory>({
    title: '',
    description: '',
    hidden: '',
    parent_id: '',
    restaurant: '',
    photo: '',
  })
  const [multiLangValues, setMultiLangValues] = useState<TMutliLangTitles>({
    "title[EN]": '',
    "title[RU]": '',
    "title[UA]": ''
  })

  const [multiLangDescriptions, setMultiLangDescriptions] = useState<TMutliLangDescriptions>({
    "description[EN]": '',
    "description[RU]": '',
    "description[UA]": ''
  })

  const Events = {
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      //@ts-ignore
      if (e.target.type === 'file') setForm({ ...form, photo: e.target.files[0] })
      else if (e.target.name.split('[')[1]) {
        setMultiLangValues({
          ...multiLangValues, [e.target.name]: e.target.value
        })
      }
      else setForm({ ...form, [e.target.name]: e.target.value })
    },
    textareaHandler: (e: ChangeEvent<HTMLTextAreaElement>) => {
       if (e.target.name.split('[')[1]) {
       setMultiLangDescriptions({
            ...multiLangDescriptions, [e.target.name]: e.target.value
       })
      } else setForm({ ...form, [e.target.name]: e.target.value })
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setForm({ ...form, [e.target.name]: e.target.value })
    },
    setParentID: (parent_id: string) => {
      setForm({ ...form, parent_id })
    },
    setRestaurant: (restaurant: string) => {
      setForm({ ...form, restaurant })
    },
    saveHandler: () => {
      if (isValid.title && isValid.photo) Callbacks.Save()
    },
    deleteHandler: () => {
      const answer = window.confirm(Translater.Alert.delete[language.slug])
      if (answer) Callbacks.Delete()
    },
  }

  const Callbacks = {
    Fetch: async () => {
      try {
        const response: ICategory = await request(`${config.API}/categories/${id}`,
            'GET', null, {
              Authorization: (token as string)
            })

        if (response) {
          setPrimary(response)
        }
      } catch (e) {
        console.log(e)
      }
    },
    FetchCategory: async () => {
      try {
        const response: ICategory = await request(`${config.API}/categories/${primary?.parent_id}`, 'GET', null, {
          Authorization: (token as string)
        })

        if (primary) setPrimary({ ...primary, parentCategory: response })
      } catch (e) {
        console.log(e)
      }
    },
    Save: async () => {
      try {
        const data = new FormData()

        if (multiLang) {
          for (let key in multiLangValues) {
            data.append(key, multiLangValues[(key as TPossibleMultiLangTitle)])
          }
        } else data.append('title', form.title)

        if (multiLang) {
          for (let key in multiLangDescriptions) {
            data.append(key, multiLangDescriptions[(key as TPossibleMultiLangDescriptions)])
          }
        } else data.append('description', form.description)

        form.photo && data.append('photo', form.photo)
        data.append('hidden', (form.hidden as string))
        if (form.restaurant) data.append('restaurant', (form.restaurant as string))
        data.append('parent_id', form.parent_id || '')

        const response = await request(`${config.API}/categories/${primary?._id}`, 'POST', data, {
          Authorization: (token as string),
        })

        if (response) history.push('/categories')
      } catch (e) {
        console.log(e)
      }
    },
    Delete: async () => {
      try {
        await request(`${config.API}/categories/${id}`, 'DELETE', null, {
          Authorization: (token as string),
        })

        history.push('/categories')
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [id])

  useEffect(() => {
    if (primary) setForm({
      ...form,
      title: primary.title,
      description: primary.description,
      hidden: primary.hidden,
      parent_id: primary.parent_id,
      restaurant: (primary?.restaurant as IRestaurant)?._id!
    })
  }, [primary])

  useEffect(() => {
    if (primary?.parent_id) Callbacks.FetchCategory()
  }, [primary?.parent_id])

  useEffect(() => {
    if (multiLang) {
      let isRight = true

      for (let key in multiLangValues) {
        if (multiLangValues[(key as TPossibleMultiLangTitle)].length >= 2 && isRight) {
          toggleValid({ ...isValid, title: true })
          isRight = true
        } else {
          isRight = false
          toggleValid({ ...isValid, title: false })
        }
      }
    } else {
      if (form.title.length >= 2) toggleValid({ ...isValid, title: true })
      else toggleValid({ ...isValid, title: false })
    }
  }, [form.title, multiLangValues])


  if (loading || !primary) return <Page.Preloader />

  return (
      <Page.Wrapper
          title={primary.title}
      >
        <Page.Header
            backButtonTitle={Translater.CategoriesDetailPage.title[language.slug]}
        />

        <Buttons.Container
            disabled={loading}
            saveHandler={access.categories?.rule === 'change' ? Events.saveHandler : false}
            deleteHandler={access.categories?.rule === 'change' ? Events.deleteHandler : false}
        />

        <Components.ConfigBlock
            data={primary}
            form={form}
            isValid={isValid}
            multiLangValues={multiLangValues}
            multiLangDescriptions={multiLangDescriptions}
            setRestaurant={Events.setRestaurant}
            setParentID={Events.setParentID}
            inputHandler={Events.inputHandler}
            selectHandler={Events.selectHandler}
            textareaHandler={Events.textareaHandler}
        />

        <Components.DetailBlock
            data={primary}
        />
      </Page.Wrapper>
  )
}

export default DetailPage
