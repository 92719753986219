import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  KeyboardEvent
} from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Components from './components'
import { useHttp } from '../../hooks/http.hook'

import {
  config,
  Translater
} from '../../config'

import {
  IPagination,
  IFilter,
  TSortBy,
  IProduct, ICategory
} from '../../types/items'


const IndexPage: React.FC = () => {

  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { loading, totalCount, request } = useHttp()

  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    perPage: 10,
   // children: 3,
    totalCount: 0
  })
  const [filter, setFilter] = useState<IFilter>({
    sortBy: 'updated',
    sortOrder: 'desc',
  })
  const [searchValue, setSearchValue] = useState<string>('')

  const [primary, setPrimary] = useState<IProduct[]>([])

  const Events = {
    searchHandler: (e: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.target.value)
    },
    pressHandler: (e: KeyboardEvent) => {
      if (e.key === 'Enter') Callbacks.Fetch()
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setPagination({ ...pagination, page: 1, perPage: +e.target.value })
    },
    sortHandler: (name: TSortBy) => {
      let sortOrder: 'asc' | 'desc'
      if (filter.sortOrder === 'asc') sortOrder = 'desc'
      else sortOrder = 'asc'
      setFilter({
        sortBy: name,
        sortOrder
      })

      setPagination({ ...pagination, page: 1 })
    },
    paginateHandler: (index: number) => {
      setPagination({ ...pagination, page: index })
    },
    reloadHandler: () => {
      Callbacks.Fetch()
    }
  }

  const Callbacks = {
    Fetch: async () => {
      try {
        const response: IProduct[] = await request(`${config.API}/products/?full=1&page=${pagination.page}&perPage=${pagination.perPage}&children=${pagination.children}&name=${searchValue}&sortOrder=${filter.sortOrder}&sortBy=${filter.sortBy}`,
          'GET', null, {
          Authorization: (token as string)
        })

        if (response) {
          setPrimary(response)
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  useEffect(() => {
    setPagination({ ...pagination, totalCount })
  }, [totalCount])

  useEffect(() => {
    Callbacks.Fetch()
  }, [filter.sortBy, filter.sortOrder, pagination.page, pagination.perPage])


  if (loading || !primary) return <Page.Preloader />

  return (
    <Page.Wrapper
      // footer
      title={Translater.ProductsPage.title[language.slug]}
    >
      <Page.Header
        title={Translater.ProductsPage.title[language.slug]}
        linkTitle={Translater.Buttons.create[language.slug]}
        enableButton={access.products?.rule === 'change'}
        linkTo='/products/create'
      />

      <Page.SortBar
        searchBar
        filter={filter}
        value={searchValue}
        perPage={pagination.perPage}
        pressHandler={Events.pressHandler}
        inputHandler={Events.searchHandler}
        // selectHandler={Events.selectHandler}
      />

      <Components.Table
        items={primary}
        sortHandler={Events.sortHandler}
        reloadHandler={Events.reloadHandler}
      />

      {/* <Page.Footer>
        <Page.Pagination
          linkTo='/products'
          pagination={pagination}
          paginateHandler={Events.paginateHandler}
        />
      </Page.Footer> */}
    </Page.Wrapper>
  )
}

export default IndexPage
